import React from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import DOMPurify from 'dompurify';

import 'react-funnel-pipeline/dist/index.css';
import './styles.scss';
import Constants from '../../../../constants/constants';
import waterfallSelectionUtil from '../../../../utils/waterfallSelection/waterfallSelectionUtil';
import Alert from '../../../shared/Alert/Alert';

const ChartResults = ({ otherSelectionsInRunChain }) => {
  // get state of properties from global and error reducer
  const {
    runStatusForSelectionChain, runningQuery, runError, waterfallSelectionStatus,
  } = useSelector(({ globalReducer, errorReducer }) => ({
    runStatusForSelectionChain: globalReducer.runStatusForSelectionChain,
    runningQuery: globalReducer.runningQuery,
    runError: errorReducer.runError,
    waterfallSelectionStatus: globalReducer.waterfallSelectionStatus,
  }), shallowEqual);

  // /**
  //  * Returns data for chart
  //  * @returns {object} object with value and name
  //  */
  // const returnDataForChart = () => runStatusForSelectionChain.map((status, idx) => {
  //   const noRecords = status.numberOfRecords === 0;

  /*
   *   // when there is no records we want to show funnel chart with 0 value
   *   const value = noRecords ? 1 : status.numberOfRecords;
   *   const name = noRecords ? `Step ${idx + 1}: 0` : `Step ${idx + 1}: ${status.numberOfRecords}`;
   */

  /*
   *   return ({
   *     value,
   *     name,
   *   });
   * });
   */

  /*
   * colors used in chart
   * const chartColors = [
   *   '#FF9900',
   *   '#FFAD33',
   *   '#FFC266',
   *   '#FFD699',
   *   '#FFEBCC',
   * ];
   */

  /**
   * Returns Step number for the selection with error
   * @returns {string} label Step ${selection number for which the error occurred} or empty string if there is no error
   */
  const getRunStepError = () => {
    const selectionWithError = selection => selection?.error && selection?.runStatus === Constants.STATUS_ERROR;
    const selectionWithErrorIndex = runStatusForSelectionChain.findIndex(selectionWithError);

    if (selectionWithErrorIndex !== -1) {
      return `Step ${selectionWithErrorIndex + 1}: `;
    }

    return '';
  };

  /**
   * Returns Run Error message in Alert component
   * @returns {object} HTML object with run error message
   */
  const renderAlertErrorMessage = () => (
    <>
      <h2 className="run-step-error">
        {getRunStepError()}
      </h2>
      {/* eslint-disable-next-line react/no-danger */}
      <h2 dangerouslySetInnerHTML={({ __html: DOMPurify.sanitize(runError).replace('<a', '<a target="_blank"') })} />
    </>
  );

  /**
   * Renders proper HTML tags for the chart, depending on the state
   * @returns {object} HTML for the chart
   */
  const renderFunnelChart = () => {
    // if there is a run error
    if (runError) {
      return (
        <div className={`results-with-error-wrapper-new ${otherSelectionsInRunChain && true}`}>
          <div className="results-with-error-content-new">
            <img
              alt="Sad Penguin"
              src="/img/sad-penguin.png"
              className="sad-penguin"
              style={{ minHeight: '10rem' }}
            />
            <Alert
              type={Constants.ALERT__TYPE__WARNING}
              assistiveText={Constants.ALERT__TYPE__WARNING}
              title={renderAlertErrorMessage()}
              headerId="waterfall-run-error-msg"
            />
          </div>
        </div>
      );
    }

    // when query is running
    if (waterfallSelectionUtil.isWaterfallSelectionRunning(waterfallSelectionStatus, runningQuery)) {
      return (
        <div className="loading-chart-container_new">
          <div className="loading-indicator-wrapper">
            <img
              alt="Penguin"
              src={Constants.PREVIEW_DEEDEE_URL}
              style={{ minHeight: '15rem' }}
            />
            <p>Loading results...</p>
          </div>
        </div>
      );
    }

    /*
     * if the chart data is ready
     * if (runStatusForSelectionChain?.length && !runningQuery &&
     *   waterfallSelectionStatus === Constants.STATUS_COMPLETE) {
     *   return (
     *     <div className="funnel-chart">
     *       <FunnelChart
     *         data={returnDataForChart()}
     *         showValues={false}
     *         getToolTip={row => row.name}
     *         pallette={chartColors}
     *       />
     *     </div>
     *   );
     * }
     */

    // if a selection has not yet been run
    if (!runStatusForSelectionChain?.length && !runningQuery) {
      return (
        <div className="without-results-wrapper">
          <p>
            Results will be shown here once selection has run.
          </p>
        </div>
      );
    }

    return null;
  };

  return (
    <div className="cart-results-container-v2">
      {renderFunnelChart()}
    </div>
  );
};

ChartResults.propTypes = {
  /**
   * Defined is there are other selections in selection chain as selected one
   */
  otherSelectionsInRunChain: PropTypes.bool.isRequired,
};

export default ChartResults;
