import React, { useState } from 'react';
import PropTypes from 'prop-types';

import Constants from '../../../../constants/constants';
import PicklistLabelInput from '../PicklistLabelInput/PicklistLabelInput';
import PicklistValueInput from '../PicklistValueInput/PicklistValueInput';
import '../styles.scss';
import Button from '../../../../components/shared/Button/Button';

const PicklistAddValuesComponent = (
  {
    selectedField,
    handleChangeOptionValueInOptions,
    handleAddToOptions,
    valueInputRef,
  },
) => {
  // state for PicklistValueInput and PicklistLabelInput
  const [labelInput, setLabelInput] = useState('');
  const [valueInput, setValueInput] = useState('');

  /**
   * It helps to change valueInput depending of the selected fieldType
   * @param {object} e - event. Use e.target to get the value
   * @param {string} fieldType - type of the selectedField
   * @returns {void}
   */
  const handleChangeOptionValue = (e, fieldType) => {
    switch (fieldType) {
      case (Constants.FILTERLINE__FIELDTYPE__TEXT): {
        setValueInput(
          e.target.value ? e.target.value.toString() : null,
        );
        break;
      }
      case (Constants.FILTERLINE__FIELDTYPE__NUMBER): {
        setValueInput(
          Number.parseInt(e.target.value),
        );
        break;
      }
      case (Constants.FILTERLINE__FIELDTYPE__DECIMAL): {
        setValueInput(
          e.target.value,
        );
        break;
      }
      case (Constants.FILTERLINE__FIELDTYPE__BOOLEAN): {
        setValueInput(
          e.target.value,
        );
        break;
      }
      default:
        break;
    }
  };

  /**
   * Execute every time the user inputs something into the input field to check for ENTER
   * @param {object} e - event
   * @returns {void}
   */
  const inputOnKeyDown = (e) => {
    if (e.key === Constants.INPUT_TYPE__ENTER) {
      // after pressing the enter add values to option and set empty state
      handleAddToOptions(valueInput, labelInput, Constants.INPUT_TYPE__ENTER);
      setLabelInput('');
      setValueInput('');
    }
  };

  /**
   * @param {object} e - event. Use e.target to get the value
   * @param {boolean} onFocusOut - it determines if it s on focus out or not
   * @returns {void}
   */
  const handleChangeOptionLabel = (e, onFocusOut) => {
    // if label has no value then set it to its default value dependent if it's on focus out or not
    if (labelInput === '') {
      const valueString = valueInput ? valueInput.toString() : '';
      const targetValueString = e.target.value ? e.target.value.toString() : '';

      setLabelInput(onFocusOut ? valueString : targetValueString);
    }
  };

  /**
   * function to change the state for label input
   * @param {object} e - event. Use e.target to get the value
   * @returns {void}
   */
  const handleLabelInputChange = (e) => {
    setLabelInput(e.target.value);
  };

  /**
   * function to change the state for value input
   * @param {object} e - event. Use e.target to get the value
   * @returns {void}
   */
  const handleValueInputChange = (e) => {
    setValueInput(e.target.value);
  };

  /**
   * function to add input values to options in PicklistsAE component
   * @returns {void}
   */
  const handleOnClick = () => {
    handleAddToOptions(valueInput, labelInput);
    setLabelInput('');
    setValueInput('');
  };

  return (
    <div className="slds-col slds-size_1-of-2">
      <div className="slds-form-element element-container value-label-container">
        <div className="slds-form-element__control">
          <PicklistValueInput
            selectedField={selectedField}
            value={valueInput}
            handleChangeOptionLabel={handleChangeOptionLabel}
            handleChangeOptionValue={handleChangeOptionValue}
            handleChangeOptionValueInOptions={handleChangeOptionValueInOptions}
            handleAddToOptions={handleAddToOptions}
            valueInputRef={valueInputRef}
            onChange={e => handleValueInputChange(e)}
            labelValue={labelInput}
            setLabelInput={setLabelInput}
            inputOnKeyDown={inputOnKeyDown}
          />
        </div>
        <div className="slds-form-element__control">
          <PicklistLabelInput
            selectedField={selectedField}
            handleChangeOptionLabel={handleChangeOptionLabel}
            onChange={e => handleLabelInputChange(e)}
            value={labelInput}
            setValue={setLabelInput}
            inputOnKeyDown={inputOnKeyDown}
          />
        </div>
        <Button
          id="add-option-btn"
          buttonLook={Constants.BUTTON__TYPE__NEUTRAL}
          onClick={handleOnClick}
          disabled={!valueInput}
        >
          Add
        </Button>
      </div>
    </div>
  );
};

PicklistAddValuesComponent.propTypes = {
  /**
   * Selected field in state of PicklistsAE
   */
  selectedField: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  /**
   * Changes the option value from the existing options in PicklistsAE state
   */
  handleChangeOptionValueInOptions: PropTypes.func.isRequired,
  /**
   * Adds the value and label as a new option
   */
  handleAddToOptions: PropTypes.func.isRequired,
  /**
   * Input reference in PicklistsAE
   */
  valueInputRef: PropTypes.oneOfType([PropTypes.object]),
};

export default PicklistAddValuesComponent;
