import React from 'react';
import PropTypes from 'prop-types';
import { Droppable } from 'react-beautiful-dnd';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { debounce } from 'lodash';
import {
  InputIcon, Input,
} from '@salesforce/design-system-react';

import Spinner from '../../../../shared/Spinner/Spinner';
import AvailableSelectionCard from './AvailableSelectionCard/AvailableSelectionCard';
import './styles.scss';
import Constants from '../../../../../constants/constants';
import { handleChangePaginationIndex, setSearchValue }
  from '../../../../../redux/actions/waterfallSelection/globalActions';
import { setAllSelections, loadingSelections as setLoadingSelections }
  from '../../../../../redux/actions/waterfallSelection/selectionActions';
import Pagination from '../../../../Overview/Pagination/Pagination';

const SelectionListColumn = ({ axiosCancelToken }) => {
  const dispatch = useDispatch();

  // get state of loadingSelections from selectionReducer
  const {
    loadingSelections, selectedFolderId, allSelections,
    paginationIndex, pageItems, searchValue,
  } = useSelector(({ selectionReducer, globalReducer, folderReducer }) => ({
    loadingSelections: selectionReducer.loadingSelections,
    allSelections: selectionReducer.allSelections,
    selectedFolderId: folderReducer.selectedFolderId,
    paginationIndex: globalReducer.paginationIndex,
    pageItems: globalReducer.pageItems,
    searchValue: globalReducer.searchValue,
  }), shallowEqual);

  // use Reacts useRef to store the debounced function across renders
  const debouncedSearch = React.useRef(
    /**
     * Returns a function, that, as long as it continues to be invoked, will not
     * be triggered. The function will be called after it stops being called for
     * 500 milliseconds.
     * @param {string} value - value from search input
     * @param {string} folderId - selected folder ID
     * @returns {void}
     */
    debounce(async (value, folderId) => {
      await dispatch(
        setAllSelections(axiosCancelToken.token, folderId, 1, value),
      );
    }, 500),
  ).current;

  /**
   * Handles changing value in the search input
   * @param {object} e - JS Event
   * @returns {void}
   */
  const handleSearch = (e) => {
    dispatch(setLoadingSelections(true));
    dispatch(setSearchValue(e.target.value));
    debouncedSearch(e.target.value, selectedFolderId);
  };

  React.useEffect(() => {
    return () => {
      // cancel the debounced function
      debouncedSearch.cancel();
    };
  }, [debouncedSearch]);

  /**
   * Function that returns true / false depending on whether the selection has targetDE
   * @param {object} selection - selection to be dragged
   * @returns {boolean} returns true if selection has no targetDE
   */
  const isDragDisabled = (selection) => {
    // find selection to be dragged
    const draggedSelection = allSelections.find(sel => sel._id === selection._id);

    // if selection has targetDE, do not disable it
    if (draggedSelection?.targetCollectionObjectID) { return false; }

    return true;
  };

  // define non-selectable selections
  const nonSelectableSelections = allSelections.filter(selection => !selection.targetCollectionObjectID);

  return (
    <div className="selection-list-column-new">
      <p className="available-selections_count">
        Available Selections (
        {allSelections.length}
        )
      </p>
      <div className="available-selection_input_wrapper">
        <Input
          type="text"
          onChange={handleSearch}
          noInputClassName
          iconLeft={
            <InputIcon
              assistiveText={{
                icon: 'Search',
              }}
              name="search"
              category="utility"
            />
          }
          className="available-extension_search des-search-position-v2"
          placeholder="Search Selections"
        />
      </div>
      <div className="make-space-for-scroll">
        <Droppable
          droppableId={Constants.DROPPABLE__CONTAINER_ID__1}
          isDropDisabled
        >
          {provided => (
            <div
              className="selections-container"
              ref={provided.innerRef}
            >
              {loadingSelections ?
                (
                  <Spinner
                    size={Constants.SPINNER__SIZE__MEDIUM}
                    assistiveText="Loading..."
                  />
                ) :
                (
                  <>
                    {allSelections?.length ?
                      allSelections.map((selection, index) => (
                        <AvailableSelectionCard
                          selection={selection}
                          id={selection._id}
                          key={selection._id}
                          index={index}
                          isDragDisabled={isDragDisabled}
                        />
                      )) :
                      null}

                    {provided.placeholder}
                  </>
                )}
            </div>
          )}
        </Droppable>
        {allSelections?.length > 0 ?
          <Pagination
            paginationIndex={paginationIndex}
            pageItems={pageItems}
            axiosCancelToken={axiosCancelToken}
            handleChangePaginationIndex={(e, nr, cancelToken) => {
              dispatch(handleChangePaginationIndex(e, nr, cancelToken, selectedFolderId, searchValue));
            }}
          /> :
          null}
      </div>
      {nonSelectableSelections ?
        (
          <div className="disabled-selection-information">
            <svg aria-hidden="true" className="slds-button__icon">
              <use xlinkHref="/assets/icons/utility-sprite/svg/symbols.svg#lock" />
            </svg>
            <p>This selection does not have a Target Data Extension</p>
          </div>
        ) :
        null}
    </div>

  );
};

SelectionListColumn.propTypes = {
  /*
   * function for changing the state of the selection search field
   */
  axiosCancelToken: PropTypes.instanceOf(Object).isRequired,
};

export default SelectionListColumn;
