import React from 'react';
import PropTypes from 'prop-types';

import './styles.scss';
import ToggleButton from '../../../../../shared_v2/ToogleButton/ToggleButton';

const PrioDeduplicationStatus = ({
  usePrioDeduplication,
  handleChangePrioDedupState,
}) => (
  <div className="slds-form-element">
    <ToggleButton
      id="prio-dedup-status-v2"
      name="usePrioDeduplication"
      checked={usePrioDeduplication}
      onChange={() => handleChangePrioDedupState({
        usePrioDeduplication: !usePrioDeduplication,
      })}
      label="Enable Prio Deduplication"
    />
  </div>
);

PrioDeduplicationStatus.propTypes = {
  /**
   * It keeps if the prio deduplication settings will be applied
   *
   */
  usePrioDeduplication: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  /**
   * It helps to change state of prio dedup
   *
   */
  handleChangePrioDedupState: PropTypes.func.isRequired,
};

export default PrioDeduplicationStatus;
