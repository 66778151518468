import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Constants from '../../../../../../constants/constants';
import './styles.scss';
import Button from '../../../../../shared_v2/Button/Button';
import Select from '../../../../../shared_v2/Select/Select';

const modes = [
  {
    label: Constants.PRIO_DEDUP__SORT_ORDER_LOWEST__LABEL,
    value: Constants.PRIO_DEDUP__SORT_ORDER__ASC,
  },
  {
    label: Constants.PRIO_DEDUP__SORT_ORDER_HIGHEST__LABEL,
    value: Constants.PRIO_DEDUP__SORT_ORDER__DESC,
  },
];

const MultipleCriteriaChoice = ({
  handleAddMoreSortingOptionLine,
  handleRemoveMoreSortingOptionLine,
  handleAddOptionToMoreSortingOptionLine,
  handleCheckWhichCriteriaWasChosen,
  multipleSortingOptionLines,
  extensionFields,
  criteriaFieldName,
  sortOrder,
}) => {
  const sortingOptionLineClassName = classNames(
    { 'red-btn': multipleSortingOptionLines.length >= extensionFields.length - 2 },
  );

  return (
    <div className="multiple_choice-v2">
      {multipleSortingOptionLines &&
        multipleSortingOptionLines.map((line, i) => (
          <div className="multiple_choice_line" key={line.id}>
            <div className="multiple_box-1">
              <p className="info_about_connection">
                When there are multiple records with the same&nbsp;
                {multipleSortingOptionLines[i - 1] ?
                  [(multipleSortingOptionLines[i - 1].sortOrder === Constants.PRIO_DEDUP__SORT_ORDER__DESC ?
                    Constants.PRIO_DEDUP__SORT_ORDER_HIGHEST__LABEL.toString().toLowerCase() :
                    Constants.PRIO_DEDUP__SORT_ORDER_LOWEST__LABEL.toString().toLowerCase())] :
                  [(sortOrder === Constants.PRIO_DEDUP__SORT_ORDER__DESC ?
                    Constants.PRIO_DEDUP__SORT_ORDER_HIGHEST__LABEL.toString().toLowerCase() :
                    Constants.PRIO_DEDUP__SORT_ORDER_LOWEST__LABEL.toString().toLowerCase())]}
                    &nbsp;
                {multipleSortingOptionLines[i - 1] ?
                  [(multipleSortingOptionLines[i - 1] &&
                      multipleSortingOptionLines[i - 1].criteriaFieldObjectID.length !== 0 ?
                    (
                      <span key={line.id}>
                        {extensionFields.find(
                          field => field.ObjectID ===
                            (multipleSortingOptionLines[i - 1] &&
                              multipleSortingOptionLines[i - 1].criteriaFieldObjectID),
                        ).Name.toString()}
                      </span>
                    ) :
                      <span>...</span>)] :
                      <span>{criteriaFieldName}</span>}
                , choose the one with the
              </p>
              <div className="box-inside">
                <div className="slds-form-element">
                  <div className="slds-form-element__control">
                    <Select
                      className="chooseOptionToMoreSortingOptionLine-sortOrder"
                      onChange={e => handleAddOptionToMoreSortingOptionLine(
                        e,
                        line.id,
                        'sortOrder',
                      )}
                      value={line.sortOrder}
                      options={modes.map(el => (
                        { value: el.value, label: el.label.toString().toLowerCase() }
                      ))}
                    />
                  </div>
                </div>

                <p>value for</p>
                <div className="slds-form-element">
                  <div className="slds-form-element__control">
                    <Select
                      className="chooseOptionToMoreSortingOptionLine-criteriaFieldObjectID"
                      onChange={e => handleAddOptionToMoreSortingOptionLine(
                        e,
                        line.id,
                        'criteriaFieldObjectID',
                      )}
                      value={line.criteriaFieldObjectID}
                      options={extensionFields.map(field => (
                        {
                          value: field.ObjectID,
                          style: {
                            backgroundColor:
                                line.criteriaFieldObjectID !== field.ObjectID && handleCheckWhichCriteriaWasChosen(
                                  field.ObjectID,
                                ) && '#ccc9c9',
                          },
                          disabled: line.criteriaFieldObjectID !== field.ObjectID &&
                            handleCheckWhichCriteriaWasChosen(field.ObjectID),
                          label: field.Name.toString(),
                        }
                      ))}
                      />
                  </div>
                </div>
              </div>
            </div>

            <Button
              id="deleteMultipleSortingOptionLine"
              buttonLook={Constants.BUTTON__TYPE__NEUTRAL}
              className="multiple_box-2"
              onClick={() => handleRemoveMoreSortingOptionLine(line.id)}
            >
              Delete option
            </Button>
          </div>
        ))}
      <br />
      <Button
        disabled={multipleSortingOptionLines.length >= extensionFields.length - 2}
        buttonLook={Constants.BUTTON__TYPE__NEUTRAL}
        className={sortingOptionLineClassName}
        onClick={() => handleAddMoreSortingOptionLine()}
        id="moreSortingOptionLine"
      >
        <svg
          className="slds-button__icon slds-icon_small plus-icon"
          aria-hidden="true"
        >
          <use xlinkHref="/assets/icons/utility-sprite/svg/symbols.svg#add" />
        </svg>
        {multipleSortingOptionLines.length >= extensionFields.length - 2 ?
          'You Cannot Add More Sorting Options' :
          'Add Sorting Option'}
      </Button>
    </div>
  );
};

MultipleCriteriaChoice.propTypes = {
  /**
   * Add more sorting option line
   */
  handleAddMoreSortingOptionLine: PropTypes.func.isRequired,
  /**
   * Remove more soting option line
   */
  handleRemoveMoreSortingOptionLine: PropTypes.func.isRequired,
  /**
   * Change options in more sorting options
   */
  handleAddOptionToMoreSortingOptionLine: PropTypes.func.isRequired,
  /**
   * All sorting option lines
   */
  multipleSortingOptionLines: PropTypes.instanceOf(Object),
  /**
   * It keeps the formatted target data extension' fields for combobox
   */
  extensionFields: PropTypes.instanceOf(Object),
  /**
   * Check which criteria should be showed
   */
  handleCheckWhichCriteriaWasChosen: PropTypes.instanceOf(Object),
  /**
   * It keeps the criteria field's name
   *
   */
  criteriaFieldName: PropTypes.string.isRequired,
  /**
   * It keeps the info about sortOrder for deduplication
   */
  sortOrder: PropTypes.string.isRequired,
};

export default MultipleCriteriaChoice;
