import React from 'react';
import PropTypes from 'prop-types';

import RelatedRow from '../RelatedRow/RelatedRow';
import arrowRight from '../../../../../icons_v2/arrow-up.svg';
import arrowDown from '../../../../../icons_v2/arrow-left.svg';

const RelatedCard = ({
  predefinedRelations, dataExtension, handleSetSelectionState, DEBorderMouseOver,
}) => {
  const [isRelatedCardOpen, setIsRelatedCardOpen] = React.useState(false);

  return (
    <div>
      <div
        className="collection-label-container-v2"
        onClick={() => setIsRelatedCardOpen(!isRelatedCardOpen)}
      >
        <span
          className="collection-name slds-truncate"
          title="Contacts"
        >
          <p className="slds-truncate">{dataExtension.deAlias}</p>
        </span>
        <div
          className="chevron-icons-container"
        >
          <img
            src={isRelatedCardOpen ? arrowRight : arrowDown}
            alt="icons"
          />
        </div>
      </div>
      {isRelatedCardOpen && predefinedRelations.map(predefinedRelation => (
        <RelatedRow
          predefinedRelation={predefinedRelation}
          key={predefinedRelation._id}
          handleSetSelectionState={handleSetSelectionState}
          DEBorderMouseOver={DEBorderMouseOver}
          deAlias={dataExtension.deAlias}
          selectedDataExtension={dataExtension}
        />
      ))}
    </div>
  );
};

RelatedCard.propTypes = {
  /**
   * An array containing relations that were defined for Data Extensions
   */
  predefinedRelations: PropTypes.instanceOf(Array).isRequired,
  /**
   * An object containing Data Extension for which relations have been defined
   */
  dataExtension: PropTypes.instanceOf(Object).isRequired,
  /**
   * It helps to set the Selection`s state
   * It will be passed from Selection.js
   */
  handleSetSelectionState: PropTypes.func.isRequired,
  /*
   * Keeps track whether related DE is dragged
   */
  DEBorderMouseOver: PropTypes.bool.isRequired,
};

export default RelatedCard;
