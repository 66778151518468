import React from 'react';
import PropTypes from 'prop-types';

import Input from '../Input/Input';

const Checkbox = ({
  className,
  id,
  name,
  label,
  checked = true,
  disabled = false,
  formElementClassName,
  onChange,
  labelClassName,
  spanFauxClassName,
  formLabelClassName,
}) => (
  <div className={`slds-form-element ${formElementClassName || ''}`}>
    <div className="slds-form-element__control">
      <div className="slds-checkbox">
        <label className={`slds-checkbox__label ${labelClassName || ''}`} htmlFor={id}>
          <Input
            noInputClassName
            type="checkbox"
            name={name}
            id={id}
            onChange={onChange}
            checked={checked}
            disabled={disabled}
            className={className}
          />
          <span className={`slds-checkbox_faux ${spanFauxClassName || ''}`} />
          <span className={`slds-form-element__label ${formLabelClassName || ''}`}>
          {label}
          </span>
        </label>
      </div>
    </div>
  </div>
);

Checkbox.propTypes = {
  /**
   * Name of the checkbox
   */
  name: PropTypes.string.isRequired,
  /**
   * Label of the checkbox
   */
  label: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
  ]).isRequired,
  /**
   * onChange Event handler
   */
  onChange: PropTypes.func.isRequired,
  /**
   * Checked state of the checkbox
   */
  checked: PropTypes.bool.isRequired,
  /**
   * Disabled state of the checkbox
   */
  disabled: PropTypes.bool,
  /**
   * Id of the checkbox
   */
  id: PropTypes.string,
  /**
   * class name of the checkbox
   */
  className: PropTypes.string,
  /**
   * class name of the parent div container
   */
  formElementClassName: PropTypes.string,
  /**
   * class name of the label element
   */
  labelClassName: PropTypes.string,
  /**
   * class name of the first span element after input
   */
  spanFauxClassName: PropTypes.string,
  /**
   * class name of the second span element after input (where we display label text)
   */
  formLabelClassName: PropTypes.string,
};

export default Checkbox;
