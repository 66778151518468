import React from 'react';
import PropTypes from 'prop-types';

import Constants from '../../../../../../constants/constants';
import './styles.scss';
import RadioButton from '../../../../../shared_v2/RadioButton/RadioButton';

const modes = [
  {
    label: Constants.PRIO_DEDUP__SORT_ORDER_LOWEST__LABEL,
    value: Constants.PRIO_DEDUP__SORT_ORDER__ASC,
  },
  {
    label: Constants.PRIO_DEDUP__SORT_ORDER_HIGHEST__LABEL,
    value: Constants.PRIO_DEDUP__SORT_ORDER__DESC,
  },
];

/**
 * Mode of the prio deduplication sort
 * @param {object} param - Object with 'sortOrder' and 'handleChangeRadioValues' as properties
 * @returns {object} HTML for the prio deduplication sort mode
 */
const PrioDeduplicationSortMode = ({ sortOrder, handleChangeRadioValues }) => (
  <div className="slds-radio_button-group prio-dedup-sort-mode">
    {
      modes.map(mode => (
        <RadioButton
          unlabeledInput
          containerClassName="slds-button slds-radio_button"
          id={mode.value}
          name="sortOrder"
          value={mode.value}
          checked={sortOrder === mode.value}
          onChange={e => handleChangeRadioValues(e)}
          key={mode.value}
          label={mode.label}
        />
      ))
    }
  </div>
);

PrioDeduplicationSortMode.propTypes = {
  /**
   * It keeps the info about sortOrder for deduplication
   */
  sortOrder: PropTypes.string.isRequired,
  /**
   * It helps to switch between deduplication modes
   */
  handleChangeRadioValues: PropTypes.func.isRequired,
};
export default PrioDeduplicationSortMode;
