import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import axios from 'axios';

import { setUserInformation } from './redux/actions/userInformation/globalActions';
import UsersAPI from './api/users';
import Constants from './constants/constants';
import Spinner from './components/shared/Spinner/Spinner';

class Root extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
    //   error: null,
    };
    this.source = axios.CancelToken.source();
  }

  componentDidMount() {
    UsersAPI.getUserInfo(this.source.token)
      .then((res) => {
        const { setUserInformation } = this.props;

        setUserInformation(res.data);
        this.setState({ loading: false });
      })
      .catch((error) => {
        this.setState({ error, loading: false });
      });
  }

  componentWillUnmount() {
    this.source.cancel('Component unmounted');
  }

  render() {
    const { loading, error } = this.state;
    const { children } = this.props;

    if (loading) {
      return (
         <div className="folder-loading-spinner">
           <Spinner size={Constants.SPINNER__SIZE__MEDIUM} assistiveText="Loading..." />
         </div>
      );
    }

    if (error) {
      throw error;
    }

    return children;
  }
}

Root.propTypes = {
  /**
   * Function to set user information in the redux store
   */
  setUserInformation: PropTypes.func.isRequired,
  /**
   * Children nodes for this component
   */
  children: PropTypes.node,
};

const mapDispatchToProps = {
  setUserInformation,
};

export default connect(null, mapDispatchToProps)(Root);
