/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';

import Constants from '../../../constants/constants';
import RelationsAPI from '../../../api/relations';
import Button from '../../../components/shared/Button/Button';
import AdminHeader from '../../shared/AdminHeader/AdminHeader';
import Table from '../../shared/Table/Table';
import SwalUtil from '../../../utils/swal/swalUtil';
import Tooltip from '../../../components/shared/Tooltip/Tooltip';

import './styles.scss';

const ContactAttributes = ({
  contactAttributes = [],
  handleSetAdminPanelState,
  handleEditRelation,
  handleOpenPanel,
  handleSortRelations,
  sortDirection,
  sortedProperty,
  isLoading,
  dataSets,
  handleFetchContactAttributes,
}) => {
  /**
   * Deletes the relation selected in the
   * overview with the given id
   * @param {Object} event - onClick event
   * @param {String} relationId - id of the relation to delete
   * @returns {void}
   */
  const handleRemoveRelation = async (event, relationId) => {
    event.preventDefault();

    const result = await SwalUtil.fire({
      title: 'Remove Relation',
      message: 'Are you sure you want to remove this relation?',
      options: {
        showCancelButton: true,
        confirmButtonText: 'Remove',
      },
    });

    if (result.value) {
      // find the relation that is going to be deleted
      const relation = contactAttributes.find(r => r._id === relationId);

      // check if the fromDE and toDE are being used in some of the data sets
      const dataSetWhereRelationIsUsed = dataSets.find(dataSet => dataSet.relations.find(rel => ((
        rel.fromCollectionObjectID === relation.fromDEObjectId) ||
        (rel.fromCollectionObjectID === relation.toDEObjectId)) &&
        ((rel.toCollectionObjectID === relation.toDEObjectId) ||
          (rel.toCollectionObjectID === relation.fromDEObjectId))));

      /*
       * if a data set uses a relation that is going to be deleted
       * do not allow it
       */
      if (dataSetWhereRelationIsUsed) {
        return SwalUtil.fire({
          title: 'Remove Relation',
          messageHTML: `<p class="width_swal">Unable to delete this relation.
               This relation is part of the data set
               <span class="swal_custom_bold">${dataSetWhereRelationIsUsed.name}</span>.
               Please delete it from the data set first before deleting it from here.</p>`,
          options: {
            showCancelButton: false,
            confirmButtonText: 'OK',
          },
        });
      }

      // Delete relation
      await RelationsAPI.deleteRelation(relationId, axios.CancelToken.source().token);

      // Reload data
      handleOpenPanel(Constants.ADMIN_PANEL__SUBMENU__CONTACT_ATTRIBUTES);
    }
  };

  const handleRetrieveRelations = async () => {
    if (contactAttributes.length > 1) {
      const result = await SwalUtil.fire({
        title: 'Retrieve Contact Builder Relations',
        messageHTML: `
          <p class="swal-content-left">Please note that this action will overwrite
          any previously retrieved relations.</p>
          <br/>
          <p class="swal-content-left">If any of the existing relations are being used in
          <span class="swal_custom_bold">Data Sets</span>, then an update can
          result in breaking that Data Set and causing an error. </p>
          <br/>
          <p class="swal-content-left">Are you sure you want to proceed?</p>
          <br/>
          `,
        options: {
          showCancelButton: true,
          confirmButtonText: 'Confirm',
        },
      });

      if (result.value) {
        handleFetchContactAttributes();
      }
    } else {
      handleFetchContactAttributes();
    }
  };

  /**
   * Renders relation Label based on relation type
   * @param {string} type - type of relation
   * @returns {string} - title of relation
   */
  const relationType = (type) => {
    const relationsType = {
      [Constants.RELATION__TYPE__ONE_TO_MANY]: '1-to-many',
      [Constants.RELATION__TYPE__ONE_TO_ONE]: '1-to-1',
      [Constants.RELATION__TYPE__MANY_TO_ONE]: 'many-to-1',
      [Constants.RELATION__TYPE__MANY_TO_MANY]: 'many-to-many',
    };

    // title of relation
    return relationsType[type];
  };

  /**
   * Renders relation name
   * @param {string} from - from DE name
   * @param {string} to  - to DE name
   * @returns {string} - relation name
   */
  const renderRelationName = (from, to) => from.toString() + ' - ' + to.toString();

  const renderRelationDataTable = () => {
    /*
     * if relations are available show relations
     * otherwise show text of no relations available
     */
    if (contactAttributes && contactAttributes.length > 0 && !isLoading) {
      return (contactAttributes.map(relation => (
        <tr key={relation._id} className="slds-hint-parent row-data">
          <td data-label="Relation">
            <div className="slds-truncate">
              <a
                href="#!"
                title={renderRelationName(relation.fromDEName, relation.toDEName)}
                onClick={(e) => {
                  handleEditRelation(e, relation._id);
                }}
              >
                {renderRelationName(relation.fromDEName, relation.toDEName)}
              </a>
            </div>
          </td>
          <td data-label="Type">
            <div className="slds-truncate slds-m-left_x-small relation-type">{relationType(relation.relation)}</div>
          </td>
          <td data-label="Action">
            <div className="slds-truncate slds-text-align_left">
              <Button
                buttonIconBorderFilled
                className="remove-relation"
                title="Remove"
                onClick={async e => handleRemoveRelation(e, relation._id)}
              >
                <svg className="slds-button__icon" aria-hidden="true">
                  <use xlinkHref="/assets/icons/utility-sprite/svg/symbols.svg#delete" />
                </svg>
              </Button>
            </div>
          </td>
        </tr>
      )));
    } if (!contactAttributes?.length && !isLoading) {
      return (
        <tr className="row-data">
          <td
            colSpan="5"
            className="slds-text-align_center"
          >
            <div id="no-picklists-defined">
              No Contact Relations have been defined yet. Click the &apos;Retrieve Contact Relations&apos;
              button to get started.
            </div>
          </td>
        </tr>
      );
    }

    return <tr aria-label="No contact relations" />;
  };

  // data for table header
  const tableHeadersData = [
    {
      title: 'Relation',
      propertyName: 'fromDEName',
      sortIconId: 'relationSort',
      leftSize: Constants.TABLE__CELL_LEFT_SIZE__SMALL,
    },
    {
      title: 'Type',
      propertyName: 'relation',
      sortIconId: 'typeSort',
      leftSize: Constants.TABLE__CELL_LEFT_SIZE__X_SMALL,
    },
    {
      title: 'Action',
    },
  ];

  return (
    <>
      {/* Relations header */}
      <AdminHeader
        title="Imported Relations"
        onClick={() => handleSetAdminPanelState({ activePanel: Constants.ADMIN_PANEL__MENU__NEW_RELATION })}
        disabled={isLoading}
        iconLink="/assets/icons/standard-sprite/svg/symbols.svg#picklist_type"
      />
      <div className="attribute-main">
        <h1 className="import-relations-title">
          Import Contact Builder Relations
        </h1>

        <div className="info-text">
          As an admin you can pull attribute sets into DESelect Segment from Contact Builder.
          DESelect will use this information to automatically create pre-defined relations if they do not exist yet.
        </div>

        <div className="attribute-main__actions">
          <Button
            buttonLook={Constants.BUTTON__TYPE__BRAND}
            className="fetch-attributes-button"
            id="fetchAttributesButton"
            title="Fetch Contact Builder Attribute Sets"
            disabled={isLoading}
            onClick={handleRetrieveRelations}
          >
            Import Contact Builder Relations

          </Button>
          <Tooltip
            nubbinPosition={Constants.NUBBIN_POSITION__TOP_LEFT}
            tooltipText="1-to-1 and 1-to-many type relationships will be retrieved
            for the Data Extensions in the current Business Unit."
          />

        </div>
      </div>
      <Table
        tableHeaders={tableHeadersData}
        bodyContent={renderRelationDataTable()}
        isLoading={isLoading}
        id="picklists-panel"
        className="contact-relations-table"
        handleSort={handleSortRelations}
        sortedProperty={sortedProperty}
        sortDirection={sortDirection}
      />
    </>
  );
};

ContactAttributes.propTypes = {
  /**
   * array containing the relations retrieved, this prop comes
   * from the admin panel component
   */
  contactAttributes: PropTypes.instanceOf(Array).isRequired,
  // handles the state of the admin panel
  handleSetAdminPanelState: PropTypes.func.isRequired,
  // function to open another panel / reload data
  handleOpenPanel: PropTypes.func.isRequired,
  // function to open the edit screen for a relation
  handleEditRelation: PropTypes.func.isRequired,
  /**
   * It sorts relations
   */
  handleSortRelations: PropTypes.func.isRequired,
  /**
   * It indicates the direction of sort
   */
  sortDirection: PropTypes.string.isRequired,
  /**
   * It gives by which value sort will take place
   */
  sortedProperty: PropTypes.string,
  /**
   * Responsible for showing/hiding loader
   */
  isLoading: PropTypes.bool.isRequired,
  /**
   * Array of the data sets -> used when a relation is being deleted
   */
  dataSets: PropTypes.instanceOf(Array).isRequired,
  /**
   * Fetch or update contact relations from SFMC
   */
  handleFetchContactAttributes: PropTypes.func.isRequired,

};

export default ContactAttributes;
