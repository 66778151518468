
const theme = {
  colors: {
    // primary colors
    white: '#fff',
    primaryOrange: '#FF9900',
    primaryGreen: '#04844b',
    primaryRed: '#f50808',
    primaryDarkGray: '#3e3e3c',

    // Very light colors
    veryLightBlue: '#D8EDFF',

    // used as a background for filter container
    primaryLightGray: '#f0f2f3',
    primaryBlue: '#0176D3',
    primaryBlack: '#222',
    primaryYellow: '#FFCC00',
    primaryBoxShadowGray: '#403d331f',

    // used for primary key icon
    yellowOrangePrimaryKey: '#ffdf00',

    // orange color with less contrast
    secondaryOrange: '#ff9a3c',

    // used for active navigation buttons
    greenActive: '#4BCA81',

    // used for selected folder in popup
    secondaryGreen: '#5cbd23',

    // green text color for successful validation
    greenText: '#4BB643',

    // pure black
    black: '#000',

    // black color with opacity 0.025
    blackOpacity025: 'rgba(0, 0, 0, 0.025)',

    // black color with opacity 0.125
    blackOpacity125: 'rgba(0, 0, 0, .125)',

    // black color with opacity 0.15
    blackOpacity15: 'rgba(0, 0, 0, .15)',

    // black color with opacity 0.50
    blackOpacity50: 'rgba(0, 0, 0, .5)',

    blackBoxShadow: '#00000042',

    // black color for text label (eg. filters)
    blackLabel: '#212529',

    gray: '#a6a6a6',

    // used for disabled text in schedule modal
    disabledGrayText: '#b0adab',

    // dark color used for title
    blackTitle: '#333',

    darkBrownGray: '#999999',

    // used for text in the footer
    darkGrayTextFooter: '#545454',

    // used in selection list
    darkGrayText: '#514f4d',

    // used in information text in Relation Panel
    grayText: '#8c8989',

    // used for dark Overview button
    darkGrayButton: '#5a6268',
    darkGrayButtonBorder: '#4e555b',
    darkGrayButtonHover: '#545b62',
    darkGrayButtonDisabled: 'rgba(127, 127, 127, .5)',

    // dark blue color used for icons or hovering primary button
    darkBlue: '#005fb2',
    darkBlueHover: '#063c6b',

    // more dark then darkBlue
    darkenBlue: '#02539b',

    // dark gray for text or icon
    darkGray: '#706e6b',

    // dark gray used for subtitle
    darkGraySubtitle: '#403131',

    // dark gray for header title
    darkGrayHeader: '#666',

    // dark orange used for warnings
    darkOrangeWarning: 'darkorange',

    // dark orange used in box-shadow
    darkOrangeBoxShadow: 'rgba(173, 65, 0, 0.5)',

    darkGreyedOutBorder: '#969492',

    // dark red color for error status
    darkRed: '#C23934',

    // dark blue color for text when you hover over dragged collection
    darkDesaturatedBlue: '#385b6b',

    // gray color for disabled buttons
    disabledGray: '#c9c7c5',

    // gray color for borders
    grayBorder: '#bbb',

    // used for disabled folder icon
    disabledGrayBlue: '#c0c7cf',

    // used for hover over warning status
    verySoftOrange: '#f8d1ab',

    // used in prioDedup in Multiple Criteria Choice
    lightRed: '#ff000059',

    // light gray used for icon backgrounds
    lightGrayDisabledItem: '#b2b2b2',
    lightGrayIconBackground: '#e9ecef',
    lightGrayIconBackgroundHover: '#DCDEE0',

    // used for background in disabled datepicker or greyed-out folders
    lightGreyedOut: '#ecebea',

    // light gray color for borders
    lightGrayBorder: '#dddbda',

    // darken light gray color for borders
    darkenLightGrayBorder: '#ced4da',

    lightGrayishOrange: '#fbdbc9',

    // used as a border for available DE or Fields collection
    lightGrayColdBorder: '#dee2e6',

    // light blue when you hover over dragged collection
    lightGrayishBlueHover: '#d9eaf5',

    // light orange background
    lightOrange: '#ffd196',
    lightOrangeBorder: '#ffab40',

    darkenLightOrangeActive: '#ffb75d',
    darkenLightOrangeActiveHover: '#fb9e23',

    // background for table header
    lightGrayTableHeader: '#fafaf9',

    // light gray for hover over Cancel button in swal modal
    lightGrayHover: '#f4f6f9',

    // very light gray used for scrollbar track
    lightGrayScrollTrack: '#e4e4e4',

    // background for selected folders
    softBlue: '#5ec7f9',

    // used for folder icon
    lightSoftBlue: '#B8DFF1',

    // darken light gray used for scrollbar thumb
    darkenLightGrayScrollThumb: '#c1c1c1',

    // gray for footer and header background in the modal
    modalGrayBackground: '#f3f2f2',

    // used for target collection dropzone
    dropzoneLightGrayBackground: '#bfbfe024',
    dropzoneLightGrayBorder: '#04040424',

    // red background for error header
    errorRed: '#c23934',

    // blue color for focusing
    focusBlue: '#1589ee',

    // body background color
    bodyBackground: 'linear-gradient(0deg, #ffd196 10%, #ffab40b8 100%)',

    loadingModalBackground: 'linear-gradient(62deg, var(--primaryOrange) 0%, #f7cf68 100%)',

    // very light gray (mostly white) background color used for rightBar and prioValue container
    mostlyWhiteBackground: '#F6F5F4',

    // gray color used in admin-panel for selected option background
    whiteSmoke: 'rgb(245, 245, 245)',

    // light blue background in admin-app
    lightBlue: '#add8e6',

    // used for sql keyword
    sqlBlue: '#0000ff',

    // disabled features overlay
    disabledOverlayOrange: '#fbdbc9af',
  },

  borders: {
    // variables responsible for border - when DRAG ENTER/LEAVE/DROP
    transparentBorder: '0.1rem dashed transparent',
    solidBorder: '0.1rem solid #04844b',
    redBorderTop: '0.2rem dashed #f50808',
    dashedBorder: '0.1rem dashed #04844b',
  },

  width: {
    rightbarWidth: '35px',
  },
};

export default theme;
