import React, { Component } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import { Dropdown } from 'semantic-ui-react';
import Swal from 'sweetalert2';
import _ from 'lodash';
import { connect } from 'react-redux';

import mapStateToProps from '../../../../mapStateToProps';
import LoadingModal from '../../../shared/LoadingModal/LoadingModal';
import Features from '../../../../features';
import Constants from '../../../../constants/constants';
import RelationsAPI from '../../../../api/relations';
import DataExtensionsAPI from '../../../../api/data-extensions';
import DataViewsAPI from '../../../../api/data-views';
import DataViews from '../../../../constants/dataViews';
import Util from '../../../../util';
import Button from '../../../../components/shared/Button/Button';
import './styles.scss';
import SwalUtil from '../../../../utils/swal/swalUtil';

class RelationPanel extends Component {
  constructor(props) {
    super(props);

    this.state = {
      relation: {
        fromDECustomerKey: '',
        fromDEObjectId: '',
        fromDEName: '',
        fromFieldObjectId: '',
        fromFieldName: '',
        fromFieldType: '',
        relation: '',
        toDECustomerKey: '',
        toDEObjectId: '',
        toDEName: '',
        toFieldObjectId: '',
        toFieldName: '',
        toFieldType: '',
        additionalJoins: [],
      },
      dataExtensions: [],
      relationDataExtensions: [],
      fromFields: [],
      toFields: [],
      loading: true,
      copiedRelationState: {},
      loadingFromFields: false,
      loadingToFields: false,
      savingRelation: false,
    };
    this.axiosCancelToken = axios.CancelToken.source();
  }

  /**
   * Mount the component with dataExtensions and relation
   * @returns {void}
   */
  async componentDidMount() {
    const {
      relationId,
      selectedRelation,
      featuresInfo,
    } = this.props;

    const featureDataViews = Features.isFeatureEnabled(featuresInfo, Constants.FEATURE__DATA_VIEWS);

    const result = selectedRelation;

    let fetchedData;

    /**
     * while relation is loading, set isMounting true
     * to work as expected
     */
    this.isMounting = true;

    // post data for fetching Data Extensions used in relation
    const relationDEs = [
      {
        collectionCustomerKey: result?.fromDECustomerKey?.toString(),
        collectionObjectID: result?.fromDEObjectId,
      },
      {
        collectionCustomerKey: result?.toDECustomerKey?.toString(),
        collectionObjectID: result?.toDEObjectId,
      },
    ];

    try {
      // fetch all available DE and relation DEs with its fields
      fetchedData = await Promise.all([
        this.fetchDataExtensions(false, relationDEs),
        this.fetchDataExtensions(true)]);

      const [, dataExtensions] = fetchedData || [];

      // add data views in data extensions array
      this.addDataViewsInDEs(dataExtensions);

      this.setState({ dataExtensions, loading: false });
    } catch (error) {
      if (!axios.isCancel(error)) {
        SwalUtil.fire({
          type: Constants.SWAL__TYPE__ERROR,
          title: 'Error',
          message: error,
          options: {
            showCancelButton: false,
            confirmButtonText: 'OK',
            allowOutsideClick: false,
          },
        });
      }
    }

    if (relationId) {
      let fromFields = [];

      let toFields = [];

      const [relationDataExtensions] = fetchedData || [];

      this.setState({ loading: true });
      const { selectedRelation } = this.props;
      const result = selectedRelation;

      if (result) {
        this.setState({ relation: result, copiedRelationState: result, relationDataExtensions });

        // if relation contains fromDECustomerKey and fromFieldObjectId, get DE and it's fields
        if (result.fromDECustomerKey && result.fromFieldObjectId) {
          // get specific data extension determined by CustomerKey
          const fromDataExtension = await relationDataExtensions?.find(
            d => d.CustomerKey === result.fromDECustomerKey ||
              d.ObjectID === result.fromDEObjectId,
          );

          // check if data extension is not a dataView - for dataView fields have no CustomerKeys
          const fromDEIsNotDataView = result?.fromDEFields.find(field => field?.CustomerKey);

          // if dataExtension is found
          if (fromDataExtension) {
            try {
              // get the fields based on data extension or data view
              fromFields = await this.getDataExtensionOrDataViewFields(fromDataExtension);

              this.setState({
                fromFields,
                loadingFromFields: false,
              });
            } catch (error) {
              if (!axios.isCancel(error)) {
                SwalUtil.fire({
                  type: Constants.SWAL__TYPE__ERROR,
                  title: 'Error',
                  message: error,
                  options: {
                    showCancelButton: false,
                    confirmButtonText: 'OK',
                    allowOutsideClick: false,
                  },
                });
              }
            }

            // then find the selected field inside of fields
            const selectedFromField = fromFields?.find(f => f.ObjectID === result.fromFieldObjectId);

            /**
             * if selectedField is undefined, that means that the field of the relation
             * doesn't belong to the data extension anymore, so we handle if the field
             * was removed from SFMC by asking the user to delete the relation
             */
            if (!selectedFromField && this.isMounting) {
              const data = {
                fieldOrDataExtension: Constants.FIELD_OR_DATA_EXTENSION__FIELD,
                relationId,
                fieldOrDeName: result.fromFieldName,
                fromOrToDe: result.fromDEName,
              };

              await this.handleThrowSwalMissingDeOrField(data);
            }
          } else if (this.isMounting && (fromDEIsNotDataView || featureDataViews)) {
            // for DE that is not data view unless the user has access to it
            const data = {
              fieldOrDataExtension: Constants.FIELD_OR_DATA_EXTENSION__DATA_EXTENSION,
              relationId,
              fieldOrDeName: result.fromDEName,
            };

            // if dataextension is removed from SFMC, ask user to delete relation
            await this.handleThrowSwalMissingDeOrField(data);
          } else if (this.isMounting && !fromDEIsNotDataView && !featureDataViews) {
            // open the selection with dataView where user has no access to it
            await this.handleFeatureMissing(Constants.FEATURE__DATA_VIEWS_LABEL);
          }
        }

        // if relation contains toDECustomerKey and toFieldObjectId, get DE and its fields
        if (result.toDECustomerKey && result.toFieldObjectId) {
          // get specific data extension determined by ObjectID
          const toDataExtension = await relationDataExtensions?.find(
            d => d.CustomerKey === result.toDECustomerKey ||
              d.ObjectID === result.toDEObjectId,
          );

          // check if data extension is not a dataView - for dataView fields have no CustomerKeys
          const toDEIsNotDataView = result?.toDEFields.find(field => field?.CustomerKey);

          // if dataExtension is found
          if (toDataExtension) {
            try {
              // get the fields based on data extension or data view
              toFields = await this.getDataExtensionOrDataViewFields(toDataExtension);
              this.setState({
                toFields,
                loadingToFields: false,
                loading: false,
              });
            } catch (error) {
              if (!axios.isCancel(error)) {
                SwalUtil.fire({
                  type: Constants.SWAL__TYPE__ERROR,
                  title: 'Error',
                  message: error,
                  options: {
                    showCancelButton: false,
                    confirmButtonText: 'OK',
                    allowOutsideClick: false,
                  },
                });
              }
            }

            // then find the selected field inside of fields
            const selectedToField = toFields?.find(f => f.ObjectID === result.toFieldObjectId);

            /**
             * if selectedField is undefined, that means that the field of the relation
             * doesn't belong to the data extension anymore, so we handle if the field
             * was removed from SFMC by asking the user to delete the relation
             */
            if (!selectedToField && this.isMounting) {
              const data = {
                fieldOrDataExtension: Constants.FIELD_OR_DATA_EXTENSION__FIELD,
                relationId,
                fieldOrDeName: result.toFieldName,
                fromOrToDe: result.toDEName,
              };

              await this.handleThrowSwalMissingDeOrField(data);
            }
          } else if (this.isMounting && (toDEIsNotDataView || featureDataViews)) {
            // for DE that is not data view unless the user has access to it
            const data = {
              fieldOrDataExtension: Constants.FIELD_OR_DATA_EXTENSION__DATA_EXTENSION,
              relationId,
              fieldOrDeName: result.toDEName,
            };

            // if dataextension is removed from SFMC, ask user to delete relation
            await this.handleThrowSwalMissingDeOrField(data);
          } else if (this.isMounting && !toDEIsNotDataView && !featureDataViews) {
            // open the selection with dataView where user has no access to it
            await this.handleFeatureMissing(Constants.FEATURE__DATA_VIEWS_LABEL);
          }
        }

        // if relation contains additionalJoins
        if (result.additionalJoins?.length) {
          const missingFields = result.additionalJoins.reduce((array, join) => {
            // check if fromFieldObjectID exists
            const fromFieldJoin = fromFields?.find(field => field.ObjectID === join.fromFieldObjectID);

            if (!fromFieldJoin) {
              // get the name of missing toField
              const fromFieldName = result.fromDEFields.find(field => field.ObjectID === join.fromFieldObjectID)?.Name;

              // create object with missing data and push into missingFields array
              const missingFromFieldObject = {
                fieldName: fromFieldName || '',
                joinId: join._id,
                fromFieldRemoved: true,
              };

              return [...array, missingFromFieldObject];
            }

            // check if toFieldObjectID exists
            const toFieldJoin = toFields?.find(field => field.ObjectID === join.toFieldObjectID);

            if (!toFieldJoin) {
              // get the name of missing fromField
              const toFieldName = result.toDEFields.find(field => field.ObjectID === join.toFieldObjectID)?.Name;

              // create object with missing data and push into missingFields array
              const missingToFieldObject = {
                fieldName: toFieldName || '',
                joinId: join._id,
                toFieldRemoved: true,
              };

              return [...array, missingToFieldObject];
            }

            return array;
          }, []);

          if (this.isMounting && missingFields?.length) {
            /*
             * depending on the missing field, assign DE name
             * and create object with data needed to display the message
             */
            const missingFieldsWithDEName = {
              fromDEName: missingFields.find(data => data.fromFieldRemoved) ? result.fromDEName : null,
              toDEName: missingFields.find(data => data.toFieldRemoved) && result.fromDEName !== result.toDEName ?
                result.toDEName :
                null,
              missingFields: _.uniqBy(missingFields, 'fieldName'),
            };

            // throw swal message
            await this.handleThrowSwalMissingFieldInAdditionalJoin(missingFieldsWithDEName);
          }
        }
      }

      this.setState({
        loading: false,
      });
    }

    /*
     * copy the relation state to check if data is modified
     * to throw swal or not when cancel
     */
    const { relation } = this.state;

    const copiedRelationState = { ...relation };

    this.setState({ copiedRelationState });
  }

  /**
   * Unmount the component
   * @returns {void}
   */
  componentWillUnmount() {
    this.axiosCancelToken.cancel('Navigation occurred');

    /**
     * while relation loading, close is clicked isMounting is used
     * to bypass swal alerts as component is unmounted
     */
    this.isMounting = false;
  }

  /**
   * Function returns new Promise after fetching data extensions
   * @param {boolean} isAll - indicates whether all available DEs are to be fetched
   * @param {array} dataExtensionsForRelation - data extensions used in relation
   * @returns {Promise<Void>} void
   */
  fetchDataExtensions = async (isAll, dataExtensionsForRelation) => new Promise((resolve) => {
    if (isAll) {
      // get all DEs
      resolve(DataExtensionsAPI.getDataExtensions(
        this.axiosCancelToken.token,
        Constants.DATAEXTENSION__FILTER_MODE__AVAILABLE,
      ));
    } else {
      // get data extensions defined in dataExtensionsForRelation
      resolve(DataExtensionsAPI.getDataExtensionsAndFieldsByCustomerKeys(
        this.axiosCancelToken.token,
        dataExtensionsForRelation,
        null,
      ));
    }
  });

  /**
   * Function returns the submenu to navigate to depending on whether the relation is predefined
   * or from contact builder
   * @returns {String}  returns the submenu to navigate to
   */
  subMenuToNavigateTo = () => {
    const { relation } = this.state;

    return relation.isContactBuilderAttribute ?
      Constants.ADMIN_PANEL__SUBMENU__CONTACT_ATTRIBUTES :
      Constants.ADMIN_PANEL__SUBMENU__PREDEFINED_RELATIONS;
  };

  /**
   * Show a swal to the user indicating the feature is not enabled, and bring user back to the overview screen
   * @param {string} feature - Name of feature
   * @returns {void}
   */
  handleFeatureMissing = async (feature) => {
    this.axiosCancelToken.cancel(`Missing feature: ${feature}.`);

    await SwalUtil.fire({
      type: Constants.SWAL__TYPE__ERROR,
      message: `You do not have the feature for <b>${feature}</b> enabled to open this type of relation.`,
      options: {
        allowOutsideClick: false,
      },
    });

    const { openPanel } = this.props;

    await openPanel(this.subMenuToNavigateTo());
  };

  /**
   * Function for adjusting data view fields for further usage
   * @param {object[]} selectedCollection - Selected collection
   * @returns {void}
   */
  prepareDataViewsFields = (selectedCollection) => {
    const dataExtension = {
      CustomerKey: selectedCollection.CustomerKey,
    };

    selectedCollection.fields.forEach((field) => {
      /* eslint-disable no-param-reassign */
      field.CustomerKey = `[${selectedCollection.CustomerKey}].[${field.Name.toString()}]`;
      field.DataExtension = dataExtension;
      /* eslint-enable no-param-reassign */
    });
  };

  /**
   * Helps to retrieve fields of a data extension or data view from SFMC
   * @param {object[]} selectedCollection - Selected collection
   * @param {boolean} isComparedField - Determined if we are calling this function for compared fields
   * @returns {void}
   */
  getDataExtensionOrDataViewFields = async (selectedCollection) => {
    const { featuresInfo } = this.props;
    const featureDataViews = Features.isFeatureEnabled(featuresInfo, Constants.FEATURE__DATA_VIEWS);

    /**
     * Check if selected DE has CategoryID
     * If it has then move on to find DE
     * If it doesn't have then move on to find Data Views
     */
    if (selectedCollection && !selectedCollection.CategoryID) {
      /**
       * If feature 'dataViews' is 'true' get data views fields
       * Else show error message
       */
      if (featureDataViews) {
        try {
          // eslint-disable-next-line no-param-reassign, require-atomic-updates
          selectedCollection.fields = await DataViewsAPI.getDataViewFields(
            selectedCollection.CustomerKey.toString(),
            this.axiosCancelToken.token,
          );
        } catch (error) {
          if (!axios.isCancel(error)) {
            // show swal error if request failed with error
            SwalUtil.fire({
              type: Constants.SWAL__TYPE__ERROR,
              title: 'Error',
              message: error,
              options: {
                showCancelButton: false,
                confirmButtonText: 'OK',
                allowOutsideClick: false,
              },
            });
          }
        }
      } else {
        await this.handleFeatureMissing(Constants.FEATURE__DATA_VIEWS_LABEL);

        /**
         * I am returning null so we can differentiate data views feature is disabled and
         * Data view is deleted
         */
        return null;
      }
      // Prepare fields so you can use it properly
      this.prepareDataViewsFields(selectedCollection);
      // Sort data view fields by Name
      Util.sortArrayOfObjects(selectedCollection.fields, 'Name');
    } else {
      // Get data extension fields
      const { relationDataExtensions } = this.state;

      let getCustomerKey;

      if (relationDataExtensions && relationDataExtensions.length) {
        // find customerKey from collection in dataExtensions
        getCustomerKey = relationDataExtensions.filter(de => de.CustomerKey &&
          de.CustomerKey === selectedCollection.CustomerKey);
      }

      // prevent from errors, check if fields have data
      if (getCustomerKey && getCustomerKey.length && getCustomerKey[0].fields) {
        // assign fields from the fetched fields
        // eslint-disable-next-line require-atomic-updates, no-param-reassign
        selectedCollection.fields = getCustomerKey[0].fields;
      } else {
        try {
          // if it doesn't find the fields, get new ones
          const fields = await DataExtensionsAPI.getDataExtensionFields(
            selectedCollection.CustomerKey,
            this.axiosCancelToken.token,
          );

          if (fields && fields.data) {
            // eslint-disable-next-line require-atomic-updates, no-param-reassign
            selectedCollection.fields = fields.data;
          } else {
            // eslint-disable-next-line require-atomic-updates, no-param-reassign
            selectedCollection.fields = [];
          }
        } catch (error) {
          if (!axios.isCancel(error)) {
            // show swal error if request failed with error
            SwalUtil.fire({
              type: Constants.SWAL__TYPE__ERROR,
              title: 'Error',
              message: error,
              options: {
                showCancelButton: false,
                confirmButtonText: 'OK',
                allowOutsideClick: false,
              },
            });
          }
        }
      }
    }

    return selectedCollection.fields;
  };

  /**
   * Function to throw swal pop up for missing field in additional join
   * @param {object} missingFieldsData - object containing missing fields data
   * @returns {void}
   */
  handleThrowSwalMissingFieldInAdditionalJoin = async (missingFieldsData) => {
    const { openPanel, relationId } = this.props;

    const { missingFields, fromDEName, toDEName } = missingFieldsData;

    // set loading on false to show the relations panel behind the modal instead of the loading screen
    this.setState({ loading: false });

    // depending on the number of missing fields, set proper variables for message
    const fieldOrFields = `Field${missingFields?.length < 2 ? '' : 's'}`;
    const singleOrCouple = missingFields?.length < 2 ? 'was removed or does not' : 'were removed or do not';
    const deOrDEs = (fromDEName && toDEName) ?
      `Data Extensions <span class="swal_custom_bold">${fromDEName}, ${toDEName}</span>` :
      `Data Extension <span class="swal_custom_bold">${fromDEName || toDEName}</span>`;

    // eslint-disable-next-line max-len
    const message = `${fieldOrFields} <span class="swal_custom_bold">${missingFields.map(field => ' ' + field.fieldName)}</span> used in additional join ${singleOrCouple} exist anymore on ${deOrDEs}. Therefore, this relation will be removed.`;

    const result = await Swal.fire({
      type: 'error',
      title: 'Remove Relation',
      html: `<p class="width_swal">${message}</p>`,
      confirmButtonText: 'OK',
      footer: '<div></div>',
      buttonsStyling: false,
      allowOutsideClick: false,
    });

    if (result.value) {
      // Delete relation
      await RelationsAPI.deleteRelation(relationId, axios.CancelToken.source().token);
    }

    // Back to relations screen
    await openPanel(this.subMenuToNavigateTo());
  };

  /**
   * Function to throw swal pop up for missing field or data extension
   * @param {object} data - object containing data
   * @returns {void}
   */
  handleThrowSwalMissingDeOrField = async (data) => {
    const {
      fieldOrDataExtension, relationId, fieldOrDeName, fromOrToDe,
    } = data;

    const { openPanel } = this.props;

    let result = {};

    /**
     * by setting loading state to false here we force the app to show the relations panel
     * behind the modal instead of the loading screen so it looks much cleaner to the user
     */
    this.setState({ loading: false });

    /**
     * if the selected field is not found, a swal appears with the message,
     * that this field was removed or does not exist anymore in DataExtension.
     */
    if (fieldOrDataExtension === Constants.FIELD_OR_DATA_EXTENSION__FIELD) {
      result = await SwalUtil.fire({
        title: 'Remove Relation',
        message: `Field <span class="swal_custom_bold">${fieldOrDeName}</span>
          was removed or does not exist anymore on
          Data Extension <span class="swal_custom_bold">${fromOrToDe}</span>.
          Therefore, this relation will be removed.`,
        options: {
          confirmButtonText: 'OK',
          allowOutsideClick: false,
        },
      });
    } else {
      // otherwise, show swal for dataextension missing
      result = await SwalUtil.fire({
        title: 'Remove Relation',
        message: `Data extension <span class="swal_custom_bold">${fieldOrDeName}</span>
          was removed or does not exist anymore. Therefore, this relation will be removed.`,
        options: {
          confirmButtonText: 'OK',
          allowOutsideClick: false,
        },
      });
    }

    if (result.value) {
      // Delete relation
      await RelationsAPI.deleteRelation(relationId, axios.CancelToken.source().token);
    }

    // Back to relations screen
    await openPanel(this.subMenuToNavigateTo());
  };

  /**
   * It helps to change data extension in relation
   * @param {object} e - event. Use e.target to get the value
   * @returns {void}
   */
  handleSetToRelationDE = async (e) => {
    const { dataExtensions, relation } = this.state;

    // set loading visible and reset additional joins
    this.setState({ loadingToFields: true, relation: { ...relation, additionalJoins: [] } });
    try {
      // get specific data extension determined by ObjectID
      const dataExtension = dataExtensions.find(d => d.ObjectID === e.value || d.CustomerKey === e.value);

      // if dataExtension is found
      if (dataExtension) {
        this.setState(prevState => ({
          relation: {
            ...prevState.relation,
            toDECustomerKey: dataExtension.CustomerKey,
            toDEObjectId: dataExtension.ObjectID || dataExtension.CustomerKey,
            toDEName: dataExtension.Name,
            // reset the field state
            toFieldObjectId: '',
            toFieldName: '',
            toFieldType: '',
          },
        }));

        // get the fields based on data extension or data view
        const toFields = await this.getDataExtensionOrDataViewFields(dataExtension);

        this.setState({
          toFields,
          loadingToFields: false,
        });
      } else {
        // set dataextension and selected field null in aggregation state
        this.setState(prevState => ({
          relation: {
            ...prevState.relation,
            toDECustomerKey: '',
            toDEObjectId: '',
            toDEName: '',
            // reset the field state
            toFieldObjectId: '',
            toFieldName: '',
            toFieldType: '',
          },
          loadingToFields: false,
        }));
      }
    } catch (error) {
      if (!axios.isCancel(error)) {
        // throw error message
        SwalUtil.fire({
          type: Constants.SWAL__TYPE__ERROR,
          title: 'Error',
          message: error,
          options: {
            showCancelButton: false,
            confirmButtonText: 'OK',
            allowOutsideClick: false,
          },
        });
      }
    }
  };

  /**
   * It helps to change data extension field in relation
   * @param {object} e - event. Use e.value to get the value
   * @returns {void}
   */
  handleSetToRelationField = async (e) => {
    const {
      toFields,
    } = this.state;

    // find the selected field
    const field = toFields.find(f => f.ObjectID === e.value);

    // if field is found
    if (field) {
      // set state with toFieldObjectId
      this.setState(prevState => ({
        relation: {
          ...prevState.relation,
          toFieldObjectId: field.ObjectID,
          toFieldName: field.Name,
          toFieldType: field.FieldType,
        },
      }));
    }
  };

  /**
   * It helps to change data extension in relation
   * @param {object} e - event. Use e.value to get the value
   * @returns {void}
   */
  handleSetFromRelationDE = async (e) => {
    const { dataExtensions, relation } = this.state;

    // set loading visible and reset additional joins
    this.setState({ loadingFromFields: true, relation: { ...relation, additionalJoins: [] } });
    try {
      // get specific data extension determined by ObjectID
      const dataExtension = dataExtensions.find(d => d.ObjectID === e.value);

      // if dataExtension is found
      if (dataExtension) {
        // get the fields based on data extension or data view
        const fromFields = await this.getDataExtensionOrDataViewFields(dataExtension);

        this.setState(prevState => ({
          relation: {
            ...prevState.relation,
            fromDECustomerKey: dataExtension.CustomerKey,
            fromDEObjectId: dataExtension.ObjectID,
            fromDEName: dataExtension.Name,
            // reset field state
            fromFieldObjectId: '',
            fromFieldName: '',
            fromFieldType: '',
          },
          fromFields,
          loadingFromFields: false,
        }));
      } else {
        // set dataextension and selected field null in aggregation state
        this.setState(prevState => ({
          relation: {
            ...prevState.relation,
            fromDECustomerKey: '',
            fromDEObjectId: '',
            fromDEName: '',
            // reset field state
            fromFieldObjectId: '',
            fromFieldName: '',
            fromFieldType: '',
          },
          loadingFromFields: false,
        }));
      }
    } catch (error) {
      if (!axios.isCancel(error)) {
        // throw error message
        SwalUtil.fire({
          type: Constants.SWAL__TYPE__ERROR,
          title: 'Error',
          message: error,
          options: {
            showCancelButton: false,
            confirmButtonText: 'OK',
            allowOutsideClick: false,
          },
        });
      }
    }
  };

  /**
   * It helps to change data extension field in relation
   * @param {object} e - event. Use e.value to get the value
   * @returns {void}
   */
  handleSetFromRelationField = async (e) => {
    const {
      fromFields,
      toFields,
      relation,
    } = this.state;

    const relationCopy = { ...relation };

    // find the selected field
    const field = fromFields.find(f => f.ObjectID === e.value);

    // if field found
    if (field) {
      // if the fields fromField and toField cannot be mapped then find the toField which is compatible
      if (!Util.canFieldBeMapped(field.FieldType, relationCopy.toFieldType) && toFields.length) {
        /**
         * Find the first compatible toField and
         * set it as the default value
         */
        const defaultToField = toFields.find(f => Util.canFieldBeMapped(
          field.FieldType,
          f.FieldType,
        ));

        relationCopy.toFieldName = defaultToField ?
          defaultToField.Name.toString() :
          toFields[0].Name.toString();

        relationCopy.toFieldType = defaultToField ?
          defaultToField.FieldType :
          toFields[0].FieldType;

        relationCopy.toFieldObjectId = defaultToField ?
          defaultToField.ObjectID :
          toFields[0].ObjectID;
      }

      relationCopy.fromFieldObjectId = field.ObjectID;
      relationCopy.fromFieldName = field.Name;
      relationCopy.fromFieldType = field.FieldType;

      // set state with toFieldObjectId
      this.setState({ relation: relationCopy });
    }
  };

  /**
   * It helps to change relation type
   * @param {object} e - event. Use e.value to get the value
   * @returns {void}
   */
  handleSetRelationType = (e) => {
    this.setState(prevState => ({
      relation: {
        ...prevState.relation,
        relation: e.value,
      },
    }));
  };

  /**
   * It cancels the current configs and reset the states
   * @returns {void}
   */
  handleCancel = async () => {
    const { openPanel } = this.props;
    const { relation, copiedRelationState } = this.state;

    /*
     * check if state is not changed then navigate to overview without showing swal
     * otherwise show swal to discard changes
     */
    if (JSON.stringify(relation) === JSON.stringify(copiedRelationState)) {
      await openPanel(this.subMenuToNavigateTo());
    } else {
      const result = await SwalUtil.fire({
        title: 'Discard changes',
        message: 'Are you sure you want to discard your changes to the relation settings?',
        options: {
          showCancelButton: true,
          confirmButtonText: 'Discard',
        },
      });

      // if pressed discard button then navigate to relations menu
      if (result.value) {
        await openPanel(this.subMenuToNavigateTo());
      }
    }
  };

  /**
   * @returns {void}
   */
  handleSubmitSave = async () => {
    const { openPanel, relationId } = this.props;
    const { relation } = this.state;

    // start saving relation
    this.setState({ savingRelation: true });

    // wait for the validation result
    const validation = await this.validateSubmitValues();

    if (validation.value) {
      // if the validation fails then return false
      this.setState({ savingRelation: false });

      return false;
    }

    try {
      const response = relationId ?
        await RelationsAPI.updateRelation(
          relationId,
          relation,
          this.axiosCancelToken.token,
        ) :
        await RelationsAPI.createRelation(
          relation,
          this.axiosCancelToken.token,
        );

      // if it succeeds
      if (response.success) {
        // navigate to relation menu
        await openPanel(this.subMenuToNavigateTo());
      } else {
        if (response.missingFields) {
          const multipleMissingFields = response.missingFields.length > 1;

          const message = `The following field${multipleMissingFields ? 's were ' : ' was '}deleted from the ` +
            `selected Data Extension${multipleMissingFields ? 's' : ''}. Please update the relationship to continue.`;

          /* eslint-disable no-await-in-loop */
          await Util.handleMissingOrRenamedField(message, response.missingFields, true);
        } else {
          // show the error
          await SwalUtil.fire({
            type: Constants.SWAL__TYPE__ERROR,
            title: 'Error',
            message: response.error,
            options: {
              showCancelButton: false,
              confirmButtonText: 'OK',
              allowOutsideClick: false,
            },
          });
        }
      }

      // stop saving relation
      this.setState({ savingRelation: false });
    } catch (error) {
      if (!axios.isCancel(error)) {
        // throw error message
        await SwalUtil.fire({
          type: Constants.SWAL__TYPE__ERROR,
          title: 'Error',
          message: error,
          options: {
            showCancelButton: false,
            confirmButtonText: 'OK',
            allowOutsideClick: false,
          },
        });
      }

      // stop saving relation
      this.setState({ savingRelation: false });
    }

    return false;
  };

  /**
   * validation for states to determine if they can be
   * saved to db or not
   * @returns {void}
   */
  validateSubmitValues = async () => {
    const { relation } = this.state;
    const { relationDataSet } = this.props;

    let errorMsg = '';

    let htmlErrorMsg = '';

    const existingRelation = this.isRelationAlreadyExists();

    if (relation) {
      // Check if there is a selected data extension
      if (!relation.fromDECustomerKey) errorMsg = 'Data extension should be selected.';
      // Check if there is selected field
      else if (!relation.fromFieldObjectId) errorMsg = 'Field should be selected.';
      // Check if there is selected relation
      else if (!relation.relation) errorMsg = 'Relation should be selected.';
      // Check if there is selected data extension
      else if (!relation.toDECustomerKey) errorMsg = 'Data extension should be selected.';
      // Check if there is selected data extension
      else if (!relation.toFieldObjectId) errorMsg = 'Field should be selected.';
      // Check if relation already exists
      else if (existingRelation) {
        errorMsg = `Relation already exists in ${existingRelation.isContactBuilderAttribute ?
          'Imported' :
          'Custom'} relations.`;
      } else if (!Util.canFieldBeMapped(relation.fromFieldType, relation.toFieldType)) {
        errorMsg = 'Fields are incompatible.';
      } else if (!this.validateAdditionalJoins()) {
        errorMsg = 'All fields in additional relations must be selected.';
      } else if (relationDataSet) {
        if (((relationDataSet.fromCollectionObjectID !== relation.fromDEObjectId) &&
          (relationDataSet.fromCollectionObjectID !== relation.toDEObjectId) ||
          ((relationDataSet.toCollectionObjectID !== relation.fromDEObjectId) &&
            (relationDataSet.toCollectionObjectID !== relation.toDEObjectId)))) {
          htmlErrorMsg = `<p class="width_swal">Unable to save changes.
          This relation is part of the data set <span class="swal_custom_bold">${relationDataSet.name}</span>.
          Please modify the data set first before making any changes here.</p>`;
        }
      }
    }

    // If any error message (i.e check failed) - Show an error to the user
    if (errorMsg || htmlErrorMsg) {
      return SwalUtil.fire({
        type: Constants.SWAL__TYPE__ERROR,
        title: 'Error',
        messageHTML: htmlErrorMsg,
        message: errorMsg,
        options: {
          showCancelButton: false,
          confirmButtonText: 'OK',
          allowOutsideClick: false,
        },
      });
    }

    return true;
  };

  /**
   * Checks if all additional joins are valid
   * @returns {boolean} True if all are valid, false otherwise
   */
  validateAdditionalJoins = () => {
    const { relation: { additionalJoins = [] } } = this.state;

    return additionalJoins.every(additionalJoin => additionalJoin.fromFieldObjectID && additionalJoin.toFieldObjectID);
  };

  /**
   * Check if relation already exists in db
   * @returns {boolean} - return isRelationExits
   */
  isRelationAlreadyExists = () => {
    const { relations, relationId } = this.props;
    const { relation } = this.state;

    let existingRelation;

    // find relation in current organization's relations
    const rel = relations?.find(re => (re.fromDECustomerKey === relation.fromDECustomerKey &&
      re.toDECustomerKey === relation.toDECustomerKey) ||
      (re.toDECustomerKey === relation.fromDECustomerKey &&
        re.fromDECustomerKey === relation.toDECustomerKey));

    // check if relation exists and this is not the relation we are currently in
    if (relationId && rel && rel?._id !== relationId) existingRelation = rel;
    if (!relationId && rel) existingRelation = rel;

    // return existing relation if exists
    return existingRelation;
  };

  /**
   * Push dataViews in dataExtensions if feature 'dataViews' is enabled and then sort it
   * @param {object} dataExtensions - List of data extensions
   * @returns {void}
   */
  addDataViewsInDEs = (dataExtensions) => {
    const { featuresInfo } = this.props;
    const featureDataViews = Features.isFeatureEnabled(featuresInfo, Constants.FEATURE__DATA_VIEWS);

    if (featureDataViews) {
      dataExtensions.push(...DataViews);
    }
    Util.sortArrayOfObjects(dataExtensions, 'Name');
  };

  /**
   * Renders all the additional joins
   * @param {array} fromFieldsOptions - An array containing from fields
   * @returns {object} - The additional joins HTML
   */
  renderAdditionalRelations = (fromFieldsOptions) => {
    const {
      relation,
      loadingFromFields,
      loadingToFields,
      toFields,
    } = this.state;

    const { additionalJoins, fromDEName, toDEName } = relation;

    /*
     * Format the fields for the dropdown
     */
    const toFieldsOptions = additionalJoinsId => toFields?.map(field => ({
      value: field.ObjectID,
      title: field.Name.toString(),
      text: field.Name.toString(),
      key: field.ObjectID,
      disabled: !Util.canFieldBeMapped(additionalJoins[additionalJoinsId].fromFieldType, field.FieldType),
    }));

    // The variable that determines whether the warning text should be shown or not
    const showFieldCompatibilityWarning = additionalJoinsId => additionalJoins[additionalJoinsId].fromFieldType &&
      additionalJoins[additionalJoinsId].toFieldType &&
      !Util.canFieldBeMappedWithWarning(
        additionalJoins[additionalJoinsId].fromFieldType,
        additionalJoins[additionalJoinsId].toFieldType,
      );

    return additionalJoins.map((additionalJoin, i) => (
      <div key={`${Date.now()} - ${i}`}>
        <div className="flex">
          <span className="label matches-with">
            And
          </span>
        </div>
        {/* eslint-disable-next-line react/no-array-index-key */}
        <div className="additional-join" key={i}>
          <div className="flex">
            <span className="label">
              Field
            </span>
            <Button
              buttonIcon
              className="delete"
              ariaDescribedBy="delete"
              ariaDisabled="true"
              title="delete"
              onClick={() => this.handleRemoveAdditionalJoins(i)}
            >
              <svg
                className="slds-button__icon slds-icon_small trash"
                aria-hidden="true"
              >
                <use xlinkHref="/assets/icons/utility-sprite/svg/symbols.svg#recycle_bin_empty" />
              </svg>
            </Button>
          </div>
          <div className="dropdown-field">
            <Dropdown
              id={'from-field-dropdown' + i}
              selection
              className="target-data-extension-dropdown searchable-dropdown"
              search
              placeholder="Choose field"
              options={fromFieldsOptions}
              value={additionalJoin.fromFieldObjectID}
              onChange={(e, data) => this.handleSetAdditionalJoinFields(data, 0, i)}
              disabled={loadingFromFields}
            />
            <div className="flex">
              <span className="label">
                On
                {' '}
                <span>{fromDEName}</span>
              </span>
            </div>
          </div>
          <div className="flex">
            <span className="label matches-with">
              Matches with
            </span>
          </div>
          <div className="flex">
            <span className="label">
              Field
            </span>
          </div>
          <div className="dropdown-field">
            <Dropdown
              id={'to-field-dropdown' + i}
              selection
              className="target-data-extension-dropdown searchable-dropdown"
              search
              placeholder="Choose field"
              options={toFieldsOptions(i)}
              value={additionalJoin.toFieldObjectID}
              onChange={(e, data) => this.handleSetAdditionalJoinFields(data, 1, i)}
              disabled={loadingToFields}
            />
            <div className="flex">
              <span className="label">
                On
                {' '}
                <span>{toDEName}</span>
              </span>
            </div>
            {
              showFieldCompatibilityWarning(i) && (
                <span className="warning">
                  {Constants.RELATION_MODAL__JOIN_WARNING__TEXT}
                </span>
              )
            }
          </div>
        </div>
      </div>
    ));
  };

  /**
   * Adds a new additional joins
   * @returns {void}
   */
  addNewRelation = () => {
    this.setState(prevState => ({
      relation: {
        ...prevState.relation,
        additionalJoins: [
          ...prevState.relation.additionalJoins,
          {
            fromFieldObjectID: '',
            fromFieldType: '',
            toFieldObjectID: '',
            toFieldType: '',
          },
        ],
      },
    }));
  };

  /**
   * Handles the removal of an additional join
   * @param {number} index - index of the join to remove
   * @returns {void}
   */
  handleRemoveAdditionalJoins = index => this.setState(prevState => ({
    relation: {
      ...prevState.relation,
      additionalJoins: [
        ...prevState.relation.additionalJoins.slice(0, index),
        ...prevState.relation.additionalJoins.slice(index + 1),
      ],
    },
  }));

  /**
   * It helps to change data extension field in an additional join relation
   * @param {object} e - event. Use e.value to get the value
   * @param {number} type - indicates the type of field; 0 for fromField, 1 for toField
   * @param {number} index - index of the additional join
   * @returns {void}
   */
  handleSetAdditionalJoinFields = (e, type, index) => {
    const { relation, fromFields, toFields } = this.state;

    const fieldsToSearch = type === 0 ? fromFields : toFields;

    const relationsCopy = { ...relation };
    const additionalJoinsCopy = [...relationsCopy.additionalJoins];

    // find the selected field
    const field = fieldsToSearch.find(f => f.ObjectID === e.value);

    // if field is found
    if (field) {
      // if we're changing fromField
      if (type === 0) {
        // Set default toField if fields can be mapped and toFields array is not empty
        if (!Util.canFieldBeMapped(field.FieldType, additionalJoinsCopy[index].toFieldType) && toFields.length) {
          /**
           * Find the first compatible toField and
           * set it as the default value
           */
          const defaultToField = toFields.find(f => Util.canFieldBeMapped(
            field.FieldType,
            f.FieldType,
          ));

          additionalJoinsCopy[index].toFieldObjectID = defaultToField ?
            defaultToField.ObjectID :
            toFields[0].ObjectID;

          additionalJoinsCopy[index].toFieldType = defaultToField ?
            defaultToField.FieldType :
            toFields[0].FieldType;

          additionalJoinsCopy[index].fromFieldObjectID = field.ObjectID;
          additionalJoinsCopy[index].fromFieldType = field.FieldType;

          this.setState({ relation: { ...relationsCopy, additionalJoins: additionalJoinsCopy } });
        } else {
          // Just set fromField
          additionalJoinsCopy[index].fromFieldObjectID = field.ObjectID;
          additionalJoinsCopy[index].fromFieldType = field.FieldType;

          this.setState({ relation: { ...relationsCopy, additionalJoins: additionalJoinsCopy } });
        }
      } else {
        // We're changing toField
        additionalJoinsCopy[index].toFieldObjectID = field.ObjectID;
        additionalJoinsCopy[index].toFieldType = field.FieldType;

        // set state with toFieldObjectId
        this.setState(prevState => ({
          relation: {
            ...prevState.relation,
            additionalJoins: additionalJoinsCopy,
          },
        }));
      }
    }
  };

  render() {
    const {
      relationId,
      activePanel,
      navigateToMenu,
      submenu,
    } = this.props;
    const {
      fromFields,
      toFields,
      dataExtensions,
      loading,
      relation,
      loadingFromFields,
      loadingToFields,
      savingRelation,
    } = this.state;

    const isCreateANewRelation = activePanel === Constants.ADMIN_PANEL__MENU__NEW_RELATION;

    /**
     * Check if relation is contact builder relation
     */
    const isContactBuilderRelation = !!relation?.isContactBuilderAttribute;

    /**
     * Render heading depending on if relation is contact builder or not
     */
    const relationPanelHeading = isContactBuilderRelation ? 'Contact Relation Details' : 'Define a relation';

    /**
     * Render description depending on if relation is contact builder or not
     */
    const relationPanelInfoText = isContactBuilderRelation ?
      `Contact relations are pulled from contact builder.
    You can view the relationship details between data extensions as created in contact builder.
    You cannot edit contact relationship details in this panel.` :
      `As an admin, you can define relations between a data extension and other data extensions
        or data views. This can simplify the life of marketers in DESelect a lot, as they don't
        have to always indicate how different data extensions / data views are related.`;

    /**
     * Get the relation button label
     * @returns {string} The relation button label
     */
    const renderRelationLabel = () => {
      if (isContactBuilderRelation) return 'View Contact Builder Relation';
      if (relationId && activePanel === Constants.ADMIN_PANEL__MENU__EDIT_RELATION) return 'Edit Relation';
      if (isCreateANewRelation) return 'New Relation';

      return 'Relations';
    };

    /**
     * Get the Data Extension from all available Data Extensions
     * @param {string} customerKey - Customer Key of the Data Extension we are looking for
     * @returns {object|null} found DE or null if not found
     */
    const getDEInAvailableDEs = customerKey => dataExtensions?.find((data) => {
      return data?.CustomerKey?.toString() === customerKey?.toString() ?
        data :
        null;
    });

    /**
     * Get option for DE depending on whether it is excluded/included in availableDE.
     * If selected DE is excluded in availableDE, then show it in the dropdown as selected option,
     * but don't add it as an option to choose from
     * @param {boolean} isFrom - defines whether DE is used in a relation as fromDE
     * @returns {object|null} found DE or null if not found
     */
    const relationDEForOption = isFrom => (
      getDEInAvailableDEs(isFrom ? relation.fromDECustomerKey : relation.toDECustomerKey) || isCreateANewRelation ?
        [] :
        [{
          value: isFrom ? relation.fromDEObjectId : relation.toDEObjectId,
          selected: true,
          disabled: true,
          hidden: true,
          title: isFrom ? relation.fromDEName : relation.toDEName,
          text: isFrom ? relation.fromDEName : relation.toDEName,
          key: isFrom ? relation.fromDEObjectId : relation.toDEObjectId,
        }]
    );

    /*
     * Format the data extensions for from DE dropdown
     * Dataviews are filtered out because it doesn't make sense for them to be in this dropdown
     */
    const fromDeOptions = [
      ...relationDEForOption(true),
      ...Util.formattedDataForTheDropdown(dataExtensions, 'ObjectID', 'Name')
        .filter(de => de.text ? de.text[0] !== '_' : false)];

    /*
     * Format the data extensions for to DE dropdown
     */
    const toDeOptions = [
      ...relationDEForOption(),
      ...Util.formattedDataForTheDropdown(dataExtensions, 'ObjectID', 'Name'),
    ];

    /*
     * Format the fields for the dropdown
     */
    const fromFieldsOptions = Util.formattedDataForTheDropdown(fromFields, 'ObjectID', 'Name');

    /*
     * Format the fields for the dropdown
     */
    const toFieldsOptions = Util.formattedDataForTheDropdown(
      toFields,
      'ObjectID',
      'Name',
      null,
      field => !Util.canFieldBeMapped(relation.fromFieldType, field.FieldType),
    );

    // The variable that determines whether the warning text should be shown or not
    const showFieldCompatibilityWarning = relation.fromFieldType && relation.toFieldType &&
      !Util.canFieldBeMappedWithWarning(
        relation.fromFieldType,
        relation.toFieldType,
      );

    // render loading text based on panel type
    if (loading) {
      return (
        <LoadingModal
          closeModal={() => navigateToMenu(submenu)}
          loadingText={relationId ? 'Relation is loading...' : 'Loading...'}
          id="loadingmodal-wrapper"
        />
      );
    }

    // relation type options to be populated
    const relationTypeOptions = () => (
      [
        {
          key: Constants.RELATION__TYPE__ONE_TO_MANY,
          text: '1-to-many',
          value: Constants.RELATION__TYPE__ONE_TO_MANY,
        },
        {
          key: Constants.RELATION__TYPE__ONE_TO_ONE,
          text: '1-to-1',
          value: Constants.RELATION__TYPE__ONE_TO_ONE,
        },
      ]);

    const contactRelationTypeOptions = () => (
      [
        {
          key: Constants.RELATION__TYPE__ONE_TO_MANY,
          text: '1-to-many',
          value: Constants.RELATION__TYPE__ONE_TO_MANY,
        },
        {
          key: Constants.RELATION__TYPE__ONE_TO_ONE,
          text: '1-to-1',
          value: Constants.RELATION__TYPE__ONE_TO_ONE,
        },
        {
          key: Constants.RELATION__TYPE__MANY_TO_ONE,
          text: 'many-to-1',
          value: Constants.RELATION__TYPE__MANY_TO_ONE,
        },
        {
          key: Constants.RELATION__TYPE__MANY_TO_MANY,
          text: 'many-to-many',
          value: Constants.RELATION__TYPE__MANY_TO_MANY,
        },
      ]);

    return (

      <div className="relation-container">
        <div className="slds-page-header slds-m-bottom_medium">
          <div className="slds-page-header__row">
            <div className="slds-page-header__col-title">
              <div className="slds-media header-alignment">
                <div className="slds-media__figure">
                  <span className="slds-icon_container slds-icon-standard-account" title="Relations">
                    <svg className="slds-icon slds-page-header__icon" aria-hidden="true">
                      <use xlinkHref="/assets/icons/standard-sprite/svg/symbols.svg#picklist_type" />
                    </svg>
                  </span>
                </div>
                <div className="slds-media__body">
                  <div className="slds-page-header__name">
                    <div className="slds-page-header__name-title">
                      <h1>
                        <span
                          className="slds-page-header__title slds-truncate"
                          title="Relations"
                          id="picklist-header-title"
                        >
                          {renderRelationLabel()}
                        </span>
                      </h1>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="relation-panel-container" id="relation-panel">
          <div className="relation_wrapper">
            <div className="aggregation-content">
              <div className="relation_header">
                <div className="relation_header-title">{relationPanelHeading}</div>
              </div>
              <div className="relation_info_text">
                <p className="info_text">
                  {relationPanelInfoText}
                </p>
              </div>
              <div className="field-container margin-top">
                <div className="field-content">
                  <div className="data-extensions-container">
                    <div className="flex">
                      <span className="label">Data Extension</span>
                    </div>
                    <div className="dropdown-field data-extension">
                      <Dropdown
                        id="from-data-extensions-dropdown"
                        selection
                        className="target-data-extension-dropdown searchable-dropdown"
                        search
                        placeholder="Choose data extension"
                        loading
                        options={fromDeOptions}
                        value={relation.fromDEObjectId}
                        disabled={isContactBuilderRelation}
                        onChange={(e, data) => this.handleSetFromRelationDE(data)}
                        allowAdditions={false}
                      />
                    </div>
                    <div className="flex">
                      <span className="label">Has a </span>
                    </div>
                    <div className="dropdown-field">
                      <Dropdown
                        id="relation-dropdown"
                        selection
                        className="target-data-extension-dropdown searchable-dropdown"
                        placeholder="Select relation"
                        options={
                          submenu ===
                            Constants.ADMIN_PANEL__SUBMENU__PREDEFINED_RELATIONS ?
                            relationTypeOptions() :
                            contactRelationTypeOptions()
                        }
                        value={relation.relation}
                        disabled={isContactBuilderRelation}
                        onChange={(e, data) => this.handleSetRelationType(data)}
                      />
                    </div>
                    <div className="flex">
                      <span className="label">Relation with</span>
                    </div>
                    <div className="dropdown-field data-extension">
                      <Dropdown
                        id="to-data-extensions-dropdown"
                        selection
                        className="target-data-extension-dropdown searchable-dropdown"
                        search
                        placeholder="Choose data extension"
                        options={toDeOptions}
                        value={relation.toDEObjectId}
                        disabled={isContactBuilderRelation}
                        onChange={(e, data) => this.handleSetToRelationDE(data)}
                      />
                    </div>
                  </div>
                  <div className="fields-container">
                    <div className="flex">
                      <span className="label">
                        Field
                      </span>
                    </div>
                    <div className="dropdown-field">
                      <Dropdown
                        id="from-field-dropdown"
                        selection
                        className="target-data-extension-dropdown searchable-dropdown"
                        search
                        placeholder="Choose field"
                        options={fromFieldsOptions}
                        value={relation.fromFieldObjectId}
                        onChange={(e, data) => this.handleSetFromRelationField(data)}
                        disabled={loadingFromFields || isContactBuilderRelation}
                      />
                      <div className="flex">
                        <span className="label">
                          On
                          {' '}
                          <span>{relation.fromDEName}</span>
                        </span>
                      </div>
                    </div>
                    <div className="flex">
                      <span className="label matches-with">
                        Matches with
                      </span>
                    </div>
                    <div className="flex">
                      <span className="label">
                        Field
                      </span>
                    </div>
                    <div className="dropdown-field">
                      <Dropdown
                        id="to-field-dropdown"
                        selection
                        className="target-data-extension-dropdown searchable-dropdown"
                        search
                        placeholder="Choose field"
                        options={toFieldsOptions}
                        value={relation.toFieldObjectId}
                        onChange={(e, data) => this.handleSetToRelationField(data)}
                        disabled={loadingToFields || isContactBuilderRelation}
                      />
                      <div className="flex">
                        <span className="label">
                          On
                          {' '}
                          <span>{relation.toDEName}</span>
                        </span>
                      </div>
                      {
                        showFieldCompatibilityWarning && (
                          <span className="warning">
                            {Constants.RELATION_MODAL__JOIN_WARNING__TEXT}
                          </span>
                        )
                      }
                    </div>
                  </div>
                  {relation?.additionalJoins?.length > 0 && (
                    <div className="additional-joins-container">
                      {this.renderAdditionalRelations(fromFieldsOptions, toFieldsOptions)}
                    </div>
                  )}
                  {/* Only show button if main relation exists */}
                  {relation.fromFieldObjectId && relation.toFieldObjectId && !isContactBuilderRelation && (
                    <div className="add-relationship-btn-container">
                      <Button
                        buttonLook={Constants.BUTTON__TYPE__NEUTRAL}
                        className="add-relationship-btn"
                        ariaDescribedBy="addRelationship"
                        title="addRelationship"
                        disabled={isContactBuilderRelation}
                        onClick={this.addNewRelation}
                      >
                        <svg
                          className="slds-button__icon slds-icon_small plus-icon"
                          aria-hidden="true"
                        >
                          <use xlinkHref="/assets/icons/utility-sprite/svg/symbols.svg#add" />
                        </svg>
                        Add Relationship
                      </Button>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>

        {isContactBuilderRelation ?
          <div className="slds-grid cancel-save-relation">
            <div className="slds-col_bump-left">
              <Button
                buttonLook={Constants.BUTTON__TYPE__BRAND}
                id="back-button"
                onClick={() => navigateToMenu(Constants.ADMIN_PANEL__SUBMENU__CONTACT_ATTRIBUTES)}
              >
                Go Back
              </Button>
            </div>
          </div> :
          <div className="slds-grid cancel-save-relation">
            <div className="slds-col_bump-left">
              <Button
                id="cancel-relation"
                buttonLook={Constants.BUTTON__TYPE__NEUTRAL}
                label="Cancel"
                onClick={() => this.handleCancel()}
                disabled={isContactBuilderRelation}
              >
                Cancel
              </Button>
              <Button
                id="save-relation"
                buttonLook={Constants.BUTTON__TYPE__BRAND}
                onClick={e => this.handleSubmitSave(e)}
                loadingClickedButton={savingRelation}
                titleInAction="Saving..."
                disabled={savingRelation || isContactBuilderRelation}
              >
                Save
              </Button>
            </div>
          </div>}

      </div>
    );
  }
}

RelationPanel.propTypes = {
  /**
   * It keeps the id value of a relation to be able it
   */
  relationId: PropTypes.string,
  /**
   * It helps to navigate between page and Admin Panel with fetching data
   */
  openPanel: PropTypes.func.isRequired,
  /**
   * It keeps location where we are entering in
   */
  activePanel: PropTypes.string.isRequired,
  /**
   * array containing the relations retrieved, this prop comes
   * from the admin panel component
   */
  relations: PropTypes.instanceOf(Array).isRequired,
  /**
   * object containing the relation retrieved, this prop comes
   * from the admin panel component
   */
  selectedRelation: PropTypes.instanceOf(Object).isRequired,
  /**
   * Data set relation that is used in a certain predefined relation
   */
  relationDataSet: PropTypes.instanceOf(Object),
  /*
   * It helps to navigate between pages in Admin Panel without fetching data
   */
  navigateToMenu: PropTypes.func.isRequired,

  /**
   * Get active submenu to navigate to on cancel
   */
  submenu: PropTypes.string,
  /**
   * Features info from cookie
   */
  featuresInfo: PropTypes.object,
};

export default connect(mapStateToProps(['featuresInfo']), null, null, { pure: false })(RelationPanel);
