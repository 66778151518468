import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Constants from '../../../../constants/constants';
import Tooltip from '../../../../components/shared/Tooltip/Tooltip';
import './styles.scss';
import Button from '../../../../components/shared/Button/Button';
import ToggleButton from '../../../../components/shared/ToogleButton/ToggleButton';
import Input from '../../../../components/shared/Input/Input';
import timeUtil from '../../../../utils/time/timeUtil';
import SwalUtil from '../../../../utils/swal/swalUtil';

class AutoRefreshPicklistValues extends Component {
  constructor(props) {
    super(props);
    const { picklist } = props;

    this.state = {
      autoRefresh: picklist && picklist.autoRefresh ?
        picklist.autoRefresh :
        false,
      refreshTime: picklist && picklist.refreshTime ?
        picklist.refreshTime :
        0,
      refreshTimeLabel: this.setAutoRefreshTimeLabel(picklist && picklist.refreshTime ? picklist.refreshTime : 0),
      daysOfWeek: picklist && picklist.daysOfWeek ?
        [...picklist.daysOfWeek] :
        [],
      openTimePicker: false,
    };
  }

  /**
   * set label for the refresh time
   * @param {number} refreshTime - value from 0 to 23
   * @returns {string} - label for the hours input
   */
  setAutoRefreshTimeLabel = (refreshTime) => {
    let autoRefreshTimeLabel = '00:00';

    if (refreshTime < 10) {
      autoRefreshTimeLabel = `0${refreshTime}:00`;
    } else {
      autoRefreshTimeLabel = `${refreshTime}:00`;
    }

    return autoRefreshTimeLabel;
  };

  /**
   * onClick event handler for the auto refresh button
   * @returns {void}
   */
  handleOpenCloseAutoRefreshSettings = () => {
    const { handleSetPicklistsAEState, openAutoRefreshSettings } = this.props;

    handleSetPicklistsAEState({
      openSplitSeparatorsSettings: false,
      openAutoRefreshSettings: !openAutoRefreshSettings,
    });
  };

  /**
   * onChange event handler for the auto refresh toggle button
   * @returns {void}
   */
  handleToggleAutoRefreshPicklistValues = () => {
    this.setState(prevState => ({ autoRefresh: !prevState.autoRefresh }));
  };

  /**
   * onChange event handler for the refresh time
   * @param {object} e - onChange event object
   * @returns {void}
   */
  handleRefreshTime = (e) => {
    this.setState({
      refreshTime: parseInt(e.target.id),
      openTimePicker: false,
      refreshTimeLabel: this.setAutoRefreshTimeLabel(parseInt(e.target.id)),
    });
  };

  /**
   * onChange event handler for the days of week checkbox
   * @param {object} e - onChange event object
   * @returns {void}
   */
  handleDaysOfWeekCheckbox = (e) => {
    const { daysOfWeek } = this.state;

    // get the array with selected and sorted days of week
    const selectedDaysOfWeek = timeUtil.setDaysOfWeekInArray(e, daysOfWeek);

    this.setState({ daysOfWeek: selectedDaysOfWeek });
  };

  /**
   * onClick event handler for the Cancel button
   * @returns {void}
   */
  handleCancelAutoRefreshPicklistValues = () => {
    const { picklist, handleSetPicklistsAEState } = this.props;
    // reset all states to previous changes

    this.setState({
      autoRefresh: picklist && picklist.autoRefresh ?
        picklist.autoRefresh :
        false,
      refreshTime: picklist && picklist.refreshTime ?
        picklist.refreshTime :
        0,
      refreshTimeLabel: this.setAutoRefreshTimeLabel(picklist && picklist.refreshTime ? picklist.refreshTime : 0),
      daysOfWeek: picklist && picklist.daysOfWeek ?
        [...picklist.daysOfWeek] :
        [],
      openTimePicker: false,
    });

    handleSetPicklistsAEState({ openAutoRefreshSettings: false });
  };

  /**
   * onClick event handler for the Save button
   * @returns {void}
   */
  handleSaveAutoRefreshPicklistValues = async () => {
    const {
      autoRefresh,
      refreshTime,
      daysOfWeek,
    } = this.state;

    const { handleSetPicklistsAEState, picklist } = this.props;
    // if auto refresh is on

    if (autoRefresh) {
      /*
       * check if the day or days are picked
       * if not throw a swal
       */
      if (daysOfWeek.length === 0) {
        await SwalUtil.fire({
          title: 'Auto refresh picklist',
          message: 'Please pick the day(s) to auto-refresh the picklist.',
          options: {
            confirmButtonText: 'OK',
            allowOutsideClick: false,
          },
        });

        return;
      }
    }

    // set picklist states
    handleSetPicklistsAEState({
      picklist: {
        ...picklist,
        autoRefresh,
        refreshTime,
        daysOfWeek,
      },
      openAutoRefreshSettings: false,
    });
  };

  /**
   * If user clicks outside timepicker then close timepicker
   * @returns {void}
   */

  handleCloseTimePicker = () => {
    const { openTimePicker } = this.state;
    // if time picker was opened close it

    if (openTimePicker) {
      this.setState({ openTimePicker: false });
    }
  };

  render() {
    const {
      autoRefresh,
      refreshTime,
      daysOfWeek,
      refreshTimeLabel,
      openTimePicker,
    } = this.state;

    const { disableAutoRefreshPicklistValues, openAutoRefreshSettings } = this.props;

    // array of the days for easier rendering
    const daysOfWeekArray = timeUtil.returnDaysOfWeek();

    return (
      <div className="slds-form-element">
        {/* Button to open Auto refresh picklist modal */}
        <Button
          buttonLook={Constants.BUTTON__TYPE__NEUTRAL}
          className="retrieve-value-button"
          onClick={() => this.handleOpenCloseAutoRefreshSettings()}
          disabled={disableAutoRefreshPicklistValues}
          id="autoRefreshButton"
          title="Auto refresh values from Data extension"
          labelId="autoRefreshButtonLabel"
        >
          Auto Refresh
        </Button>
        {openAutoRefreshSettings && (
          <div className="autorefresh-settings-container">
            <div className="slds-button-group" role="group">
              <div className="slds-button slds-button_icon">
                <div
                  className={
                    `slds-dropdown slds-dropdown_right
                    auto-refresh-settings slds-dropdown_actions ${autoRefresh && 'active'}`
                  }
                  id="autoRefreshSettings"
                >
                  <ul
                    className="slds-dropdown__list"
                    role="menu"
                    onClick={() => this.handleCloseTimePicker()}
                  >
                    <ToggleButton
                      label="Refresh"
                      labelId="toggle-button-label"
                      labelClassName="auto-refresh-label"
                      id="autoRefreshToggle"
                      name="autoRefreshToggle"
                      onChange={this.handleToggleAutoRefreshPicklistValues}
                      checked={autoRefresh}
                    />
                    <li
                      className="slds-dropdown__item"
                      role="presentation"
                      id="set-data-action"
                    >
                      <div>
                        <div className="slds-form-element">
                          <div className="slds-form-element__label">
                            Hour
                          </div>
                          <div className="slds-form-element__control input-time-picker">
                            <div className="slds-combobox_container">
                              <div
                                className="slds-combobox slds-dropdown-trigger
                                slds-dropdown-trigger_click slds-is-open slds-combobox-picklist slds-timepicker"
                                aria-expanded="true"
                                aria-haspopup="listbox"
                                aria-controls="time-picker-input"
                                role="combobox"
                              >
                                <div
                                  className="slds-combobox__form-element slds-input-has-icon
                                    slds-input-has-icon_right"
                                  role="none"
                                >
                                  {/* Here will be displayed the picked hour and also by clicking
                                  this input hours for selection will be displayed */}
                                  <Input
                                    className="slds-combobox__input slds-combobox__input-value"
                                    id="time-picker-input"
                                    ariaAutoComplete="list"
                                    autoComplete="off"
                                    placeholder=" "
                                    readOnly
                                    value={refreshTimeLabel}
                                    onClick={() => {
                                      this.setState({ openTimePicker: true });
                                    }}
                                  />
                                  <span
                                    className="slds-icon_container slds-icon-utility-clock slds-input__icon
                                    slds-input__icon_right hours-icon"
                                  >
                                    <svg
                                      className="slds-icon slds-icon slds-icon_x-small
                                      slds-icon-text-default"
                                      aria-hidden="true"
                                    >
                                      <use xlinkHref="/assets/icons/utility-sprite/svg/symbols.svg#clock" />
                                    </svg>
                                  </span>
                                </div>
                                {openTimePicker && (
                                  <div
                                    id="listbox-unique-id"
                                    role="listbox"
                                    className="slds-dropdown slds-dropdown_fluid slds-dropdown_length-5 time-picker"
                                  >
                                    <ul
                                      className="slds-listbox slds-listbox_vertical"
                                      role="presentation"
                                    >
                                      {/* Array with a length of 24 because there are 24 hours in a day */}
                                      {[...Array(24)].map((x, i) => (
                                        <li
                                          role="presentation"
                                          className="slds-listbox__item"
                                          // eslint-disable-next-line react/no-array-index-key
                                          key={`${i}`}
                                        >
                                          <div
                                            id={`${i}`}
                                            className={
                                              'slds-media slds-listbox__option ' +
                                            'slds-listbox__option_plain slds-media_small ' +
                                            'slds-media_center ' + (refreshTime === i ? 'slds-is-selected' : '')
                                            }
                                            onClick={e => this.handleRefreshTime(e)}
                                            title={this.setAutoRefreshTimeLabel(i)}
                                          >
                                            <span
                                              className="slds-media__figure"
                                              id={`${i}`}
                                              title={this.setAutoRefreshTimeLabel(i)}
                                            >
                                              <svg
                                                className="slds-icon slds-icon_x-small slds-listbox__icon-selected"
                                                aria-hidden="true"
                                                id={`${i}`}
                                                title={this.setAutoRefreshTimeLabel(i)}
                                              >
                                                <use
                                                  xlinkHref="/assets/icons/utility-sprite/svg/symbols.svg#check"
                                                  id={`${i}`}
                                                  title={this.setAutoRefreshTimeLabel(i)}
                                                />
                                              </svg>
                                            </span>
                                            <span
                                              className="slds-media__body"
                                              id={`${i}`}
                                              title={this.setAutoRefreshTimeLabel(i)}
                                            >
                                              <span
                                                className="slds-truncate"
                                                title={this.setAutoRefreshTimeLabel(i)}
                                                id={`${i}`}
                                              >
                                                {this.setAutoRefreshTimeLabel(i)}
                                              </span>
                                            </span>
                                          </div>
                                        </li>
                                      ))}
                                    </ul>
                                  </div>
                                )}
                              </div>
                            </div>
                            <div className="UTC-styling">
                              UTC
                              <Tooltip
                                nubbinPosition={Constants.NUBBIN_POSITION__BOTTOM_RIGHT}
                                type={Constants.TOOLTIP_TYPE__UTC}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </li>
                    <li
                      className="slds-dropdown__item"
                      role="presentation"
                    >
                      <div>
                        <div className="slds-form-element__label">
                          Day
                        </div>
                        {/* Displaying the days that user can choose to schedule the auto refresh picklist */}
                        {daysOfWeekArray.map((day, index) => (
                          <div
                            className="slds-dropdown__item"
                            role="presentation"
                            title={day}
                            id="days-of-week"
                            // eslint-disable-next-line react/no-array-index-key
                            key={index + 1}
                          >
                            {day}
                            <Input
                              type="checkbox"
                              name="checkbox-onoff"
                              className="days-of-week-checkbox"
                              id={index + 1}
                              onChange={e => this.handleDaysOfWeekCheckbox(e)}
                              checked={daysOfWeek.includes(index + 1)}
                              noInputClassName
                              />
                          </div>
                        ))}
                      </div>
                    </li>
                  </ul>
                  <footer className="footer" id="action-footer">
                    <Button
                      buttonLook={Constants.BUTTON__TYPE__NEUTRAL}
                      id="action-cancel"
                      onClick={() => this.handleCancelAutoRefreshPicklistValues()}
                    >
                      Cancel
                    </Button>
                    <Button
                      buttonLook={Constants.BUTTON__TYPE__BRAND}
                      id="action-save"
                      onClick={() => this.handleSaveAutoRefreshPicklistValues()}
                    >
                      Confirm
                    </Button>
                  </footer>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

AutoRefreshPicklistValues.propTypes = {
  /**
   * The current picklist
   */
  picklist: PropTypes.instanceOf(Object),
  /**
   * Function for setting PicklistsAE state
   */
  handleSetPicklistsAEState: PropTypes.func.isRequired,
  /**
   * The disable state of the auto refresh picklist values button
   */
  disableAutoRefreshPicklistValues: PropTypes.bool.isRequired,
  /**
   * Determines if the auto refresh picklist settings are opened or not
   */
  openAutoRefreshSettings: PropTypes.bool.isRequired,
};

export default AutoRefreshPicklistValues;
