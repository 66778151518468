import React from 'react';
import PropTypes from 'prop-types';
import './styles.scss';
import DOMPurify from 'dompurify';
import sqlFormatter from '@sqltools/formatter';
import classNames from 'classnames';
import { connect } from 'react-redux';

import mapStateToProps from '../../../mapStateToProps';
import QueryActivitiesAPI from '../../../api/query-activities';
import SelectionsAPI from '../../../api/selections';
import Constants from '../../../constants/constants';
import Util from '../../../util';
import SQLQueryModal from './SQLQueryModal';
import Button from '../../shared_v2/Button/Button';
import ProgressBar from '../../shared_v2/ProgressBar/ProgressBar';
import Alert from '../../shared_v2/Alert/Alert';
import timeUtil from '../../../utils/time/timeUtil';
import SwalUtil from '../../../utils/swal/swalUtil';
import WarningAlert from '../../shared_v2/WarningAlert/WarningAlert';
import GuidanceTip from '../../shared_v2/GuidanceTip/GuidanceTip';
import RunPreviewLogsAPI from '../../../api/run-preview-logs';
import PreviewTips from './TargetDefinition/PreviewTips/PreviewTips';
import SelectionNavigator from '../../NewNavbar/SelectionNavBar/SelectionNavigator';

class Preview extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      validatingSelection: false,
      showSQLQuery: false,
      cancelClicked: false,
      isRunPreviewClicked: false,
    };
  }

  // eslint-disable-next-line camelcase
  async UNSAFE_componentWillMount() {
    const {
      currentSelectionId,
      previewStatus,
      runPreviewQueryActivityStarted,
      switchedToOtherTab,
      handleSetSelectionState,
    } = this.props;

    /*
     * if a different tab is pressed while running the preview and then we come back
     * just do nothing here and let the started query to finish
     */
    if (!switchedToOtherTab) {
      // if the preview (with dedup or without) has not been completed yet
      if (previewStatus !== Constants.STATUS_COMPLETE &&
        previewStatus !== null && previewStatus !== undefined) {
        /*
         * if runPreviewQueryActivityStarted is true (means that preview is started)
         * proceed with loading preview data
         */
        if (runPreviewQueryActivityStarted) {
          // here you started getting previewData
          this.loadPreviewData();
        } else {
          // otherwise, start the preview from the beginning
          const createResult = await this.runPreviewQueryActivity(
            currentSelectionId,
          );

          // render preview if api req. is successful
          if (createResult?.success) {
            handleSetSelectionState({ previewTaskId: createResult.taskId });

            // here you started getting previewData
            this.loadPreviewData();
          }
        }
      }
    }
  }

  /**
   * When user clicked Run Preview
   * @returns {void}
   */
  handleRunPreview = async () => {
    const {
      handleSetSelectionState,
      validateIfQueryCanBeRun,
      saveSelection,
      usePrioDeduplication,
      nothingHasChanged,
      timer,
    } = this.props;

    const { cancelClicked } = this.state;

    if (!cancelClicked) {
      // This will clear timeout of loadPreviewData function
      clearTimeout(timer);

      let { currentSelectionId } = this.props;

      let saveSelectionResult;

      let saveSuccessful;

      handleSetSelectionState({
        disablePreviewBTN: true,
        previewStatus: null,
        runPreviewQueryActivityStarted: false,
        switchedToOtherTab: true,
      });

      try {
        saveSelectionResult = await saveSelection(true, nothingHasChanged);
        saveSuccessful = saveSelectionResult && saveSelectionResult.success;
        if (saveSuccessful) {
          currentSelectionId = saveSelectionResult.selectionId;
        }

        // Button should be enabled regardless of the outcome of the save attempt
        handleSetSelectionState({ disablePreviewBTN: false });
      } catch (err) {
        handleSetSelectionState({ error: err });
      }

      // check de, target de, fields
      if ((await validateIfQueryCanBeRun(false)) && saveSuccessful) {
        /**
         * On click set previewDataRetrieved and previewTaskCompleted to false
         * because you started getting previewData from API
         * Set loadingBarIcon to true, to be able to display the status icons on the progress bar
         */
        this.setState({
          validatingSelection: true,
        });

        // reset all states related with preview page
        handleSetSelectionState({
          previewData: [],
          fieldsMetaData: [],
          previewTaskId: '',
          previewDataExtensionCustomerKey: '',
          numberOfResults: null,
          previewDedupNumberOfRecords: null,
          previewStatus: Constants.STATUS_CREATED,
          previewError: null,
          disablePreviewBTN: false,
          loadingBarIcon: true,
          previewDataRetrieved: false,
          previewTaskCompleted: false,
          runPreviewQueryActivityStarted: true,
          /*
           * if priodedup is in usage set 'previewDeduplicationTaskStatus' to queued state
           * because 'previewDeduplicationTaskStatus' state, while running the preview, can become -1
           */
          previewDeduplicationTaskStatus: usePrioDeduplication ? Constants.STATUS_QUEUED : null,
        });

        this.setState({ validatingSelection: false });

        const createResult = await this.runPreviewQueryActivity(
          currentSelectionId,
        );

        // render preview if api req. is successful
        if (createResult?.success) {
          handleSetSelectionState({ previewTaskId: createResult.taskId });

          // here you started getting previewData
          this.loadPreviewData();

          // render error
        } else {
          handleSetSelectionState(prevState => ({
            previewError: createResult ? createResult.error : prevState.previewError,
            previewStatus: Constants.STATUS_ERROR,
            disablePreviewBTN: false,
          }));
        }
      }
    }
  };

  /**
   * When user clicks Cancel Preview
   * @param {String} selectionId - Selection id
   * @param {String} taskId - Task id
   * @returns {Object} - Success: true or error
   */
  handleCancelPreview = async (selectionId, taskId) => {
    const { axiosCancelToken, handleSetSelectionState, timer } = this.props;

    try {
      // This will clear timeout of loadPreviewData function
      clearTimeout(timer);

      // Set cancel button to clicked
      this.setState({ cancelClicked: true });

      // Send a request to the backend to cancel preview
      const res = await QueryActivitiesAPI.cancelPreview({
        selectionId,
        taskId,
        axiosCancelToken,
      });

      if (res.success) {
        this.setState({ cancelClicked: false, isRunPreviewClicked: false });

        // Reset preview state
        handleSetSelectionState({
          previewTaskId: null,
          previewData: [],
          fieldsMetaData: [],
          previewDataExtensionCustomerKey: '',
          numberOfResults: null,
          previewDedupNumberOfRecords: null,
          previewStatus: null,
          previewError: null,
          disablePreviewBTN: false,
          loadingBarIcon: false,
          previewDataRetrieved: false,
          previewTaskCompleted: false,
          runPreviewQueryActivityStarted: false,
          previewDeduplicationTaskStatus: null,
        });
      }

      return res;
    } catch (error) {
      this.setState({ cancelClicked: false });
      handleSetSelectionState({
        previewTaskId: null,
      });

      SwalUtil.fire({
        type: Constants.SWAL__TYPE__ERROR,
        message: error,
      });
    }
  };

  /**
   * Run the preview QueryActivity
   * @param {string} selectionId - id of a selection
   * @returns {object|null} - an object or null
   */
  runPreviewQueryActivity = async (selectionId) => {
    const { axiosCancelToken, handleSetSelectionState } = this.props;

    try {
      const res = await QueryActivitiesAPI.runPreviewQueryActivity(
        selectionId,
        axiosCancelToken,
      );

      return res;
    } catch (error) {
      handleSetSelectionState({ previewError: error });
    }

    return null;
  };

  /**
   * Check if query is ready, and load the data on the screen if ready
   * @returns {void}
   */
  loadPreviewData = async () => {
    const {
      previewStatus,
      numberOfResults,
      currentSelectionId,
      axiosCancelToken,
      handleSetSelectionState,
      loadPreviewTable,
      previewDeduplicationTaskStatus,
      previewDedupNumberOfRecords,
      usePrioDeduplication,
    } = this.props;

    /**
     * Get whether or not the status is open
     * @param {*} status - The status to check
     * @returns {boolean} True or false
     */
    const isOpenStatus = (status) => {
      const openStatuses = [
        Constants.STATUS_CREATED,
        Constants.STATUS_QUEUED,
        Constants.STATUS_PROCESSING,
        Constants.STATUS_WAITING,
      ];

      return openStatuses.includes(status);
    };

    if (previewStatus === Constants.STATUS_ERROR ||
      isOpenStatus(previewStatus) ||
      isOpenStatus(previewDeduplicationTaskStatus) ||
      !previewStatus ||
      numberOfResults === null ||
      (usePrioDeduplication && previewDeduplicationTaskStatus === null)
    ) {
      let selection;

      try {
        selection = await SelectionsAPI.getSelection(
          currentSelectionId,
          axiosCancelToken,
        );
      } catch (error) {
        handleSetSelectionState({ previewError: error, previewTaskId: null });
      }

      if (selection) {
        /*
         * I removed previewStatus:selection.previewStatus
         * because of usage of previewStatus in
         * render (the screen with message "Please wait while preview is loading..." will be
         * longer displayed so it looks nicer). Also I removed lastRanDate: selection.previewTaskCompletedDate
         * because previewStatus can become -1 after it is completed
         */
        const error = selection.previewTaskError || selection.previewCountTaskError;

        if (error) {
          handleSetSelectionState({
            previewError: error,
            previewStatus: selection.previewStatus,
            previewTaskId: null,
          });

          return;
        }

        // make sure the progress bar will be true while loading
        if (selection.previewStatus === Constants.STATUS_COMPLETE) {
          handleSetSelectionState({ loadingBarIcon: true, previewTaskId: null });
        }

        /*
         * if selection.previewStatus or selection.previewDeduplicationTaskStatus is completed, load the data
         */
        if (selection.previewStatus === Constants.STATUS_COMPLETE) {
          handleSetSelectionState({
            loadingBarIcon: true,
            previewTaskCompleted: true,
            previewTaskId: null,
            previewDataExtensionObjectID: selection.previewDataExtensionObjectID || null,
            previewDeduplicationDEObjectID: selection.previewDeduplicationDEObjectID || null,
          });

          loadPreviewTable(
            null,
            selection.previewDataExtensionCustomerKey,
            selection.previewStatus,
            selection.previewTaskCompletedDate || selection.runAutomationCompletedDate,
            selection.previewAutomationError,
            selection.previewDeduplicationTaskStatus,
            selection.previewDataExtensionObjectID || null,
            selection.previewDeduplicationDEObjectID || null,
          );

          // status can be undefined in copied selection when you hit run preview button
        } else if (typeof selection.previewStatus !== 'undefined') {
          handleSetSelectionState(
            {
              previewStatus: selection.previewStatus,
              previewDeduplicationTaskStatus: selection.previewDeduplicationTaskStatus,
              previewQueryActivityId: selection.previewQueryActivityId,
              previewTaskQuery: selection.previewTaskQuery,
            },
            () => {
              if (previewStatus === Constants.STATUS_ERROR) {
                handleSetSelectionState({ previewStatus });
              }
            },
          );
        }

        // call this function again in 3 seconds
        if (isOpenStatus(selection.previewStatus)) {
          handleSetSelectionState({ timer: setTimeout(() => this.loadPreviewData(), 3000) });
        }

        // Create run preview log for when task is complete
        if (selection.previewStatus === Constants.STATUS_COMPLETE) {
          const taskRunLog = {
            selectionId: currentSelectionId,
            previewQueryActivityId: selection.previewQueryActivityId,
            previewTaskCompletedDate: selection.previewTaskCompletedDate,
          };
          // Update dedup details if deduplication enabled

          if (selection.previewDeduplicationDEObjectID &&
            selection.previewDeduplicationTaskStatus === Constants.STATUS_COMPLETE) {
            taskRunLog.runDeduplicationQueryActivityId = selection.runDeduplicationQueryActivityId;
            taskRunLog.previewDeduplicationTaskCompletedDate = selection.previewDeduplicationTaskCompletedDate;
            taskRunLog.withDeduplication = true;
            taskRunLog.previewStatus = selection.previewStatus;
            taskRunLog.numberOfPreviewRecords = selection.numberOfPreviewRecords;
          } else {
            taskRunLog.withDeduplication = false;
            taskRunLog.previewStatus = selection.previewStatus;
            taskRunLog.numberOfPreviewRecords = selection.numberOfPreviewRecords;
          }
          await RunPreviewLogsAPI.createRunPreviewLog(taskRunLog);
        }
        // save numberOfPreviewRecords
        if (numberOfResults !== selection.numberOfPreviewRecords) {
          handleSetSelectionState({
            numberOfResults: selection.numberOfPreviewRecords,
          });
        }

        // save numberOfDedupPreviewRecords
        if (previewDedupNumberOfRecords !== selection.previewDedupNumberOfRecords) {
          handleSetSelectionState({
            previewDedupNumberOfRecords: selection.previewDedupNumberOfRecords,
          });
        }
      }
    } else {
      /*
       * wait when api call is finished then set previewTaskCompleted to true
       * previewDataRetrieved set to true if we already hit Run Preview button and
       * we switch between pages
       */
      handleSetSelectionState({ previewDataRetrieved: true, loadingBarIcon: true, previewTaskCompleted: true });
    }
  };

  /**
   * Format the column value to depending on the data type
   * @param {string} val - value
   * @param {string} fieldType - field type
   * @param {number} scale - The desired scale
   * @returns {string|null} the formatted value or null
   */
  formatColumnValue = (val, fieldType, scale) => {
    const { userInfo } = this.props;
    const DATEFORMAT = timeUtil.getUserDateTimeFormat(userInfo);
    const USERLOCALE = timeUtil.getUserLocale(userInfo);
    // check if there are any values

    if (val !== '') {
      if (fieldType === Constants.FILTERLINE__FIELDTYPE__NUMBER ||
        fieldType === Constants.FILTERLINE__FIELDTYPE__DECIMAL) {
        // format numbers: ensure decimal settings are respected
        let formattedValue;

        if (fieldType === Constants.FILTERLINE__FIELDTYPE__DECIMAL && scale) {
          formattedValue = Util.formatNumber(val, scale, USERLOCALE);
        } else {
          formattedValue = val;
        }

        return formattedValue;
      } if (fieldType === Constants.FILTERLINE__FIELDTYPE__DATE) {
        // format dates
        return timeUtil.formatDate(val, DATEFORMAT);
      }

      return val;
    }

    return null;
  };

  /**
   * Get styles of fields
   * @param {string} fieldType - type of chosen fields
   * @returns {void}
   */
  getColumnStyleClass = fieldType => fieldType === Constants.FILTERLINE__FIELDTYPE__NUMBER ||
    fieldType === Constants.FILTERLINE__FIELDTYPE__DECIMAL ?
    'move-number-right' :
    '';

  /**
   * Get styles of fields
   * @returns {string} Text to be shown about impact of chosen data action
   */
  getDataActionWarning = () => {
    const { previewData, dataAction } = this.props;

    let warning = `The record${previewData.length > 1 ? 's' : ''} in this preview` +
      `${previewData.length > 1 ? ' are' : ' is'} the record${previewData.length > 1 ? 's' : ''
      } that will `;

    switch (dataAction) {
      case Constants.DATA_ACTION__APPEND:
        warning += ' be <b>appended</b> to';
        break;

      case Constants.DATA_ACTION__UPDATE:
        warning += ' <b>update</b>';
        break;

      case Constants.DATA_ACTION__OVERWRITE:
      default:
        warning += ' <b>overwrite</b>';
        break;
    }
    warning += ' the records in the Target Data Extension.';

    return warning;
  };

  /**
   * get temporary data extension link that has been created by the run preview
   * @returns {string} - return the temporary data extension link
   * @param {string} type - can either be processing or deduplication
   */
  getTemporaryDataExtensionLink = (type) => {
    const { orgInfo } = this.props;
    const { previewDataExtensionObjectID, previewDeduplicationDEObjectID, usePrioDeduplication } = this.props;

    if (!previewDataExtensionObjectID || !orgInfo?.marketingCloudStackNumber) return null;

    // eslint-disable-next-line spellcheck/spell-checker
    const domain = `https://mc.${orgInfo.marketingCloudStackNumber}.exacttarget.com`;

    let link = '';

    if (type === 'Processing') {
      if (usePrioDeduplication) {
        link = domain + `/contactsmeta/admin.html#admin/data-extension/${previewDeduplicationDEObjectID}`;
      } else {
        link = domain + `/contactsmeta/admin.html#admin/data-extension/${previewDataExtensionObjectID}`;
      }
    } else {
      link = domain + `/contactsmeta/admin.html#admin/data-extension/${previewDataExtensionObjectID}`;
    }

    return link;
  };

  /**
   * Shows the SQL query modal
   * @returns {void}
   */
  showSQLQuery = () => this.setState({ showSQLQuery: true });

  /**
   * hides the SQL query modal
   * @returns {void}
   */
  hideSQLQuery = () => this.setState({ showSQLQuery: false });

  runSelection = async () => {
    const {
      saveSelection,
      axiosCancelToken,
      handleNavigator,
    } = this.props;

    // Save new data
    const saveSelectionResult = await saveSelection(true);

    if (saveSelectionResult.success) {
      const currentSelectionId = saveSelectionResult.selectionId;

      try {
        // Save activities and navigate to overview
        await QueryActivitiesAPI.runQueryActivity(currentSelectionId, axiosCancelToken);
        handleNavigator(Constants.NAVIGATION__OVERVIEW);
      } catch (error) {
        await SwalUtil.fire({
          type: Constants.SWAL__TYPE__ERROR,
          title: 'Error Running Selection',
          message: error,
        });
      }
    }
  };

  /**
   * Check if the selection is running
   * @returns {boolean} - True or false
   */
  isSelectionRunning = () => {
    const { runStatus } = this.props;

    return ![Constants.STATUS_COMPLETE,
      null,
      undefined,
      Constants.STATUS_WAITING,
      Constants.STATUS_ERROR, Constants.STATUS_RETRY].includes(runStatus);
  };

  render() {
    const {
      validatingSelection,
      showSQLQuery,
    } = this.state;
    const {
      previewStatus,
      previewError,
      lastRanDate,
      previewData,
      fieldsMetaData,
      numberOfResults,
      usePrioDeduplication,
      previewDataRetrieved,
      isArchived,
      loadingBarIcon,
      previewTaskCompleted,
      previewDeduplicationTaskStatus,
      previewQueryActivityId,
      previewTaskQuery,
      currentSelectionId,
      previewDedupNumberOfRecords,
      taskId,
      handleSelectionNavigator,
      axiosCancelToken,
      handleSetSelectionState,
      enabledScheduleSelection,
      validateIfQueryCanBeRun,
      checkMissingFieldsInRelations,
      checkValidSchedule,
      checkIncompleteFilter,
      selectedDataExtensions,
      selectionNavigation,
      isTemplate,
      selectionCreator,
      selectionState,
      clickedSave,
      saveSelection,
      userInfo,
      backToWaterFall,
    } = this.props;

    const DATEFORMAT = timeUtil.getUserDateTimeFormat(userInfo);
    const USERLOCALE = timeUtil.getUserLocale(userInfo);

    const { cancelClicked, isRunPreviewClicked } = this.state;

    /*
     * data is prepared if preview data is retrieved and previewStatus !== null and
     * typeof previewStatus !== 'undefined' (means new selection or copied selection doesn't have data
     * so "data" is already prepared)
     */
    const preparingData = !previewDataRetrieved && previewStatus !== null && typeof previewStatus !== 'undefined';

    // if error has occurred
    const error = previewStatus === Constants.STATUS_ERROR || previewError;

    const isPermissionError = String(previewError?.actualError).includes(Constants.ERROR_SFMC_PERMISSION_FAILED);

    const processing = previewStatus === Constants.STATUS_PROCESSING ||
      (previewStatus === Constants.STATUS_WAITING && previewDeduplicationTaskStatus === Constants.STATUS_WAITING);

    let previewDataValues = [];

    if (previewData.length > 0) {
      // get values from 'previewData' without keys (e.g. FirstName: Sveto => 0: Sveto)
      previewDataValues = previewData.map(data => Object.values(data));
    }

    /**
     * Determines the width the preview bar should have
     * @param {number} previewQueryStatus - keeps status about phase in which is query
     * @param {number} previewDedupQueryStatus - keeps status about phase in which is dedup query
     * @returns {string} The % of completion
     */
    const previewBarWidth = (previewQueryStatus, previewDedupQueryStatus) => {
      /**
       * make sure that query is completed then set the progress bar to 100%
       * let the progress bar fill up while the page is loading
       */
      if ((previewQueryStatus === Constants.STATUS_COMPLETE && !preparingData) ||
        (previewStatus === Constants.STATUS_COMPLETE && loadingBarIcon)) {
        return '100%';
      }

      if (Number.isInteger(previewDedupQueryStatus)) {
        switch (true) {
          case previewQueryStatus === Constants.STATUS_CREATED:
            return '25%';
          case processing:
            return '50%';
          case (previewDeduplicationTaskStatus === Constants.STATUS_PROCESSING ||
            previewDeduplicationTaskStatus === Constants.STATUS_CREATED):
            return '75%';
          default:
            return '0%';
        }
      } else {
        switch (previewQueryStatus) {
          case Constants.STATUS_CREATED:
            return '34%';
          case Constants.STATUS_PROCESSING:
            return '67%';
          default:
            return '0%';
        }
      }
    };

    const cancelPreviewButtonClassName = classNames(
      'cancel-preview-button',
      { 'disable-cancel-preview-btn': !preparingData && !error },
    );

    // preview status is completed and the data is no longer prepared
    const previewCompleteWithNoPreparingDataStatus = previewStatus === Constants.STATUS_COMPLETE && !preparingData;

    // preview status is completed and bar icon is loading
    const previewCompleteWithLoadingBarIconStatus = previewStatus === Constants.STATUS_COMPLETE && loadingBarIcon;

    // preview status for deduplication is in progress or preview status is completed without preparing data
    const processingDeduplicationStatus = (previewDeduplicationTaskStatus === Constants.STATUS_PROCESSING ||
      previewDeduplicationTaskStatus === Constants.STATUS_CREATED) ||
      previewCompleteWithNoPreparingDataStatus;

    // preview status for deduplication is processing or preview status is completed and bar icon is loading
    const loadingDeduplicationStatus = processingDeduplicationStatus ||
      previewCompleteWithLoadingBarIconStatus;

    /**
     * Returns class name for tooltip button depends of passed conditions
     * @param {bool} conditionForMarkerIcon - the condition that must be met for the button to have
     * an additional class name
     * @param {bool} buttonRelative - indicates that it has to render button-relative class name
     * @returns {string} class name
     */
    const tooltipButtonClassName = (conditionForMarkerIcon, buttonRelative) => classNames(
      'slds-progress__marker',
      buttonRelative ? 'button-relative' : '',
      {
        'slds-button_icon slds-progress__marker_icon': conditionForMarkerIcon,
      },
    );

    // class name for the first tooltip button
    const buttonTooltipOneClassName = tooltipButtonClassName(validatingSelection ||
      previewStatus === Constants.STATUS_CREATED || processing ||
      processingDeduplicationStatus || preparingData);

    // class name for the second tooltip button
    const buttonTooltipTwoClassName = tooltipButtonClassName(processing || loadingDeduplicationStatus);

    // class name for the third tooltip button
    const buttonTooltipThreeClassName = tooltipButtonClassName(loadingDeduplicationStatus);

    // class name for the fourth tooltip button
    const buttonTooltipFourClassName = tooltipButtonClassName(previewCompleteWithNoPreparingDataStatus ||
      previewCompleteWithLoadingBarIconStatus, true);

    // class name for the fifth tooltip button
    const buttonTooltipFiveClassName = tooltipButtonClassName(previewCompleteWithNoPreparingDataStatus, true);

    // status for not completed preview
    const notCompletedPreviewStatus = (previewStatus === Constants.STATUS_COMPLETE && !preparingData) || preparingData;

    // when starting the preview is active
    const startingPreviewActive = previewStatus === null || previewStatus === undefined || notCompletedPreviewStatus;

    // when starting the preview is completed
    const startingPreviewCompleted = validatingSelection ||
      previewStatus === Constants.STATUS_CREATED || processing ||
      (previewDeduplicationTaskStatus === Constants.STATUS_PROCESSING ||
        previewDeduplicationTaskStatus === Constants.STATUS_CREATED) || notCompletedPreviewStatus;

    /**
     * Returns class name for list item depends of passed conditions
     * @param {bool} activeCondition - indicates that it has to render active class name
     * @param {bool} completedCondition - indicates that it has to render completed class name
     * @returns {string} class name
     */
    const previewClassName = (activeCondition, completedCondition) => classNames(
      activeCondition ? 'slds-is-active' : '',
      {
        'slds-is-completed': completedCondition,
      },
    );

    /**
     * Returns class name for tooltip element depends of passed conditions
     * @param {string} step - indicates in which step the tooltip is render
     * @param {bool} showTooltip - indicates that it has to render show-tooltip class name
     * @param {bool} nubbinPositionChanged - indicates that it has to render nubbin-position-change class name
     * @returns {string} class name
     */
    const tooltipClassName = (step, showTooltip, nubbinPositionChanged) => classNames(
      `tooltip-${step}`,
      showTooltip ? 'show-tooltip' : '',
      nubbinPositionChanged ? 'nubbin-position-change' : '',
    );

    // items for progress bar
    const progressItems = [
      {
        className: previewClassName(startingPreviewActive, startingPreviewCompleted),
        tooltip: startingPreviewActive || startingPreviewCompleted,
        tooltipId: 'btn-tooltip-one',
        tooltipClassName: tooltipClassName('one', null, previewStatus === null ||
          previewStatus === undefined || preparingData),
        tooltipLabel: 'Started',
        buttonClassName: buttonTooltipOneClassName,
        icon: !!(validatingSelection || previewStatus === Constants.STATUS_CREATED || processing ||
          processingDeduplicationStatus || preparingData),
        condition: true,
        name: 'Starting',
      },
      {
        className: previewClassName(
          previewStatus === Constants.STATUS_CREATED,
          processing || loadingDeduplicationStatus,
        ),
        tooltip: true,
        tooltipClassName: tooltipClassName('two', previewStatus === Constants.STATUS_CREATED ||
          processing || loadingDeduplicationStatus, previewStatus === Constants.STATUS_CREATED),
        tooltipId: 'btn-tooltip-two',
        tooltipLabel: previewStatus === Constants.STATUS_CREATED ?
          'Creating...' :
          'Created',
        buttonClassName: buttonTooltipTwoClassName,
        icon: !!(processing || loadingDeduplicationStatus),
        condition: true,
        name: 'Creating',
      },
      {
        className: previewClassName(processing, loadingDeduplicationStatus),
        tooltip: true,
        tooltipClassName: tooltipClassName('three', processing || loadingDeduplicationStatus, processing),
        tooltipId: 'btn-tooltip-three',
        tooltipLabel: processing ?
          'Processing...' :
          'Processed',
        buttonClassName: buttonTooltipThreeClassName,
        icon: !!loadingDeduplicationStatus,
        condition: true,
        name: 'Processing',
      },
      {
        condition: previewDeduplicationTaskStatus !== null && previewDeduplicationTaskStatus !== undefined,
        className: previewClassName(
          previewDeduplicationTaskStatus === Constants.STATUS_PROCESSING ||
          previewDeduplicationTaskStatus === Constants.STATUS_CREATED,
          previewCompleteWithNoPreparingDataStatus || previewCompleteWithLoadingBarIconStatus,
        ),
        tooltip: true,
        tooltipId: 'btn-tooltip-four',
        tooltipClassName: tooltipClassName(
          'four',
          loadingDeduplicationStatus,
          previewDeduplicationTaskStatus === Constants.STATUS_PROCESSING ||
          previewDeduplicationTaskStatus === Constants.STATUS_CREATED,
        ),
        tooltipLabel: previewDeduplicationTaskStatus === Constants.STATUS_PROCESSING ||
          previewDeduplicationTaskStatus === Constants.STATUS_CREATED ?
          'Deduplicating...' :
          'Deduplicated',
        buttonClassName: buttonTooltipFourClassName,
        icon: !!(previewCompleteWithNoPreparingDataStatus || previewCompleteWithLoadingBarIconStatus),
        name: 'Deduplicating',
      },
      {
        className: previewClassName(null, previewStatus === Constants.STATUS_COMPLETE && !preparingData),
        tooltip: true,
        tooltipClassName: tooltipClassName(
          'five',
          previewCompleteWithNoPreparingDataStatus,
          previewCompleteWithNoPreparingDataStatus,
        ),
        tooltipId: 'btn-tooltip-five',
        tooltipLabel: 'Completed',
        buttonClassName: buttonTooltipFiveClassName,
        icon: !!previewCompleteWithNoPreparingDataStatus,
        condition: true,
        name: 'Completed',
      },
    ];

    // values of progress bar
    const progressBarValues = {
      min: '0',
      max: '100',
      now: '0',
    };

    // style for progress bar value
    const progressBarValueStyle = {
      transition: '1s all',
      width: previewBarWidth(previewStatus, previewDeduplicationTaskStatus),
    };

    /**
     * Gets the values for the selection
     * @returns - the value of selections
     */

    const getProcessingNumberOfRecords = () => {
      let res = '';

      let tempPreviewDedupNumberOfRecords = previewDedupNumberOfRecords;

      let tempNumberOfResults = numberOfResults;

      if (tempPreviewDedupNumberOfRecords === null && tempNumberOfResults !== null) {
        tempPreviewDedupNumberOfRecords = tempNumberOfResults;
      }

      if (tempPreviewDedupNumberOfRecords === null && tempNumberOfResults === null) {
        tempPreviewDedupNumberOfRecords = 0;
        tempNumberOfResults = 0;
      }

      if (usePrioDeduplication) {
        if ((tempPreviewDedupNumberOfRecords !== null ||
          tempPreviewDedupNumberOfRecords !== undefined) && previewTaskCompleted) {
          res = Util.formatNumber(tempPreviewDedupNumberOfRecords, 0, USERLOCALE);
        } else {
          res = (
            <span>
              (
              <i className="fas fa-spinner fa-spin" />
              &nbsp;Calculating)
            </span>);
        }
      } else {
        if (tempNumberOfResults !== null &&
          (tempPreviewDedupNumberOfRecords === null ||
            tempPreviewDedupNumberOfRecords === undefined) && previewTaskCompleted) {
          res = Util.formatNumber(tempNumberOfResults, 0, USERLOCALE);
        } else {
          res = Util.formatNumber(tempPreviewDedupNumberOfRecords, 0, USERLOCALE);
        }
      }

      if (res === 'NaN') {
        res = 'unknown';
      }

      return res;
    };

    const getHTMLForGeneratedRecords = (showPrioDedup) => {
      const processingNumberOfRecords = getProcessingNumberOfRecords();

      return (
        <div className={classNames(
          'records-container',
          { 'priodedup-records-container': usePrioDeduplication },
        )}>
          <div className="no-records-generated-container">
            <div className="processed">
              <span>Processed</span>
            </div>
            <div className="no-records-text">
              {previewData.length ?
                (
                  <>
                    <span>
                      <strong>
                        {Util.formatNumber(processingNumberOfRecords, 0, this.USERLOCALE)}
                        {' '}
                        record
                        {parseInt(processingNumberOfRecords) === 0 ||
                          parseInt(processingNumberOfRecords) > 1 ?
                          's' :
                          ''}
                      </strong>
                      &nbsp;
                      {parseInt(processingNumberOfRecords) === 0 ||
                        parseInt(processingNumberOfRecords) > 1 ?
                        'have' :
                        'has'}
                      {' '}
                      been generated
                    </span>
                    <div className="see-all-link">
                      <span className="see-all-label">See all</span>
                      <a
                        className="slds-link-size"
                        href={this.getTemporaryDataExtensionLink('Processing')}
                        target="__blank">
                        <svg className="slds-icon-new_window link">
                          <use xlinkHref="/assets/icons/utility-sprite/svg/symbols.svg#new_window" />
                        </svg>
                      </a>
                    </div>
                  </>
                ) :
                (
                  <>
                    <span>
                      <strong>No records</strong>
                      &nbsp;have been generated
                    </span>
                    <div className="see-all-link">
                      <span className="see-all-label">See all</span>
                      <a
                        className="slds-link-size"
                        href={this.getTemporaryDataExtensionLink('Processing')}
                        target="__blank">
                        <svg className="slds-icon-new_window link">
                          <use xlinkHref="/assets/icons/utility-sprite/svg/symbols.svg#new_window" />
                        </svg>
                      </a>
                    </div>
                  </>
                )}
            </div>
          </div>

          {
            usePrioDeduplication && showPrioDedup && previewDeduplicationTaskStatus === Constants.STATUS_COMPLETE ?
              (
                <div className="no-records-generated-container priodedup">
                  <div className="processed">
                    <span>Deduplicated</span>
                  </div>
                  <div className="no-records-text">
                    {numberOfResults > 0 ?
                      (
                        <>
                          <span>
                            <strong>
                              {Util.formatNumber(numberOfResults, 0, this.USERLOCALE)}
                              {' '}
                              record
                              {parseInt(numberOfResults) === 0 ||
                                parseInt(numberOfResults) > 1 ?
                                's' :
                                ''}
                            </strong>
                            &nbsp;
                            {parseInt(numberOfResults) === 0 ||
                              parseInt(numberOfResults) > 1 ?
                              'have' :
                              'has'}
                            {' '}
                            been generated
                          </span>
                          <div className="see-all-link">
                            <span className="see-all-label">See all</span>
                            <a
                              className="slds-link-size"
                              href={this.getTemporaryDataExtensionLink()}
                              target="__blank">
                              <svg className="slds-icon-new_window link">
                                <use xlinkHref="/assets/icons/utility-sprite/svg/symbols.svg#new_window" />
                              </svg>
                            </a>
                          </div>
                        </>
                      ) :
                      (
                        <>
                          <span>
                            <strong>No records</strong>
                            &nbsp;have been generated
                          </span>
                          <div className="see-all-link">
                            <span className="see-all-label">See all</span>
                            <a
                              className="slds-link-size"
                              href={this.getTemporaryDataExtensionLink()}
                              target="__blank">
                              <svg className="slds-icon-new_window link">
                                <use xlinkHref="/assets/icons/utility-sprite/svg/symbols.svg#new_window" />
                              </svg>
                            </a>
                          </div>
                        </>
                      )}
                  </div>
                </div>
              ) :
              null
          }
        </div>
      );
    };

    return (
      <div
        id="preview-section-v2"
        className="preview-v2"
        ref={this.reviewRef}
      >
        {isArchived && <WarningAlert text={Constants.WARNING_TEXT__ARCHIVED_SELECTION_READ_ONLY} />}

        <div className="preview_wrapper-v2">
          <SelectionNavigator
            handleSetSelectionState={handleSetSelectionState}
            enabledScheduleSelection={enabledScheduleSelection}
            validateIfQueryCanBeRun={validateIfQueryCanBeRun}
            checkMissingFieldsInRelations={checkMissingFieldsInRelations}
            checkValidSchedule={checkValidSchedule}
            checkIncompleteFilter={checkIncompleteFilter}
            buttonName={this.isSelectionRunning() ? 'Running...' : 'Run'}
            isSelectionRunning={this.isSelectionRunning()}
            runSelection={this.runSelection}
            clickedSave={clickedSave}
            saveSelection={saveSelection}
            selectionNavigator_={Constants.NAVIGATION__PREVIEW}
            previewStatus={previewStatus}
            numberOfResults={numberOfResults}
            previewQueryActivityId={previewQueryActivityId}
            selectedDataExtensions_={selectedDataExtensions}
            selectionNavigation={selectionNavigation}
            isTemplate={isTemplate}
            selectionCreator={selectionCreator}
            selectionState={selectionState}
            backToWaterFall={backToWaterFall}
          />
          <div className="align-items">
            {!isRunPreviewClicked && (previewStatus === null || previewStatus === undefined) ?
              (
                <Button
                  onClick={() => handleSelectionNavigator(Constants.NAVIGATION__SELECTION_CRITERIA)}
                  id="back-to-selection-button"
                  buttonLook={Constants.BUTTON__TYPE__NEUTRAL}
                >
                  <svg className="slds-button__icon back-icon" aria-hidden="true">
                    <use xlinkHref="/assets/icons/utility-sprite/svg/symbols.svg#back" />
                  </svg>
                  <span className="back-to-selection-button-text">
                    Back to Selection
                  </span>
                </Button>
              ) :
              <div className="placeholder" />}
            <GuidanceTip tipId="run-preview-tip" />
          </div>
          {showSQLQuery && (
            <SQLQueryModal
              closeModal={this.hideSQLQuery}
              taskQuery={sqlFormatter.format(previewTaskQuery)}
            />
          )}
        </div>

        {!isRunPreviewClicked && (previewStatus === null || previewStatus === undefined) ?
          (
            <div className="preview-no-running">
              <div className="preview-is-safe-place">
                <div className="text-1">
                  Preview is a safe place
                </div>
                <div className="text-2">
                  It will show you the results of your Selection and will
                  calculate the number of records in your results. You can
                  always go back and edit your Selection and run a new preview.
                </div>
              </div>
              <div className="good-to-know">
                <svg className="slds-button__icon light-bulb" aria-hidden="true">
                  <use xlinkHref="/assets/icons/utility-sprite/svg/symbols.svg#light_bulb" />
                </svg>
                <div className="text">
                  <span>
                    Good to know:
                  </span>
                  &nbsp;
                  <span className="second-subtext">
                    A preview will not write your Target Data Extension yet
                  </span>
                </div>
              </div>
              <div className="buttons-container">
                <div>
                  <Button
                    onClick={() => {
                      this.setState({ isRunPreviewClicked: true }, () => this.handleRunPreview());
                    }}
                    id="preview-button"
                    buttonLook={Constants.BUTTON__TYPE__BRAND}
                    className="buttons"
                  >
                    <span className="preview-results-text">
                      Preview results
                    </span>
                    <svg className="slds-button__icon eye-icon" aria-hidden="true">
                      <use xlinkHref="/assets/icons/utility-sprite/svg/symbols.svg#preview" />
                    </svg>
                  </Button>
                </div>
              </div>
            </div>
          ) :
          null}
        <div className="preview-running">
          {/* Progress bar */}
          {((validatingSelection || previewStatus === Constants.STATUS_CREATED || processing ||
            (previewDeduplicationTaskStatus === Constants.STATUS_PROCESSING ||
              previewDeduplicationTaskStatus === Constants.STATUS_CREATED) ||
            (previewStatus === Constants.STATUS_COMPLETE && !preparingData) || preparingData) && !error ?
            (
              <ProgressBar
                progressItems={progressItems}
                values={progressBarValues}
                progressBarValueStyle={progressBarValueStyle}
                containerId="progress-indicator-v2"
              />
            ) :
            null)}

          {validatingSelection ||
            (previewStatus !== undefined && previewStatus !== null &&
              ((previewStatus === Constants.STATUS_CREATED || processing ||
                (previewDeduplicationTaskStatus === Constants.STATUS_PROCESSING ||
                  previewDeduplicationTaskStatus === Constants.STATUS_CREATED) ||
                previewStatus === Constants.STATUS_ERROR) ||
                !(!preparingData && previewStatus === Constants.STATUS_COMPLETE))) ?
            (
              <div className="happy-penguin">
                {(validatingSelection ||
                  previewStatus === Constants.STATUS_CREATED || processing ||
                  (previewDeduplicationTaskStatus === Constants.STATUS_PROCESSING ||
                    previewDeduplicationTaskStatus === Constants.STATUS_CREATED) ||
                  !(!preparingData && previewStatus === Constants.STATUS_COMPLETE)) && previewStatus !== undefined &&
                  previewStatus !== null && !error ?
                  (
                    <img
                      alt="Penguin"
                      src={Constants.PREVIEW_DEEDEE_URL}
                    />
                  ) :
                  null}
                {previewStatus === Constants.STATUS_ERROR ?
                  (
                    <div className="sad-penguin-container">
                      <img
                        alt="Sad Penguin"
                        src="/img/sad-penguin.png"
                        className="sad-penguin"
                      />
                      <Alert
                        type={Constants.ALERT__TYPE__WARNING}
                        assistiveText={Constants.ALERT__TYPE__WARNING}
                        dangerouslySetInnerHTML={previewError ?
                          DOMPurify.sanitize(isPermissionError ?
                            Constants.ERROR_INSUFFICIENT_PERMISSIONS_DETAILS :
                            previewError).replace('<a', '<a target="_blank"') :
                          null}
                        className="sad-penguin-alert"
                      />
                    </div>
                  ) :
                  null}
              </div>
            ) :
            null}

          {(validatingSelection ||
            previewStatus === Constants.STATUS_CREATED || processing ||
            (previewDeduplicationTaskStatus === Constants.STATUS_PROCESSING ||
              previewDeduplicationTaskStatus === Constants.STATUS_CREATED) ||
            !(!preparingData && previewStatus === Constants.STATUS_COMPLETE)) && previewStatus !== undefined &&
            previewStatus !== null && !error ?
            (
              <PreviewTips axiosCancelToken={axiosCancelToken} />
            ) :
            null}

          <div className="preview-running-buttons">
            {(
              (processing ||
                (previewDeduplicationTaskStatus === Constants.STATUS_PROCESSING ||
                  previewDeduplicationTaskStatus === Constants.STATUS_CREATED))
            ) ?
              <div
                onClick={() => this.handleCancelPreview(currentSelectionId, taskId)}
                id="cancel-button"
                className={cancelPreviewButtonClassName}
                disabled={cancelClicked || (previewStatus !== Constants.STATUS_QUEUED &&
                  previewStatus !== Constants.STATUS_PROCESSING && previewStatus !== Constants.STATUS_WAITING)}
              >
                Cancel the preview
              </div> :
              null}

            {previewQueryActivityId && previewStatus !== Constants.STATUS_COMPLETE && (processing ||
                (previewDeduplicationTaskStatus === Constants.STATUS_PROCESSING ||
                  previewDeduplicationTaskStatus === Constants.STATUS_CREATED)) && (
              <Button
                className="show-sql-btn"
                onClick={this.showSQLQuery}
                buttonLook={Constants.BUTTON__TYPE__NEUTRAL}
              >
                Show the SQL query
              </Button>
            )}
          </div>
        </div>

        {
          !preparingData && previewStatus === Constants.STATUS_COMPLETE ?
            (getHTMLForGeneratedRecords(true)) :
            null
        }
        {
          previewStatus !== undefined && previewStatus !== null && previewStatus === Constants.STATUS_COMPLETE ?
            (
              <>
              <Button
                onClick={() => {
                  this.setState({ isRunPreviewClicked: true }, () => this.handleRunPreview());
                }}
                id="preview-button"
                buttonLook={Constants.BUTTON__TYPE__BRAND}
                className={classNames({
                  'run-preview-button-after-changes': numberOfResults,
                  'run-preview-button-without-records': !numberOfResults,
                })}
              >
                <span className="preview-results-text">
                  Run Preview
                </span>
              </Button>
                {previewQueryActivityId && !numberOfResults && (
                  <Button
                    className="show-sql-run-btn-without-records"
                    onClick={this.showSQLQuery}
                    buttonLook={Constants.BUTTON__TYPE__NEUTRAL}
                  >
                    Show the SQL query
                  </Button>
                )}
              </>
            ) :
            null
        }

        {
          previewData.length > 0 &&
            (!preparingData && previewStatus === Constants.STATUS_COMPLETE) ?
            (
              <div className="preview-v2_result_title">
                <div className="number-of-records-and-last-run">
                  <p
                    className="number-of-records"
                    id="preview-title"
                  >
                    <svg className="slds-button__icon info-icon" aria-hidden="true">
                      <use xlinkHref="/assets/icons/utility-sprite/svg/symbols.svg#info_alt" />
                    </svg>
                    Showing first
                    {' '}
                    <strong id="preview-number-of-records">
                      {previewData.length}
                    </strong>
                    {' '}
                    of
                    {' '}
                    {usePrioDeduplication ?
                      (
                        <>
                          <strong id="preview-total-number-of-records">
                            {numberOfResults !== null && previewTaskCompleted ?
                              Util.formatNumber(numberOfResults, 0, USERLOCALE) :
                              (
                                <span>
                                  (
                                  <i className="fas fa-spinner fa-spin" />
                                  &nbsp;Calculating)
                                </span>
                              )}
                          </strong>
                          {' '}
                          deduplicated records
                          {' '}
                          {
                            previewDedupNumberOfRecords !== undefined && (
                              <>
                                (
                                <strong id="preview-total-number-of-records">
                                  {previewDedupNumberOfRecords !== null && previewTaskCompleted ?
                                    Util.formatNumber(previewDedupNumberOfRecords, 0, USERLOCALE) :
                                    Util.formatNumber(numberOfResults, 0, USERLOCALE)}
                                </strong>
                                {' '}
                                before Deduplication
                                )
                              </>
                            )
                          }
                        </>
                      ) :
                      (
                        <>
                          <strong id="preview-total-number-of-records">
                            {/* eslint-disable-next-line no-nested-ternary */}
                            {numberOfResults !== null &&
                              (previewDedupNumberOfRecords === null || previewDedupNumberOfRecords === undefined) &&
                              previewTaskCompleted ?
                              Number.isNaN(numberOfResults) ?
                                'Unknown' :
                                Util.formatNumber(numberOfResults, 0, USERLOCALE) :
                              Number.isNaN(previewDedupNumberOfRecords) ?
                                'Unknown' :
                                Util.formatNumber(previewDedupNumberOfRecords, 0, USERLOCALE)}
                          </strong>
                          {' '}
                          records in total
                        </>
                      )}
                  </p>
                  {lastRanDate ?
                    (
                      <>
                        <svg className="slds-button__icon circle-icon" aria-hidden="true">
                          <use xlinkHref="/assets/icons/utility-sprite/svg/symbols.svg#record" />
                        </svg>
                        <p
                          id="preview-status"
                          className="last-run"
                        >
                          <strong>Last Run</strong>
                          :&nbsp;
                          {timeUtil.showCSTDateTimeInLocale(
                            lastRanDate,
                            DATEFORMAT,
                          )}
                        </p>
                      </>
                    ) :
                    null}
                </div>
                <div>
                  {previewQueryActivityId && (
                    <Button
                      className="view-sql-query-button"
                      onClick={this.showSQLQuery}
                    >
                      Show the SQL query
                    </Button>
                  )}
                </div>
              </div>
            ) :
            null
        }

        {/* same as comment on line 281 except lastRanDate
      don't show info about data when new selection still need to be created (previewStatus === null)
      or selection is copied (previewStatus === 'undefined') */}
        {
          (validatingSelection || (!preparingData && previewStatus === Constants.STATUS_COMPLETE)) &&
            previewData.length ?
            (
              <div className="preview-v2_result">
                <div id="preview-table-div" className="preview-v2_result_table">
                  <table
                    className="slds-table slds-table_cell-buffer"
                    id="preview-table"
                  >
                    <thead>
                      <tr
                        id="preview-list-header"
                        className="slds-line-height_reset"
                      >
                        {fieldsMetaData.map((fieldData, i) => {
                          const thKey = new Date().getTime() + '' + i;
                          /*
                           * set field to fieldsMetaData if fieldsMetaData exists, in other case set field to
                           * emtpy object to prevent undefined error (field.FieldType)
                           */
                          const field = fieldsMetaData[i] || {};
                          const fieldType = field && field.FieldType ? field.FieldType : '';

                          return (
                            <th key={thKey} scope="col">
                              {' '}
                              <div
                                className={`slds-truncate ${this.getColumnStyleClass(fieldType)}`}
                                title={fieldData.Name.toString()}
                              >
                                {fieldData.Name.toString()}
                              </div>
                            </th>
                          );
                        })}
                      </tr>
                    </thead>
                    <tbody id="preview-list-body">
                      {previewDataValues.map((data, rowCounter) => {
                        const trKey = new Date().getTime() + '' + rowCounter;

                        return (
                          <tr key={trKey} className="slds-hint-parent">
                            {data.map((columnValue, columnCounter) => {
                              const tdKey = new Date().getTime() + '' + columnCounter;
                              /*
                               * set field to fieldsMetaData if fieldsMetaData exists, in other case set field to
                               * emtpy object to prevent undefined error (field.FieldType and field.Scale)
                               */
                              const field = fieldsMetaData[columnCounter] || {};
                              const fieldType = field && field.FieldType ? field.FieldType : '';

                              return (
                                <td
                                  className={this.getColumnStyleClass(fieldType)}
                                  key={tdKey}
                                >
                                  <div className="slds-truncate">
                                    {this.formatColumnValue(
                                      columnValue,
                                      fieldType,
                                      field.Scale,
                                    )}
                                  </div>
                                </td>
                              );
                            })}
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
            ) :
            null
        }
        {
          !preparingData && previewStatus === Constants.STATUS_COMPLETE ?
            (
              <div className="after-records-div preview-no-running">
                <div className="preview-is-safe-place no-height">
                  <span className="text-1">
                    Do these results meet your expectations?
                  </span>
                  <p className="text-2">
                    If not, you can go back and edit your Selection and run a new preview.
                  </p>
                  <div className="good-to-know good-to-know_border">
                    <svg className="slds-button__icon light-bulb" aria-hidden="true">
                      <use xlinkHref="/assets/icons/utility-sprite/svg/symbols.svg#light_bulb" />
                    </svg>
                    <div className="text">
                      <span>
                        Good to know:
                      </span>
                      &nbsp;
                      <span
                        className="second-subtext"
                        dangerouslySetInnerHTML={({ __html: this.getDataActionWarning() })}
                      />
                    </div>
                  </div>
                </div>
                <div className="buttons-container">
                  <Button
                    onClick={() => handleSelectionNavigator(Constants.NAVIGATION__SELECTION_CRITERIA)}
                    id="back-to-selection-button"
                    buttonLook={Constants.BUTTON__TYPE__NEUTRAL}
                    className="buttons"
                  >
                    <svg className="slds-button__icon back-icon" aria-hidden="true">
                      <use xlinkHref="/assets/icons/utility-sprite/svg/symbols.svg#back" />
                    </svg>
                    <span className="bring-me-back-button-text">
                      No, bring me back
                    </span>
                  </Button>
                </div>
              </div>
            ) :
            null
        }

        {/* close preview-panel */}
      </div>
    );
  }
}

Preview.propTypes = {
  /**
   * It keeps the fields of Target Data Extension
   */
  fieldsMetaData: PropTypes.instanceOf(Array),
  /**
   * It keeps data we got after query was run
   */
  previewData: PropTypes.instanceOf(Array),
  /**
   * It keeps total number of data we got after query was run
   */
  numberOfResults: PropTypes.number,
  /**
   * It keeps total number of dedup data we got after query was run
   */
  previewDedupNumberOfRecords: PropTypes.number,
  /**
   * It keeps status about phase in which is query (Created, Queued, Processing, Complete)
   */
  previewStatus: PropTypes.number,
  /**
   * It keeps error info if something is wrong with when query is run
   */
  previewError: PropTypes.string,
  /**
   * It keeps data when query was run last time
   */
  lastRanDate: PropTypes.string,
  /**
   * It helps to set the Selection`s state
   * It will be passed from Selection.js
   */
  handleSetSelectionState: PropTypes.func.isRequired,
  /**
   * It helps to set the save Selection in DB
   * It will be passed from Selection.js
   */
  saveSelection: PropTypes.func.isRequired,
  /**
   * It helps to validate if everything is alright before query can be run
   * It will be passed from Selection.js
   */
  validateIfQueryCanBeRun: PropTypes.func.isRequired,
  /**
   * DataAction applied to the QueryActivity
   */
  dataAction: PropTypes.string,
  /**
   * It keeps Selection's Id
   */
  currentSelectionId: PropTypes.string,
  /**
   * It helps to cancel a subscription of an API call to backend
   * It will be passed from Selection.js
   */
  axiosCancelToken: PropTypes.instanceOf(Object),
  /**
   * It keeps if the prio deduplication settings will be applied
   */
  usePrioDeduplication: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  /**
   * It keeps if the preview has started
   */
  runPreviewQueryActivityStarted: PropTypes.bool.isRequired,
  /**
   * It helps to load preview data
   */
  loadPreviewTable: PropTypes.func.isRequired,
  /**
   * It keeps if the preview data have been returned
   */
  previewDataRetrieved: PropTypes.bool.isRequired,
  /**
   * It keeps if the loading bar icon should be displayed
   */
  loadingBarIcon: PropTypes.bool.isRequired,
  /**
   * It keeps if the entire preview is completed
   */
  previewTaskCompleted: PropTypes.bool.isRequired,
  /**
   * It keeps if a user switched to a new tab
   */
  switchedToOtherTab: PropTypes.bool.isRequired,
  /**
   * It keeps status about phase in which is priodedup query (Created, Queued, Processing, Complete)
   */
  previewDeduplicationTaskStatus: PropTypes.number,
  /**
   * Id of the preview queryActivity
   */
  previewQueryActivityId: PropTypes.string,
  /**
   * ObjectID of the preview Data Extension
   */
  previewDataExtensionObjectID: PropTypes.string,
  /**
   * ObjectID of the preview Data Extension
   */
  previewDeduplicationDEObjectID: PropTypes.string,
  /**
   * Preview's SQL query
   */
  previewTaskQuery: PropTypes.string,
  /*
   * Tells whether the selection is archived or not
   */
  isArchived: PropTypes.bool.isRequired,
  /*
   * Tells whether anything changed in the selection
   */
  nothingHasChanged: PropTypes.bool.isRequired,
  /*
   * Id of the timer generated when preview has started.
   */
  timer: PropTypes.number,
  /*
   * Id of preview task currently running.
   */
  taskId: PropTypes.string,
  /*
   * This function helps to navigate between pages in a Selection
   * This prop will be passed from Selection.js
   */
  handleSelectionNavigator: PropTypes.func.isRequired,
  /**
   * User info from cookie
   */
  userInfo: PropTypes.object,
  /**
   * Org info from cookie
   */
  orgInfo: PropTypes.object,
  /**
   * Handle the global navigator
   */
  handleNavigator: PropTypes.func.isRequired,
  /**
   * Run status
   */
  runStatus: PropTypes.number,
};

export default connect(mapStateToProps(['userInfo', 'orgInfo']), null, null, { pure: false })(Preview);
