/* eslint-disable import/order */
/* eslint-disable max-len */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import './styles.scss';
import moment from 'moment';

import SelectionRunLogsAPI from '../../api/selection-run-logs';
import SelectionRunLogDetails from '../../components/Overview/SelectionList/SelectionRunLogsModal/SelectionRunLogDetails/SelectionRunLogDetails';

import { Modal, Button } from '@salesforce/design-system-react';
import timeUtil from '../../utils/time/timeUtil';
import SwalUtil from '../../utils/swal/swalUtil';
import Constants from '../../constants/constants';
import Util from '../../util';
import Spinner from '../shared/Spinner/Spinner';
import SelectionRunLogsModalLine from './SelectionRunLogsModalLine';
import Pagination from './Pagination';
import mapStateToProps from '../../mapStateToProps';
import { connect } from 'react-redux';

const customButtonStyles = {
  width: '9.375rem',
};

class SelectionRunLogsModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loadingData: false,
      selectionRunLogs: [],
      selectedRunLog: null,
      isRetryTabOpen: true,
      currentPage: 1,
      pageSize: 4,
    };
  }

  /**
   * Fetch data while loading modal
   * @returns {void}
   */
  async componentDidMount() {
    const { selectionId, isWaterfall } = this.props;

    // create 'CancelToken'
    this.axiosCancelToken = axios.CancelToken.source();

    // start loading data - show the loading spinner while fetching data
    this.setState({ loadingData: true });

    let selectionRunLogs;

    try {
      if (isWaterfall) {
        // call function to fetch run logs for waterfall selection
        selectionRunLogs = await SelectionRunLogsAPI.getWaterfallSelectionRunLogs(
          selectionId,
          this.axiosCancelToken.token,
        );
      } else {
        // call function to fetch run logs for selection
        selectionRunLogs = await SelectionRunLogsAPI.getSelectionRunLogs(
          selectionId,
          this.axiosCancelToken.token,
        );
      }

      this.setState({ selectionRunLogs });
    } catch (error) {
      if (!axios.isCancel(error)) {
        // in case of error, display the message
        SwalUtil.fire({
          type: Constants.SWAL__TYPE__ERROR,
          title: 'Error',
          message: error,
          options: {
            customClass: {
              popup: 'popup-targetDE',
            },
          },
        });
      }
    }

    // show the content after retrieving the data
    this.setState({ loadingData: false });
  }

  /**
   * Helps to disable run details button when selection is running
   * @param {object} runLog - object with run log data
   * @returns {boolean} true if details button in run logs should be disabled, false otherwise
   */
  disableRunDetailsForRunningSelection = (runLog) => {
    const { selection, userInfo } = this.props;
    const { selectionRunLogs } = this.state;

    // get user's time format
    const userDateTimeFormat = timeUtil.getUserDateTimeFormat(userInfo);

    // define run start date for fetched selection, depending on whether it is a selection or a waterfall selection
    const selectionStartDate = selection.taskStartDate || selection.waterfallSelectionTaskStartDate;

    // format selectionStartDate
    const selectionTaskStartDate = timeUtil.formatDate(selectionStartDate, userDateTimeFormat);

    // define run start date for run log
    const runLogStartDate = timeUtil.formatDate(
      runLog?.previousAttempt?.startDateTime || runLog.startDateTime,
      userDateTimeFormat,
    );

    // get current selection run status
    const selectionRunStatus = selection.runStatus || selection.waterfallSelectionStatus;

    // get the latest date
    const moments = selectionRunLogs.map(log => moment(log.startDateTime));
    const maxDate = moment.max(moments).format(userDateTimeFormat);

    // determine whether a selection is running
    const selectionIsRunning = selectionRunStatus && selectionRunStatus !== Constants.STATUS_COMPLETE &&
    selectionRunStatus !== Constants.STATUS_ERROR && selectionRunStatus !== Constants.STATUS_QUEUED_FOR_WATERFALL &&
    selectionRunStatus !== Constants.STATUS_RETRY;

    // define whether a query is creating
    const queryIsCreating = selectionRunStatus === Constants.STATUS_CREATING;

    /*
     * if selection is in progress and the start date for both selection and run log is equal
     * or if query is creating and run log start date is the most up-to-date
     */
    if((selectionTaskStartDate === runLogStartDate && selectionIsRunning && !queryIsCreating) ||
      (queryIsCreating && maxDate === runLogStartDate)
    ) {
      // disable details button
      return true;
    }

    // otherwise return false
    return false;
  };

  /**
   * Function to handle the selection of the run log
   * @param {object} runLog - selected run log
   * @returns {void}
   */
  handleSelectRunLog = (runLog) => {
    if(!runLog || (runLog && !this.disableRunDetailsForRunningSelection(runLog))) {
      this.setState({ selectedRunLog: runLog });
    }
  };

  /**
   * Returns trigger mode label for run log
   * @param {object} selectionRunLog - selected run log
   * @returns {string} label with trigger mode
   */
  showTriggerMode = (selectionRunLog) => {
    let triggerMode;

    switch (selectionRunLog.trigger) {
      // Backend in the past used to send it as auto, now sends it as scheduled
      case Constants.TRIGGER_SELECTION_MODE__VALUE__SCHEDULED:
      case Constants.TRIGGER_SELECTION_MODE__AUTO:
        triggerMode = Constants.TRIGGER_SELECTION_MODE__LABEL__SCHEDULED;

        break;

      default:
        triggerMode = Constants.TRIGGER_SELECTION_MODE__LABEL__MANUAL;
    }

    return triggerMode;
  };

  /**
   * Returns source label for run log
   * @param {object} selectionRunLog - selected run log
   * @returns {string} label with source
   */
  showSourceLabel = (selectionRunLog) => {
    const { isWaterfall } = this.props;

    // when the source is waterfall or we are in waterfall run log
    if (selectionRunLog?.source === Constants.SOURCE_SELECTION__VALUE__WATERFALL_SELECTION ||
      isWaterfall) {
      return Constants.SOURCE_SELECTION__LABEL__WATERFALL_SELECTION;
    }

    return Constants.SOURCE_SELECTION__LABEL__SELECTION;
  };

  /**
   * Returns retry label for run log
   * @param {object} selectionRunLog - selected run log
   * @returns {string} label with source
   */
  showRetryLabel = selectionRunLog => selectionRunLog?.previousAttempt?.startDateTime ?
    'Yes' :
    'No';

  /**
   * Shows modal content depending on the selected run log
   * @returns {void}
   */
  showModalContent = () => {
    const { selectedRunLog, selectionRunLogs, isRetryTabOpen } = this.state;
    const { selection, userInfo } = this.props;

    // get user's time format
    const userDateTimeFormat = timeUtil.getUserDateTimeFormat(userInfo);

    // if run log is selected, show content with run log details
    if (selectedRunLog) {
      return selectedRunLog?.previousAttempt?.startDateTime ?
        (
          <div>
            <ul id="selection-run-logs-tabs">
              <li
                className={`tab${isRetryTabOpen ? ' active' : ''}`}
                onClick={() => this.setState({ isRetryTabOpen: true })}
              >
                Retried Run
              </li>
              <li
                className={`tab${isRetryTabOpen ? '' : ' active'}`}
                onClick={() => this.setState({ isRetryTabOpen: false })}
              >
                First Run
              </li>
            </ul>
            <SelectionRunLogDetails
              selectedRunLog={isRetryTabOpen ? selectedRunLog : selectedRunLog.previousAttempt}
              userDateTimeFormat={userDateTimeFormat}
              showTriggerMode={this.showTriggerMode}
              showSourceLabel={this.showSourceLabel}
              showRetryLabel={this.showRetryLabel}
            />
          </div>
        ) :
        (
          <SelectionRunLogDetails
            selectedRunLog={selectedRunLog}
            userDateTimeFormat={userDateTimeFormat}
            showTriggerMode={this.showTriggerMode}
            showSourceLabel={this.showSourceLabel}
            showRetryLabel={this.showRetryLabel}
          />
        );
    }

    // otherwise show the list with run logs
    if (selectionRunLogs.length) {
      const { currentPage, pageSize } = this.state;

      const startIndex = (currentPage - 1) * pageSize;
      const endIndex = startIndex + pageSize;
      const currentLogs = selectionRunLogs.slice(startIndex, endIndex);

      return (
        currentLogs.map(selectionRunLog => (
          <SelectionRunLogsModalLine
            key={selectionRunLog._id}
            selectionRunLog={selectionRunLog}
            handleSelectRunLog={this.handleSelectRunLog}
            userDateTimeFormat={userDateTimeFormat}
            showTriggerMode={this.showTriggerMode}
            showSourceLabel={this.showSourceLabel}
            disableRunDetailsForRunningSelection={this.disableRunDetailsForRunningSelection}
            selection={selection}
          />
        )));
    }

    // or show a message that there are no run logs
    if (!selectionRunLogs.length) {
      return (<div className="no-run-logs-found">No logs found for this selection.</div>);
    }

    return false;
  };

  /**
   * Returns title for modal header
   * @param {object} selectedRunLog - selected run log
   * @param {object} selection - selection data
   * @returns {object} HTML for the header title
   */
  showHeaderTitle = (selectedRunLog, selection) => (
    selectedRunLog ?
      (
        <div className="header-for-selected-run-log">
          <svg
            className="slds-button__icon back-icon"
            aria-hidden="true"
            onClick={() => this.handleSelectRunLog(null)}
          >
            <use xlinkHref="/assets/icons/utility-sprite/svg/symbols.svg#back" />
          </svg>
          <span className="logs-detail-style">Run Logs Details</span>
        </div>
      ) :
      (
        <span title={`Run Logs for ${selection.name}`}>
          Run Logs for
          {' '}
          <span className="run-logs-selection-name-in-header">{Util.abbreviate(selection.name, 35)}</span>
        </span>
      )
  );

  handlePageChange = (pageNumber) => {
    this.setState({ currentPage: pageNumber });
  };

  render() {
    const {
      loadingData,
      selectedRunLog,
      currentPage,
      pageSize,
      selectionRunLogs,
    } = this.state;

    const {
      handleCloseSelectionRunLogsModal,
      selection,
    } = this.props;

    const totalItems = selectionRunLogs.length;
    const totalPages = Math.ceil(totalItems / pageSize);

    return (

        <Modal
        disableClose
        size="small"
        isOpen
        >
        <div className="slds-m-around_medium">
          {loadingData ?
            (
              <div className="demo-only demo--inverse" style={{ height: '6rem' }}>
                <Spinner size={Constants.SPINNER__SIZE__MEDIUM} />
              </div>
            ) :
            (
            <div>
                <div className="des-lib-datatable-delete-title">
                    {this.showHeaderTitle(selectedRunLog, selection)}
                </div>
              <div>
              <table className="des-lib-datatable-last-run-table">
                <thead className={selectedRunLog ? 'show-none' : ''}>
                    <tr>
                    <th>Date</th>
                    <th>Trigger</th>
                    <th>Source</th>
                    <th>Status</th>
                    </tr>
                </thead>
                <tbody>
                {this.showModalContent()}
                </tbody>
              </table>
              </div>
            </div>
            )}
            <div className={`${selectedRunLog ? 'show-none' : ''}`}>
            <Pagination
                currentPage={currentPage}
                totalPages={totalPages}
                onPageChange={this.handlePageChange}
                />
            </div>
            <div className={`des-lib-datatable-run-logs-action ${selectedRunLog ? 'show-none' : ''}`}>
                <Button
                    iconCategory="utility"
                    label="Cancel"
                    size="large"
                    style={customButtonStyles}
                    onClick={() => handleCloseSelectionRunLogsModal()}
                />
            </div>
        </div>
        </Modal>
    );
  }
}

SelectionRunLogsModal.propTypes = {
  /**
   * Id of selection for which to load the SelectionRunLogs
   */
  selectionId: PropTypes.string.isRequired,
  /**
   * It closes this Modal
   */
  handleCloseSelectionRunLogsModal: PropTypes.func.isRequired,
  /**
   * Selection for which the modal was opened
   */
  selection: PropTypes.instanceOf(Object).isRequired,
  /**
   * Defines whether the Waterfall Selections section is selected in the Overview
   */
  isWaterfall: PropTypes.bool,
  /**
   * User info from cookie
   */
  userInfo: PropTypes.object,
};

export default connect(mapStateToProps(['userInfo']), null, null, { pure: false })(SelectionRunLogsModal);
