/* eslint-disable multiline-ternary */
import React, { useState, useRef, useEffect } from 'react';

import './styles.scss';
import DatabaseIcon from '../../../icons_v2/database.svg';
import AddIcon from '../../../icons_v2/add.svg';
import arrowDown from '../../../icons_v2/arrow-up.svg';
import arrowUp from '../../../icons_v2/arrow-up-up.svg';
import Input from '../../shared/Input/Input';

const CustomSelect = ({
  placeholder,
  optionsData,
  value,
  onChange,
  handleShowNewTargetDEModal,
}) => {
  const [selectedOption, setSelectedOption] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [isOpen, setIsOpen] = useState(false);
  const selectRef = useRef(null);
  const [filteredOptions, setFilteredOptions] = useState(optionsData);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (selectRef.current && !selectRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };
    const filteredOptions = optionsData?.filter(option => option.value === value);

    setSelectedOption(filteredOptions[0]);
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectRef]);

  useEffect(() => {
    // Filter options based on search term
    const filtered = optionsData.filter(option => option.title.toLowerCase().includes(searchTerm.toLowerCase()));

    setFilteredOptions(filtered);
  }, [optionsData, searchTerm]);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleOptionClick = (option) => {
    if (option.type !== 'button') {
      setSelectedOption(option);
      onChange(option);
    }
    setIsOpen(false);
    if (option.onClick) {
      option.onClick();
    }
  };

  return (
    <div ref={selectRef} className="des-lib-custom-select">
      <div className="selected-option" onClick={toggleDropdown}>
        {selectedOption ? (
          <div className="option">
            <span>{selectedOption.title}</span>
          </div>
        ) : (
          <div className="placeholder">{placeholder}</div>
        )}
        <img src={isOpen ? arrowUp : arrowDown} alt="" />
      </div>
      {isOpen && (
        <div className="options">
          <Input
            placeholder="Search..."
            value={searchTerm}
            onChange={e => setSearchTerm(e.target.value)}
          />
          <div
            className="option button-option"
            onClick={() => handleShowNewTargetDEModal()}
          >
            <span className="option__content">
              <img src={AddIcon} alt="" />
              <span className="button-options">Create Data Extension</span>
            </span>
          </div>
          {filteredOptions.map((option, index) => (
            <div
              key={index}
              className="option"
              onClick={() => handleOptionClick(option)}
            >
              <span className="option__content">
                <img src={DatabaseIcon} alt="" />
                <span className="other-options">{option.title}</span>
              </span>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default CustomSelect;
