import React from 'react';
import PropTypes from 'prop-types';
import {
  Button,
} from '@salesforce/design-system-react';

import './styles.scss';

const AdminHeader = ({
  title, buttonId, onClick, buttonLabel, headerRightContent, disabled, iconTitle, iconLink,
  secondButtonId, secondButtonLabel, secondButtonOnClick,
}) => {
  return (
    <div className="slds-page-header slds-m-bottom_medium" style={{ backgroundColor: '#E8EEF6' }}>
    <div className="slds-page-header__row">
      <div className="slds-page-header__col-title">
        <div className="slds-media header-alignment">
          <div className="slds-media__figure">
            <span className="slds-icon_container slds-icon-standard-account" title={iconTitle || title}>
              <svg className="slds-icon slds-page-header__icon" aria-hidden="true">
                <use xlinkHref={iconLink} />
              </svg>
            </span>
          </div>
          <div className="slds-media__body">
            <div className="slds-page-header__name">
              <div className="slds-page-header__name-title">
                <h1>
                  <span className="slds-page-header__title slds-truncate" title={title}>{title}</span>
                </h1>
              </div>
            </div>
          </div>
        </div>
      </div>
{secondButtonId &&
(<div className="slds-page-header__control">
            <Button
              className="header-second-button"
              label={secondButtonLabel}
              type="button"
              onClick={() => secondButtonOnClick()}
            />
 </div>)}
      <div className="slds-page-header__control">
        {buttonId &&
          <Button
            className="header-main-button"
            label={buttonLabel}
            title="Add New"
            variant="brand"
            iconName="add"
            iconPosition="left"
            id={buttonId}
            onClick={onClick}
            disabled={disabled}
          />}
        {headerRightContent}
      </div>
    </div>
    </div>
  );
};

AdminHeader.propTypes = {
  /**
   * title for the Header
   */
  title: PropTypes.string.isRequired,
  /**
   * title for the icon
   */
  iconTitle: PropTypes.string,
  /**
   * xlinkHref for the icon
   */
  iconLink: PropTypes.string.isRequired,
  /**
   * Id of the button used in Header
   */
  buttonId: PropTypes.string,
  /**
   * onClick function for the button
   */
  onClick: PropTypes.func,
  /**
   * label for the button
   */
  buttonLabel: PropTypes.string,
  /*
   * everything that is used to display in the header except button
   */
  headerRightContent: PropTypes.oneOfType(
    [PropTypes.number, PropTypes.string, PropTypes.object, PropTypes.array],
  ),
  /**
   * defines whether the button is disabled
   */
  disabled: PropTypes.bool,
};

export default AdminHeader;
