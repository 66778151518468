import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Constants from '../../../../constants/constants';
import Input from '../../../../components/shared/Input/Input';

class PicklistNameInput extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: props.picklistName,
    };
  }

  render() {
    const { name } = this.state;
    const {
      picklist,
      picklistId,
      activePanel,
      handleChangePicklistName,
    } = this.props;

    return (
      <Input
        id="picklist-name"
        required
        maxLength="250"
        onChange={e => this.setState({ name: e.target.value })}
        onBlur={e => handleChangePicklistName(e)}
        value={name || ''}
        disabled={picklist === null && picklistId &&
        activePanel === Constants.ADMIN_PANEL__MENU__EDITED_PICKLIST}
      />
    );
  }
}

PicklistNameInput.propTypes = {
  /**
   * It keeps the id value of a picklist to be able it
   */
  picklistId: PropTypes.string,
  /**
   * It keeps location where we are entering in
   */
  activePanel: PropTypes.string.isRequired,
  /**
   * Picklist object
   */
  picklist: PropTypes.instanceOf(Object),
  /**
   * Change the picklist state on PicklistsAE component
   */
  handleChangePicklistName: PropTypes.func.isRequired,
  /**
   * Picklist name coming from PicklistsAE component
   */
  picklistName: PropTypes.string,
};
export default PicklistNameInput;
