import React from 'react';
import './styles.scss';

const DataSourceTabs = () => {
  return (
    <div>
      <div className="des-source-container">
        <span className="des-source-text">Source 1</span>
      </div>

      <div className="add-new-source">
        <span>+ Add new source</span>
      </div>
    </div>
  );
};

export default DataSourceTabs;
