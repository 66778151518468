import React from 'react';
import PropTypes from 'prop-types';

import Constants from '../../../constants/constants';
import Spinner from '../Spinner/Spinner';
import SortIcon from '../SortIcon/SortIcon';

import './styles.scss';

const Table = ({
  tableHeaders,
  bodyContent,
  isLoading = false,
  id,
  className,
  handleSort,
  sortDirection,
  sortedProperty,
  children,
  tableClassName,
  rowClassName,
}) => (
  <div
    className={`slds-table--header-fixed_container table-common-look ${className || ''} table-common-look `}
    id={id || ''}>
    <div className="slds-scrollable">
      <table className={`slds-table slds-table_bordered slds-table--header-fixed ${tableClassName || ''}`}>
        <thead>
          <tr className={`slds-line-height_reset ${rowClassName || ''}`}>
            {tableHeaders.map(header => (
              <th scope="col" key={header.title}>
                <div
                  className={`slds-truncate slds-cell-fixed ${header?.leftSize || ''} cell-common-look`}
                  title={header.title}>
                  {header.title}
                  {header?.sortIconId && (
                    <span
                      id={header.sortIconId}
                      className="sorting slds-icon_container slds-icon-utility-announcement"
                      onClick={() => handleSort(
                        header.propertyName,
                        sortDirection === Constants.SORT_DIRECTION__ASCENDING ?
                          Constants.SORT_DIRECTION__DESCENDING :
                          Constants.SORT_DIRECTION__ASCENDING,
                      )}
                    >
                      <SortIcon sortDirection={sortedProperty === header.propertyName ? sortDirection : ''} />
                    </span>
                  )}
                </div>
              </th>))}
          </tr>
        </thead>
          <tbody>
            {!isLoading && bodyContent}
          </tbody>
      </table>
      {isLoading ?
        (
          <Spinner size={Constants.SPINNER__SIZE__MEDIUM} assistiveText="Loading" />
        ) :
        null}
      {children}
    </div>
  </div>
);

Table.propTypes = {
  /**
   * array with objects that appear in the header
   */
  tableHeaders: PropTypes.instanceOf(Array).isRequired,
  /**
   * the object with the content that displays in the table
   */
  bodyContent: PropTypes.instanceOf(Object).isRequired,
  /**
   * indicates that the data in the table are loading
   */
  isLoading: PropTypes.bool,
  /**
   * id of the table component
   */
  id: PropTypes.string,
  /**
   * className of the table component
   */
  className: PropTypes.string,
  /**
   * function responsible for sorting data
   */
  handleSort: PropTypes.func,
  /**
   * the sorting direction
   */
  sortDirection: PropTypes.string,
  /**
   * the property by which the array is sorted
   */
  sortedProperty: PropTypes.string,
  /*
   * everything that is used to display outside the table
   * it depends of what is include between the opening and closing tags when invoking a component
   */
  children: PropTypes.oneOfType([PropTypes.number, PropTypes.string, PropTypes.object, PropTypes.array]),
  /**
   * class name for the table element
   */
  tableClassName: PropTypes.string,
  /**
   * class name for the tr element
   */
  rowClassName: PropTypes.string,
};

export default Table;
