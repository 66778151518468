import React from 'react';
import PropTypes from 'prop-types';
import { Button, BuilderHeaderToolbar } from '@salesforce/design-system-react';
import { toast } from 'react-toastify';
import './styles.scss';
import { connect } from 'react-redux';
import classNames from 'classnames';

import mapStateToProps from '../../../mapStateToProps';
import CriteriaNavigation from './CriteriaNavigation';
import Constants from '../../../constants/constants';
import Toast from '../../shared_v2/Toast/Toast';
import SwalUtil from '../../../utils/swal/swalUtil';

const customButtonStyles = {
  width: '6rem',
};

const MyActions = ({
  selectionNavigator,
  selectionNavigation,
  selectedDataExtensions_,
  previewQueryActivityId,
  numberOfResults,
  previewStatus,
  editTargetDataExtension,
  handleSetSelectionState,
  editNewAutoTargetDE,
  matchedFields,
  buttonName,
  saveSelection,
  enabledScheduleSelection,
  validateIfQueryCanBeRun,
  checkMissingFieldsInRelations,
  checkIncompleteFilter,
  checkValidSchedule,
  isTemplate,
  selectionCreator,
  selectionState,
  clickedSave,
  clickedRun,
  showSaveToast,
  isSelectionRunning,
  hasReadOnlyAccess,
  disableRunDetails,
  waterfallSelection,
  runSelection = () => {},
  userInfo,
  backToWaterFall,
}) => {
  /**
   * Handles click on the third arrow
   * @returns {void}
   */
  const handleThirdArrowClick = () => {
    if (editNewAutoTargetDE || editTargetDataExtension || disableRunDetails) {
      return null;
    }

    if (waterfallSelection) {
      // for waterfall selection go to Run Details view
      selectionNavigation(Constants.NAVIGATION__RUN_DETAILS);
    } else if (selectionNavigator === Constants.NAVIGATION__PREVIEW) {
      runSelection();
    } else {
      if (buttonName) {
        // for selection go to Preview
        selectionNavigation(Constants.NAVIGATION__PREVIEW);
      } else {
        // for selection go to Target Definition
        selectionNavigation(Constants.NAVIGATION__TARGET_DEFINITION);
      }
    }

    return null;
  };
  /**
   * Handles the disabling of template editing if the user is not an admin or
   * the template selection is not created by the active user
   * @returns {boolean} - Determines whether saving should be disabled
   */
  const shouldSavingBeDisabled = () => {
    return !selectionState && isTemplate &&
      !userInfo.isAdmin && userInfo.id !== selectionCreator;
  };
  /**
   * Save button handler. Saves new selection.
   * Function cannot be moved to Navbar as it uses saveSelection on Selection
   * @returns {void}
   */
  // eslint-disable-next-line consistent-return
  const handleSave = async () => {
    // Throw a swal if selection save is disabled
    if (shouldSavingBeDisabled()) {
      return SwalUtil.fire({
        title: 'Selection cannot be saved',
        // eslint-disable-next-line max-len
        message: 'This selection is marked as a template. Only admins or the person who created this selection can modify it.',
        options: {
          confirmButtonText: 'OK',
          allowOutsideClick: false,
        },
      });
    }

    handleSetSelectionState({ clickedSave: true });
    try {
      /**
       * Save selection is only possible in the following scenarios:
       * When schedule is not enabled and no incomplete filter
       * When schedule is enabled and valid, and selection can be run and no incomplete filter
       */
      if (!enabledScheduleSelection || (enabledScheduleSelection &&
        await validateIfQueryCanBeRun(true) &&
        await checkMissingFieldsInRelations(true) &&
        await checkValidSchedule()) &&
        await checkIncompleteFilter()
      ) {
        const result = await saveSelection();

        handleSetSelectionState({
          captureSelectionChange: false,
          clickedSave: false,
        });

        if (result.success) {
          handleSetSelectionState({ showSaveToast: result.success, captureSelectionChange: false });

          // if the toast about auto-fix selection exists, close it
          if (toast.isActive(Constants.NOTIFICATION__TOAST_ID__AUTOFIX)) {
            toast.dismiss(Constants.NOTIFICATION__TOAST_ID__AUTOFIX);
            handleSetSelectionState({ showAutofixToast: false });
          }

          // show toast with message information
          toast.success(
            <Toast
              notificationText={Constants.NOTIFICATION__TEXT__SAVED}
            />,
            {
              position: toast.POSITION.TOP_RIGHT,
              className: 'toast-saved',
              toastId: Constants.NOTIFICATION__TOAST_ID__SAVE,
              hideProgressBar: true,
              autoClose: 2000,
              onClose: () => handleSetSelectionState({ showSaveToast: false }),
              containerId: Constants.NOTIFICATION__CONTAINER_ID__SELECTION,
              draggable: false,
              pauseOnHover: false,
              pauseOnFocusLoss: false,
            },
          );
        }
      } else {
        handleSetSelectionState({ clickedSave: false });
      }
    } catch (e) {
      handleSetSelectionState({ clickedSave: false });
    }
  };

  return (
    <div className="navigation-bar">
      <CriteriaNavigation
        selectionNavigator={selectionNavigator}
        selectionNavigation={selectionNavigation}
        selectedDataExtensions_={selectedDataExtensions_}
        previewQueryActivityId={previewQueryActivityId}
        numberOfResults={numberOfResults}
        previewStatus={previewStatus}
        editTargetDataExtension={editTargetDataExtension}
        handleSetSelectionState={handleSetSelectionState}
        editNewAutoTargetDE={editNewAutoTargetDE}
        matchedFields={matchedFields}
      />
      <div className="buttons_container">
        <Button
          disabled={!!(clickedSave || editNewAutoTargetDE || editTargetDataExtension) || clickedRun ||
            showSaveToast || isSelectionRunning || selectionState?.isArchived || hasReadOnlyAccess}
          style={customButtonStyles}
          onClick={() => editNewAutoTargetDE || editTargetDataExtension ? null : handleSave()}
          label="Save"
        />
        <Button
          style={customButtonStyles}
          onClick={() => handleThirdArrowClick()}
          disabled={!!(editNewAutoTargetDE || editTargetDataExtension ||
             disableRunDetails) || isSelectionRunning === true || backToWaterFall?._id}
          label={buttonName || 'Next'}
          variant="brand" />
      </div>
    </div>
  );
};

MyActions.propTypes = {
  /**
   * User info from cookie
   */
  userInfo: PropTypes.object,
};

const SelectionNavigator = ({
  selectionNavigator_,
  selectionNavigation,
  selectedDataExtensions_,
  previewQueryActivityId,
  numberOfResults,
  previewStatus,
  editTargetDataExtension,
  handleSetSelectionState,
  editNewAutoTargetDE,
  matchedFields,
  buttonName,
  saveSelection,
  enabledScheduleSelection,
  validateIfQueryCanBeRun,
  checkMissingFieldsInRelations,
  checkIncompleteFilter,
  checkValidSchedule,
  isTemplate,
  selectionCreator,
  selectionState,
  clickedSave,
  clickedRun,
  runSelection,
  showSaveToast,
  isSelectionRunning,
  hasReadOnlyAccess,
  disableRunDetails,
  waterfallSelection,
  showDataMultipleTabs = true,
  userInfo,
  backToWaterFall,
}) => {
  return (
    <div className={classNames({ 'selection-navbar': !showDataMultipleTabs })}>
      <BuilderHeaderToolbar
        assistiveText={{
          actions: 'Document Actions',
        }}
        // eslint-disable-next-line react/no-unstable-nested-components
        onRenderActions={() => <MyActions
          selectionNavigator={selectionNavigator_}
          selectionNavigation={selectionNavigation}
          selectedDataExtensions_={selectedDataExtensions_}
          previewQueryActivityId={previewQueryActivityId}
          numberOfResults={numberOfResults}
          previewStatus={previewStatus}
          runSelection={runSelection}
          editTargetDataExtension={editTargetDataExtension}
          handleSetSelectionState={handleSetSelectionState}
          editNewAutoTargetDE={editNewAutoTargetDE}
          matchedFields={matchedFields}
          buttonName={buttonName}
          saveSelection={saveSelection}
          enabledScheduleSelection={enabledScheduleSelection}
          validateIfQueryCanBeRun={validateIfQueryCanBeRun}
          checkMissingFieldsInRelations={checkMissingFieldsInRelations}
          checkIncompleteFilter={checkIncompleteFilter}
          checkValidSchedule={checkValidSchedule}
          isTemplate={isTemplate}
          selectionCreator={selectionCreator}
          selectionState={selectionState}
          clickedSave={clickedSave}
          clickedRun={clickedRun}
          showSaveToast={showSaveToast}
          isSelectionRunning={isSelectionRunning}
          hasReadOnlyAccess={hasReadOnlyAccess}
          disableRunDetails={disableRunDetails}
          waterfallSelection={waterfallSelection}
          userInfo={userInfo}
          backToWaterFall={backToWaterFall}
        />}
      />
    </div>
  );
};

SelectionNavigator.propTypes = {
  /**
   * User info from cookie
   */
  userInfo: PropTypes.object,
};

export default connect(mapStateToProps(['userInfo']), null, null, { pure: false })(SelectionNavigator);
