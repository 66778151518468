import React from 'react';
import './styles.scss';
import PropTypes from 'prop-types';
import axios from 'axios';
import { connect } from 'react-redux';

import Constants from '../../../constants/constants';
import PicklistsAPI from '../../../api/picklists';
import AdminHeader from '../../shared/AdminHeader/AdminHeader';
import Table from '../../shared/Table/Table';
import timeUtil from '../../../utils/time/timeUtil';
import SwalUtil from '../../../utils/swal/swalUtil';
import ActionButton from '../../shared_v2/ActionButton/ActionButton';
import mapStateToProps from '../../../mapStateToProps';

const Picklists = ({
  picklists = [],
  handleSetAdminPanelState,
  handleEditPicklist,
  handleOpenPanel,
  handleSortPicklists,
  sortDirection,
  sortedProperty,
  isLoading,
  userInfo,
}) => {
  const DATEFORMAT = timeUtil.getUserDateTimeFormat(userInfo);

  /**
   * Deletes the picklist selected in the
   * overview with the given id
   * @param {String} picklistId - id of the picklists to delete
   * @returns {void}
   */
  const handleRemovePicklist = async (picklistId) => {
    const result = await SwalUtil.fire({
      title: 'Remove Picklist',
      message: 'Are you sure you want to remove this picklist?',
      options: {
        showCancelButton: true,
        confirmButtonText: 'Remove',
      },
    });

    if (result.value) {
      // Delete picklist
      await PicklistsAPI.deletePicklist(picklistId, axios.CancelToken.source().token);
      // Reload data
      handleOpenPanel(Constants.ADMIN_PANEL__MENU__PICKLISTS);
    }
  };

  const tableData = picklists && picklists.length > 0 ?
    (picklists.map(picklist => (
      <tr key={picklist._id} className="slds-hint-parent row-data">
        <td data-label="Name">
          <div className="slds-truncate">
            <a
              href="#!"
              title={picklist.name}
              onClick={(e) => {
                handleEditPicklist(e, picklist._id, picklist.dataExtensionCustomerKey);
              }}
            >
              {picklist.name}
            </a>
          </div>
        </td>
        <td data-label="Field">
          <div className="slds-truncate slds-m-left_x-small">{picklist.fieldName}</div>
        </td>
        <td data-label="Data Extension">
          <div
            className="slds-truncate slds-m-left_x-small"
            title={picklist.dataExtensionName}
          >
            {picklist.dataExtensionName}
          </div>
        </td>
        <td data-label="Last Modified Date">
          <div className="slds-truncate slds-m-left_x-small">{timeUtil.formatDate(picklist.updatedAt, DATEFORMAT)}</div>
        </td>
        <td data-label="Status">
          <div className="slds-truncate slds-m-left_x-small">
          {picklist.isActive ?
              <div className="picklist-active-status-sticker">Active</div> :
              <div className="picklist-inactive-status-sticker">Inactive</div>}
          </div>
        </td>
        <td data-label="Auto Refresh">
          <div className="picklist-status-container">
            {picklist.autoRefresh ?
              <div className="picklist-active-status-sticker">Active</div> :
              <div className="picklist-inactive-status-sticker">Inactive</div>}
          </div>
        </td>
        <td data-label="Action">
          <ActionButton
            rowIndex={picklist._id}
            actions={[{
              title: 'Delete',
              iconCategory: 'utility',
              iconName: 'delete',
              actionHandler: e => handleRemovePicklist(e, picklist._id),
            }]}
          />
        </td>
      </tr>
    ))) :
    (
      <tr className="row-data">
        <td
          colSpan="5"
          className="slds-text-align_center"
        >
          <div id="no-picklists-defined">
            No picklists have been defined yet. Hit the &apos;New Picklist&apos; button to get started.
          </div>
        </td>
      </tr>
    );

  // data for table header
  const tableHeadersData = [
    {
      title: 'Name',
      propertyName: 'name',
      sortIconId: 'nameSort',
      leftSize: Constants.TABLE__CELL_LEFT_SIZE__SMALL,
    },
    {
      title: 'Field',
      propertyName: 'fieldName',
      sortIconId: 'fieldNameSort',
      leftSize: Constants.TABLE__CELL_LEFT_SIZE__X_SMALL,
    },
    {
      title: 'Data Extension',
      propertyName: 'dataExtensionName',
      sortIconId: 'dataExtensionNameSort',
      leftSize: Constants.TABLE__CELL_LEFT_SIZE__X_SMALL,
    },
    {
      title: 'Last Modified Date',
      propertyName: 'updatedAt',
      sortIconId: 'updatedAtSort',
      leftSize: Constants.TABLE__CELL_LEFT_SIZE__X_SMALL,
    },
    {
      title: 'Status',
      propertyName: 'isActive',
      sortIconId: 'activeSort',
      leftSize: Constants.TABLE__CELL_LEFT_SIZE__X_SMALL,
    },
    {
      title: 'Auto Refresh',
      propertyName: 'autoRefresh',
      sortIconId: 'autoRefreshSort',
      leftSize: Constants.TABLE__CELL_LEFT_SIZE__X_SMALL,
    },
    {
      title: 'Action',
    },
  ];

  return (
    <>
      {/* Picklists header */}
      <AdminHeader
        title="Picklists"
        buttonId="new-picklist"
        onClick={() => handleSetAdminPanelState({ activePanel: Constants.ADMIN_PANEL__MENU__NEW_PICKLIST })}
        buttonLabel="New Picklist"
        iconLink="/assets/icons/standard-sprite/svg/symbols.svg#picklist_type"
      />
      <Table
        tableHeaders={tableHeadersData}
        bodyContent={tableData}
        id="new-picklists-panel"
        className="picklists-table"
        handleSort={handleSortPicklists}
        sortedProperty={sortedProperty}
        sortDirection={sortDirection}
        isLoading={isLoading}
      />
    </>
  );
};

Picklists.propTypes = {
  /**
   * array containing the picklists retrieved, this prop comes
   * from the admin panel component
   */
  picklists: PropTypes.instanceOf(Array).isRequired,
  // handles the state of the admin panel
  handleSetAdminPanelState: PropTypes.func.isRequired,
  // function to open another panel / reload data
  handleOpenPanel: PropTypes.func.isRequired,
  // function to open the edit screen for a picklist
  handleEditPicklist: PropTypes.func.isRequired,
  /**
   * It sorts picklists
   */
  handleSortPicklists: PropTypes.func.isRequired,
  /**
   * It indicates the direction of sort
   */
  sortDirection: PropTypes.string.isRequired,
  /**
   * It gives by which value sort will take place
   */
  sortedProperty: PropTypes.string,
  /**
   * Responsible for showing/hiding loader
   */
  isLoading: PropTypes.bool,
  /**
   * User info from cookie
   */
  userInfo: PropTypes.object,
};

export default connect(mapStateToProps(['userInfo']), null, null, { pure: false })(Picklists);
