import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { connect } from 'react-redux';

import mapStateToProps from '../../../mapStateToProps';
import Constants from '../../../constants/constants';
import RadioButton from '../../../components/shared/RadioButton/RadioButton';
import Folders from '../../Selection/DataExtensions/Folders';
import Tooltip from '../../../components/shared/Tooltip/Tooltip';
import FoldersAPI from '../../../api/folders';
import './styles.scss';
import Toast from '../../../components/shared/Toast/Toast';
import Button from '../../../components/shared/Button/Button';
import Spinner from '../../../components/shared/Spinner/Spinner';
import AdminHeader from '../../shared/AdminHeader/AdminHeader';
import Expand from '../../../icons/jump_to_top.svg';
import Collapse from '../../../icons/jump_to_bottom.svg';

class DataExtensionFolders extends Component {
  /**
   * Sort alphabetically an array of Folders, should be passed as parameter to the 'sort' function
   * @param {object} folder1 - is a folder with at least the 'Name' property
   * @param {object} folder2 - is also folder with at least the 'Name' property
   * @see https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Array/sort
   * @returns {Integer} - it returns -1, 0 or 1
   */
  static alphabeticalFolderSort(folder1, folder2) {
    if (folder1?.Name !== undefined && folder2?.Name !== undefined) {
      return folder1.Name.toString().toLowerCase().localeCompare(folder2.Name.toString().toLowerCase());
    }

    return 0; // Don't sort this element
  }

  constructor(props) {
    super(props);
    this.state = {
      filterMode: Constants.DO_NOT_FILTER_FOLDERS,
      folders: [],
      folderId: '',
      loading: false,
      error: null,
      folderSelectionMap: {},
      foldersChildren: {},
      foldersMap: {},
      greyedOutFolders: {},
      showToast: false,
      expandAll: false,
    };

    // Set state based on props
    if (props.settings && Object.keys(props.settings).length) {
      const {
        availableDEFoldersToFilter,
        includeOrExcludeAvailableDEFolders,
        targetDEFoldersToFilter,
        includeOrExcludeTargetDEFolders,
      } = props.settings;

      // If folders to filter have been retrieved, set them to the state
      if (availableDEFoldersToFilter && targetDEFoldersToFilter) {
        // Set folderSelectionArray
        const folderSelectionArray = props.dataExtensionType === Constants.ADMIN_PANEL__SUBMENU__AVAILABLE_DES ?
          availableDEFoldersToFilter :
          targetDEFoldersToFilter;

        // Set folderSelectionMap
        this.state.folderSelectionMap = folderSelectionArray.reduce((object, item) => {
          // eslint-disable-next-line no-param-reassign
          object[item] = true;

          return object;
        }, {});
      }

      // Set filterMode
      this.state.filterMode = props.dataExtensionType === Constants.ADMIN_PANEL__SUBMENU__AVAILABLE_DES ?
        includeOrExcludeAvailableDEFolders :
        includeOrExcludeTargetDEFolders;
    }
  }

  async componentDidMount() {
    await this.fetchFolders();
  }

  /**
   * Gets a folder's path
   * @param {number} folderId - Id of the folder
   * @param {object} foldersMap - An object with folderId as keys and folder object as values
   * @param {object} pathsMap - An object containing already found paths
   * @returns {array} - An array containing folder's path starting from the root folder
   */
  getFoldersPath = (folderId, foldersMap, pathsMap) => {
    let parentId = '';

    // Get folder
    let currentFolder = foldersMap[folderId];

    let path = [];

    while (currentFolder && parentId !== 0) {
      // If path of current folder has already been found, concatenate to current path
      if (pathsMap[currentFolder.ID]) {
        path = pathsMap[currentFolder.ID].concat(path);

        return path;
      }

      // eslint-disable-next-line no-loop-func
      const parentFolder = foldersMap[currentFolder?.ParentFolder?.ID];

      // Push the id of parent folder if it exists
      if (parentFolder) {
        path.push(parentFolder.ID);
      }

      // Set parent folder as current folder
      currentFolder = parentFolder;

      // Set parent id
      parentId = currentFolder?.ParentFolder?.ID;
    }

    // Return reversed paths array
    return path.reverse();
  };

  /**
   * Get folders to display the folder structure in the component
   * @returns {void}
   */
  fetchFolders = async () => {
    // Set initial state
    this.setState({ loading: true, error: null, foldersChildren: {} });

    const { userInfo } = this.props;

    // Get loggedIn BU
    const { loggedInBusinessUnitId: businessUnitId } = userInfo || {};

    const { dataExtensionType, settings } = this.props;

    const { availableDEFoldersToFilter = [], targetDEFoldersToFilter = [] } = settings;

    const foldersToFilter = dataExtensionType === Constants.ADMIN_PANEL__SUBMENU__AVAILABLE_DES ?
      availableDEFoldersToFilter :
      targetDEFoldersToFilter;

    try {
      const params = {
        ...businessUnitId && { businessUnitId },
        dataExtensionType,
      };

      // Get DE folders
      const folders = await FoldersAPI.getAllFolders(params);

      // Sort the folders to display them alphabetically
      folders.sort(DataExtensionFolders.alphabeticalFolderSort);

      const pathsMap = {};

      const foldersMap = folders.reduce((obj, folder) => {
        // eslint-disable-next-line no-param-reassign
        obj[folder.ID] = folder;

        return obj;
      }, {});

      // Get paths of all selected folders
      const paths = foldersToFilter.reduce((pathsArray, folderToFilter) => {
        // Get a folder's path
        const path = this.getFoldersPath(folderToFilter, foldersMap, pathsMap);

        // Add path to pathsMap
        pathsMap[folderToFilter] = path;

        // Push path in pathsArray
        pathsArray.push(path);

        return pathsArray;
      }, []);

      const foldersChildren = {};

      // Build folders children object
      folders.forEach((folder) => {
        // Push folder in its parent's folders array if parent's key exists in the object
        if (foldersChildren[folder.ParentFolder.ID]) {
          foldersChildren[folder.ParentFolder.ID].folders.push(folder);
        } else {
          // Create the parent's entry in the object
          foldersChildren[folder.ParentFolder.ID] = {
            folders: [],
            showChildren: false,
          };

          // Push folder in its parent's folders array
          foldersChildren[folder.ParentFolder.ID].folders.push(folder);
        }
      });

      /**
       * Get all parent folders.
       * Each default De folder has its own Customer Key, except from the Synchronized DE folder.
       * For the Synchronized Data Extensions folder we need to check Parent Folder ID to be equal to 0.
       */
      const rootFolders = folders.filter(
        f => f.ParentFolder.ID === 0 ||
        f.CustomerKey === Constants.FOLDER__CUSTOMER_KEY__DATA_EXTENSION ||
        f.CustomerKey === Constants.FOLDER__CUSTOMER_KEY__SALESFORCE_DATA_EXTENSION ||
        f.CustomerKey === Constants.FOLDER__CUSTOMER_KEY__SHARED_SALESFORCE_DATA_EXTENSION ||
        f.CustomerKey === Constants.FOLDER__CUSTOMER_KEY__SHARED_DATA_EXTENSION,
      );

      // Set state
      this.setState({
        folders: rootFolders, foldersChildren, foldersMap, loading: false,
      }, async () => {
        const hasBeenOpenedBefore = {};

        // Open all the folders in each selected folder's path
        // eslint-disable-next-line array-callback-return
        paths.map((path) => {
          // eslint-disable-next-line array-callback-return
          path.map((folderId) => {
            // If folder has not been opened before, open it
            if (!hasBeenOpenedBefore[folderId]) {
              // Update the beenOpenedBefore object
              hasBeenOpenedBefore[folderId] = true;
            }
          });
        });
      });

      // Grey-out parent folders whose children are not checked
      this.setGreyOutStateOfFolderCheckboxes(pathsMap);
    } catch (e) {
      let error = '';
      /*
       * e.response is a complex object so it was causing an error: Objects are not valid react elements
       * because in Folders component we are trying to render it
       */

      if (e.response?.data?.error) {
        error = e.response.data.error;
      } else if (typeof error === 'string') {
        error = e;
      } else {
        error = e.message;
      }
      this.setState({ error, loading: false });
    }
  };

  /**
   * Handles the greying-out of folder checkboxes onLoad
   * @param {object} pathsMap - An object with folderId as keys and folder paths as values
   * @return {void}
   */
  setGreyOutStateOfFolderCheckboxes = (pathsMap) => {
    const { dataExtensionType, settings } = this.props;

    const { availableDEFoldersToFilter = [], targetDEFoldersToFilter = [] } = settings;

    const foldersToFilter = dataExtensionType === Constants.ADMIN_PANEL__SUBMENU__AVAILABLE_DES ?
      availableDEFoldersToFilter :
      targetDEFoldersToFilter;

    const greyedOutFolders = {};

    const { foldersChildren, folderSelectionMap } = this.state;

    // Set greyedOut state of checked folders
    foldersToFilter.forEach((folder) => {
      // Determine whether to grey-out checkbox or not.
      greyedOutFolders[folder] = foldersChildren[folder]?.showChildren &&
        foldersChildren[folder]?.folders?.some(
          child => !folderSelectionMap[child.ID],
        );

      // Grey-out parent folders
      if (greyedOutFolders[folder]) {
        // Get folder's path
        const currentPath = pathsMap[folder].reverse();

        // eslint-disable-next-line no-restricted-syntax
        for (const currentFolder of currentPath) {
          // If parent folder is not checked, break out of the loop
          if (!folderSelectionMap[currentFolder]) break;

          // Grey-out folder checkbox
          greyedOutFolders[currentFolder] = folderSelectionMap[currentFolder];
        }
      }
    });

    this.setState({ greyedOutFolders });
  };

  /**
   * OnClick event handler for when a folder is clicked
   * @param {string} id - Id of the clicked folder
   * @param {bool} openedAutomatically - Is the click triggered automatically?
   * @returns {void}
   */
  handleFolderClicked = (id, openedAutomatically) => {
    const {
      foldersChildren, folderSelectionMap, greyedOutFolders, foldersMap,
    } = this.state;

    // Set state
    this.setState({ loading: true, folderId: id });
    try {
      /*
       * If folder is being opened by the user and it is checked, don't check its children,
       * and grey its and its parents' checkboxes
       */
      if (!openedAutomatically && folderSelectionMap[id] && !foldersChildren[id]?.showChildren) {
        // Determine whether the folder itself should be greyedOut
        greyedOutFolders[id] = foldersChildren[id]
          ?.folders?.some(child => !folderSelectionMap[child.ID] || greyedOutFolders[child.ID]);

        // Get folder's path
        const folderPath = this.getFoldersPath(id, foldersMap, {}).reverse();

        // eslint-disable-next-line no-restricted-syntax
        for (const currentFolder of folderPath) {
        // If parent folder is not checked, break out of the loop
          if (!folderSelectionMap[currentFolder] || !greyedOutFolders[id]) break;

          greyedOutFolders[currentFolder] = true;
        }
      }

      if (foldersChildren[id]) {
        this.setState(prevState => ({
          foldersChildren: {
            ...prevState.foldersChildren,
            [id]: {
              ...prevState.foldersChildren[id],
              showChildren: openedAutomatically || !prevState.foldersChildren[id].showChildren,
            },
          },
          loading: false,
          folderSelectionMap,
          greyedOutFolders,
        }));
      } else {
        const childrenCopy = {
          ...foldersChildren,
          [id]: {
            folders: [],
            showChildren: true,
          },
        };

        this.setState({
          foldersChildren: childrenCopy,
          loading: false,
        });
      }
    } catch (e) {
      this.setErrorState(e);
    }
  };

  /**
   * Sets error in the state
   * @param {object} e - The error object
   * @returns {void}
   */
  setErrorState = (e) => {
    let error = '';

    // If error is from the backend
    if (e.response) {
      error = e.response;
    } else {
      // When error is from code execution
      error = e.message;
    }
    this.setState({ error, loading: false });
  };

  /**
   * Event handler for when form elements' values are changed
   * @param {object} e - onChange event object
   * @returns {void}
   */
  handleFormElementChanged = e => this.setState({
    filterMode: e.target.value,
  });

  /**
   * Changes the checkbox selection state of a folder
   * @param {object} folder - The folder that's checked
   * @return {void}
   */
  handleChangeFolderSelection = (folder) => {
    const {
      foldersChildren, folderSelectionMap, foldersMap, greyedOutFolders,
    } = this.state;
    const { ID: folderId } = folder;

    let childrenObject;

    /**
     * Traverses child folders and sets their selection state
     * @param {array} childFolders  - Array of children folders
     * @param {object} initialState - Initial state of the children folder's selection
     * @param {object} children - foldersChildren object
     * @returns {object} object with folder selection states
     */
    const traverseChildFolders = (childFolders, initialState, children) => childFolders.reduce((object, item) => {
      let childFoldersResult;

      // If folder's children are shown, change their selection too
      if (children[item.ID]?.showChildren) {
        childFoldersResult = traverseChildFolders(
          children[item.ID].folders,
          object,
          children,
        );
      }

      // eslint-disable-next-line no-param-reassign
      object[item.ID] = !folderSelectionMap[folderId];

      // If folder is just selected, determine its greyedOut state
      if (object[item.ID]) {
        greyedOutFolders[item.ID] = false;
      }

      return { ...object, ...childFoldersResult && { ...childFoldersResult } };
    }, initialState);

    // If we're showing the children of the checked folder
    if (foldersChildren[folderId]?.folders.length) {
      childrenObject = traverseChildFolders(
        foldersChildren[folderId].folders,
        {},
        foldersChildren,
      );
    }

    // Set updatedFolderSelectionMap
    const updatedFolderSelectionMap = {
      ...folderSelectionMap,
      [folderId]: !folderSelectionMap[folderId],
      ...childrenObject,
    };

    // Get folder's path
    const folderPath = this.getFoldersPath(folderId, foldersMap, {}).reverse();

    greyedOutFolders[folderId] = false;

    // If folder was checked before, grey-out all parent folders' checkboxes
    if (updatedFolderSelectionMap[folderId]) {
      // eslint-disable-next-line no-restricted-syntax
      for (const currentFolder of folderPath) {
        // If parent folder is not checked, break out of the loop
        if (!updatedFolderSelectionMap[currentFolder]) break;

        greyedOutFolders[currentFolder] = foldersChildren[currentFolder]
          ?.folders?.some(child => child.ID !== folderId && !updatedFolderSelectionMap[child.ID] ||
            greyedOutFolders[child.ID]);
      }
    } else {
      // eslint-disable-next-line no-restricted-syntax
      for (const currentFolder of folderPath) {
        // If parent folder is not checked, break out of the loop
        if (!updatedFolderSelectionMap[currentFolder]) break;

        // Grey-out folder checkbox only if it's checked.
        greyedOutFolders[currentFolder] = updatedFolderSelectionMap[currentFolder];
      }
    }

    // Set state
    this.setState({
      folderSelectionMap: updatedFolderSelectionMap,
      greyedOutFolders,
    });
  };

  /**
   * Saves folders' settings
   * @returns {void}
   */
  handleSaveFolderSettings = async () => {
    const { folderSelectionMap, filterMode } = this.state;
    const { dataExtensionType } = this.props;

    const foldersToFilter = filterMode === Constants.DO_NOT_FILTER_FOLDERS ?
      [] :
      Object.keys(folderSelectionMap).filter(key => folderSelectionMap[key]);

    // Populate settings object (depending on the DE type)
    const settings = {
      ...dataExtensionType === Constants.ADMIN_PANEL__SUBMENU__TARGET_DES && {
        targetDEFoldersToFilter: foldersToFilter,
        includeOrExcludeTargetDEFolders: filterMode,
      },
      ...dataExtensionType === Constants.ADMIN_PANEL__SUBMENU__AVAILABLE_DES && {
        availableDEFoldersToFilter: foldersToFilter,
        includeOrExcludeAvailableDEFolders: filterMode,
      },
    };

    try {
      // Pre-define mode to filter available DE folders
      let mode = Constants.DATAEXTENSION__FILTER_MODE__AVAILABLE;

      // Re-set mode if the filter type is target
      if (dataExtensionType === Constants.ADMIN_PANEL__SUBMENU__TARGET_DES) {
        mode = Constants.DATAEXTENSION__FILTER_MODE__TARGET;
      }

      // Save settings
      await FoldersAPI.saveFoldersSettings(settings, mode);
      this.setState({ showToast: true });

      // Disappear the toast
      setTimeout(() => {
        this.setState({ showToast: false });
      }, 2000);
    } catch (e) {
      this.setErrorState(e);
    }
  };

  /**
   * Expands or collapses all folders
   * @returns {void}
   */
  toggleAllFolders = () => {
    const {
      expandAll, foldersChildren,
    } = this.state;

    // loop through foldersChildren and set showChildren to true/false
    const foldersChildrenCopy = { ...foldersChildren };

    Object.keys(foldersChildrenCopy).forEach((key) => {
      foldersChildrenCopy[key].showChildren = !expandAll;
    });

    this.setState({
      expandAll: !expandAll,
      foldersChildren: foldersChildrenCopy,
    });
  };

  render() {
    const {
      filterMode,
      folders,
      loading,
      error,
      folderSelectionMap,
      foldersChildren,
      greyedOutFolders,
      refreshingFolders,
      folderId,
      showToast,
      expandAll,
    } = this.state;

    // Map with key as DE type and value as the title of the screen
    const titlesMap = {
      [Constants.ADMIN_PANEL__SUBMENU__AVAILABLE_DES]: Constants.AVAILABLE_DES__TITLE,
      [Constants.ADMIN_PANEL__SUBMENU__TARGET_DES]: Constants.TARTGET_DES__TITLE,
    };

    const { dataExtensionType, handleNavigator, loadingSettings } = this.props;

    // Title of the screen
    const title = titlesMap[dataExtensionType];

    // Filter mode variables
    const doNotFilter = filterMode === Constants.DO_NOT_FILTER_FOLDERS;
    const includeFolders = filterMode === Constants.INCLUDE_FOLDERS;
    const excludeFolders = filterMode === Constants.EXCLUDE_FOLDERS;

    return (
      <div className="DE-Folders-container">
        <AdminHeader
          title={title}
          iconTitle="DE Folders Menu"
          iconLink="/assets/icons/standard-sprite/svg/symbols.svg#folder"
        />
        {/* Main Component */}
        <div className="DE-folders-main">
          <div>
            <div className="info-text">
              It is possible to limit the data extensions that are shown in the
              {' '}
              {title}
              {' '}
              section. This can be done by either including or excluding certain data extension folders.
            </div>
            <fieldset className="slds-form-element">
              <div className="slds-form-element__control radio-buttons-container">
                <RadioButton
                  id={Constants.DO_NOT_FILTER_FOLDERS}
                  name={Constants.DO_NOT_FILTER_FOLDERS}
                  label="Don’t filter. Show all data extensions."
                  value={Constants.DO_NOT_FILTER_FOLDERS}
                  onChange={this.handleFormElementChanged}
                  checked={doNotFilter}
                />
                <div className="include-folders-container">
                  <RadioButton
                    id={Constants.INCLUDE_FOLDERS}
                    name={Constants.INCLUDE_FOLDERS}
                    label="Include: only the data extensions in the folders selected below will be shown."
                    value={Constants.INCLUDE_FOLDERS}
                    onChange={this.handleFormElementChanged}
                    checked={includeFolders}
                  />
                  <span className="recommended-text">Recommended</span>
                  <Tooltip
                    nubbinPosition={Constants.NUBBIN_POSITION__TOP_LEFT}
                    type={Constants.TOOLTIP_TYPE__RECOMMENDED_FILTER_MODE}
                  />
                </div>
                <RadioButton
                  id={Constants.EXCLUDE_FOLDERS}
                  name={Constants.EXCLUDE_FOLDERS}
                  // eslint-disable-next-line max-len
                  label="Exclude: all data extensions except the ones in the data extensions in the folders selected below are shown."
                  value={Constants.EXCLUDE_FOLDERS}
                  onChange={this.handleFormElementChanged}
                  checked={excludeFolders}
                />
              </div>
            </fieldset>
            <div className="folders-container">
              { (folders?.length && !refreshingFolders && !loadingSettings) ?
                (
                  <>
                    <div className="allFolders" onClick={this.toggleAllFolders}>
                      <img
                        className="openFolder_icon"
                        src={expandAll ? Collapse : Expand}
                        onClick={this.toggleAllFolders}
                        alt={expandAll ? 'Collapse all icon' : 'Expand all icon'}
                      />
                      <div className="all-folders-text">
                        <span className={classNames('fas', { 'fa-folder-open': expandAll, 'fa-folder': !expandAll })} />
                        <p onMouseDown={e => e.preventDefault()}>
                          All Folders
                        </p>
                      </div>
                    </div>
                    <Folders
                      folders={folders}
                      foldersChildren={foldersChildren}
                      handleFolderClicked={this.handleFolderClicked}
                      handleChangeFolderSelection={this.handleChangeFolderSelection}
                      folderSelectionMap={folderSelectionMap}
                      greyedOutFolders={greyedOutFolders}
                      filteringDEs
                      loading={loading}
                      error={error}
                      id={folderId}
                    />
                  </>
                ) :
                (
                  <div className="loading-text-container">
                    <Spinner
                      size={Constants.SPINNER__SIZE__X_SMALL}
                      loadingText={refreshingFolders ?
                        'Refreshing folders...' :
                        'Loading folders...'}
                      loadingTextClassName="loading-text"
                    />
                  </div>
                )}
            </div>
          </div>
        </div>
        <div className="slds-modal__footer de-folders-footer">
          <div className={`toast-position ${showToast ? 'toast-saved' : 'toast-none'}`}>
            <div className="slds-notify_container slds-is-relative">
              <Toast
                handleOnClick={() => this.setState({ showToast: false })}
                notificationText={Constants.NOTIFICATION__TEXT__SAVED}
              />
            </div>
          </div>
          <Button
            buttonLook={Constants.BUTTON__TYPE__NEUTRAL}
            id="cancel-button"
            onClick={() => handleNavigator(Constants.NAVIGATION__OVERVIEW)}
          >
            Cancel
          </Button>
          <Button
            buttonLook={Constants.BUTTON__TYPE__BRAND}
            id="save-button"
            onClick={this.handleSaveFolderSettings}
            disabled={loadingSettings || loading || refreshingFolders}
          >
            Save
          </Button>
        </div>
      </div>
    );
  }
}

DataExtensionFolders.propTypes = {
  /**
   * Keeps folder filtering information
   */
  settings: PropTypes.instanceOf(Object).isRequired,
  /**
   * Determines which screen to render based on the DE type (available or target)
   */
  dataExtensionType: PropTypes.string.isRequired,
  /**
   * Helps to navigate between screens
   */
  handleNavigator: PropTypes.func.isRequired,
  /**
   * Keeps loading state of folder settings
   */
  loadingSettings: PropTypes.bool.isRequired,
  /**
   * User info from cookie
   */
  userInfo: PropTypes.object,
};

export default connect(mapStateToProps(['userInfo']), null, null, { pure: false })(DataExtensionFolders);
