import React from 'react';
import PropTypes from 'prop-types';

import Constants from '../../../constants/constants';
import Button from '../../../components/shared/Button/Button';
import Tooltip from '../../../components/shared/Tooltip/Tooltip';
import './styles.scss';

const AdminBody = ({
  title, info, htmlBody, handleSave, handleCancel, saving, tooltip, disabled, saveButtonId,
}) => {
  return (
    <div className="admin-body-container">
      <div className="wrapper">
        <div className="content">
          <div className="header">
            <div className="header-title">{title}</div>
          </div>
          <p className="info_text">
            {info}
            {
              tooltip ?
              <Tooltip
                nubbinPosition={Constants.NUBBIN_POSITION__TOP_RIGHT}
                type={tooltip}
              /> :
                null
            }
          </p>
          {htmlBody}
        </div>
      </div>
      <div className="slds-grid footer">
        <div className="slds-col_bump-left">
          <Button
            id="cancel-relation"
            buttonLook={Constants.BUTTON__TYPE__NEUTRAL}
            label="Cancel"
            onClick={handleCancel}
          >
            Cancel
          </Button>
          <Button
            id={saveButtonId}
            buttonLook={Constants.BUTTON__TYPE__BRAND}
            onClick={handleSave}
            loadingClickedButton={saving}
            titleInAction="Saving..."
            disabled={saving || disabled}
          >
            Save
          </Button>
        </div>
      </div>
    </div>
  );
};

AdminBody.propTypes = {
  /**
   * title for the Header
   */
  title: PropTypes.string.isRequired,
  /**
   * info shown below the header
   */
  info: PropTypes.string.isRequired,
  /**
   * Html to be shown inside the body
   */
  htmlBody: PropTypes.string.isRequired,
  /**
   * Handles click on save button
   */
  handleSave: PropTypes.func,
  /**
   * Handles click on cancel button
   */
  handleCancel: PropTypes.func,
  /**
   * State of save button
   */
  saving: PropTypes.bool,
  /**
   * Type of tooltip to be shown
   */
  tooltip: PropTypes.string,
  /**
   * If save button should be disabled or not
   */
  disabled: PropTypes.bool,
  /**
   * Id of the save button
   */
  saveButtonId: PropTypes.string,
};

export default AdminBody;
