import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button } from '@salesforce/design-system-react';
import './styles.scss';
import { connect } from 'react-redux';

import mapStateToProps from '../../../mapStateToProps';
import Features from '../../../features';
import Constants from '../../../constants/constants';
import Util from '../../../util';
import SwalUtil from '../../../utils/swal/swalUtil';
import arrowRight from '../../../icons_v2/arrow-left.svg';
import arrowUp from '../../../icons_v2/arrow-up.svg';

const classNames = require('classnames');

const FilterCard = ({
  dataExtension,
  filterSearchField,
  handleAddFieldToTargetDE,
  editNewAutoTargetDE,
  newTargetDataExtensionFields,
  handleSetSelectionState,
  dropToTargetDataExtensionField,
  manageSubscriberRelationship,
  customValues,
  matchedFields,
  editTargetDataExtension,
  targetDataExtensionFields,
  DEBorderMouseOver,
  hideCollectionAlias,
  inTargetDefinition,
  isBasicMode,
  toggleFieldsVisibility,
  showFields,
  globalCustomValues,
  positionIndex,
  featuresInfo,
}) => {
  const [tabName, setTabName] = useState('Standard');

  const featureCustomValuesIsEnabled = Features.isFeatureEnabled(
    featuresInfo,
    Constants.FEATURE__CUSTOM_VALUES,
  );

  // feature for encrypted fields
  const featureEncryptedFieldsIsEnabled = Features.isFeatureEnabled(
    featuresInfo,
    Constants.FEATURE__ENCRYPTED_FIELDS,
  );

  /**
   * onDragStart set data to transfer
   * @param {object} event - event
   * @param {string} alias - DE alias
   * @param {string} deObjectID - DE Object ID
   * @param {string} type - field type
   * @param {string} fieldName - field name
   * @param {string} customerKey - field customer key
   * @param {string} fieldObjectID - field object id
   * @param {boolean} IsRequired - is required
   * @param {int} MaxLength - field max length
   * @param {string} globalCustomValueId - if of used shared custom value, if used
   * @returns {void}
   */
  const dragField = (
    event,
    alias,
    deObjectID,
    type,
    fieldName,
    customerKey,
    fieldObjectID,
    IsRequired,
    MaxLength,
    globalCustomValueId,
  ) => {
    const maxLength = MaxLength ? JSON.stringify(MaxLength) : Util.getMaxLength(type);

    event.dataTransfer.setData('id', event.target.id);
    event.dataTransfer.setData('alias', alias);
    event.dataTransfer.setData('deObjectID', deObjectID);
    event.dataTransfer.setData('type', type);
    event.dataTransfer.setData('fieldName', fieldName);
    event.dataTransfer.setData('customerKey', customerKey);
    event.dataTransfer.setData('fieldObjectID', fieldObjectID);
    event.dataTransfer.setData('IsRequired', IsRequired);
    event.dataTransfer.setData('MaxLength', maxLength);
    event.dataTransfer.setData('globalCustomValueId', globalCustomValueId || '');
  };

  // make sure filterSearchField is always a string (required for filtering below)
  if (!filterSearchField) {
    // eslint-disable-next-line no-param-reassign
    filterSearchField = '';
  }

  // filter fields based on the name matching the search criteria
  let filteredFields = [];

  if (dataExtension && dataExtension.fields) {
    filteredFields =
      dataExtension.fields.filter(field => field.Name.toString().toLowerCase().includes(filterSearchField.toString()
        .toLowerCase()));
  } else if (customValues?.length && tabName === 'Standard') {
    filteredFields =
      customValues.filter(field => field.name && field.name.length > 0 && filterSearchField !== undefined &&
        field.name.toString().toLowerCase().includes(filterSearchField.toString().toLowerCase()))
        .sort((a, b) => a.name.localeCompare(b.name));
  } else if (globalCustomValues?.length && tabName === 'Shared') {
    filteredFields =
      globalCustomValues?.filter(field => field.name && field.name.length > 0 && filterSearchField !== undefined &&
        field.name.toString().toLowerCase().includes(filterSearchField.toString().toLowerCase()))
        .sort((a, b) => a.name.localeCompare(b.name));
  }

  /**
   * Returns a unique field name, by increasing a counter that's part of the name
   * @param {string} name - name of a field
   * @param {array} usedNames - names already used in Target Data Extension
   * @returns {string} name
   */
  const getUniqueFieldName = (name, usedNames) => {
    // Create a new name for the field
    do {
      const counter = Number(name[name.length - 1]);

      if (counter) {
        // eslint-disable-next-line no-param-reassign
        name = name.substring(0, name.length - 1) + '' + (counter + 1);
      } else {
        // eslint-disable-next-line no-param-reassign
        name += '2';
      }
    }
    // Make sure that field name is unique before stopping the loop.
    while (usedNames.includes(name.toString().toLowerCase()));

    return name;
  };

  /**
   * Add new matched field with unique properties
   * @param {object} originalField - Available field
   * @param {object} newField - Target Data Extension field
   * @returns {void}
   */
  const addNewMatchedField = (originalField, newField) => {
    // Create all necessary values for field drop.
    const availableFieldName = originalField.Name.toString();
    const availableFieldDataExtensionAlias = newField.deAlias.toString();
    const targetDataExtensionFieldName = newField.Name.toString();
    const availableFieldDataExtensionCustomerKey = originalField.DataExtension.CustomerKey;
    const availableFieldObjectID = originalField.ObjectID + '+' + targetDataExtensionFieldName;
    const targetDataExtensionFieldObjectID = newField.ObjectID + '+' + targetDataExtensionFieldName;
    const availableFieldIsRequired = originalField.IsRequired;
    const availableFieldMaxLength = originalField.MaxLength || Util.getMaxLength(originalField.FieldType);

    // Drop field to Target Data Extension section.
    dropToTargetDataExtensionField(
      null,
      null,
      availableFieldName,
      availableFieldDataExtensionAlias,
      targetDataExtensionFieldName,
      availableFieldDataExtensionCustomerKey,
      availableFieldObjectID,
      targetDataExtensionFieldObjectID,
      true,
      availableFieldIsRequired,
      availableFieldMaxLength,
    );

    // Assign ObjectID to new field.
    /* eslint-disable no-param-reassign */
    newField.ObjectID = targetDataExtensionFieldObjectID;
  };

  /**
   * Add all fields from available fields as new ACDE fields
   * @returns {void}
   */
  const addAllFields = async () => {
    const result = await SwalUtil.fire({
      title: 'Add All Fields',
      message: `All fields from <b>${dataExtension.Name.toString()}</b> will be added to your Target Data Extension.`,
      options: {
        showCancelButton: true,
        confirmButtonText: 'Confirm',
        cancelButtonText: 'Cancel',
      },
    });

    if (result.value) {
      // The new fields of the Target Data Extension.
      let newFields = [];

      /**
       * If we are on edit mode use editTargetDataExtension.
       * If we are on create mode use newTargetDataExtensionFields.
       */
      if (editTargetDataExtension) {
        newFields = [...targetDataExtensionFields];
      } else {
        newFields = [...newTargetDataExtensionFields];
      }

      // An array containing all the existing names inside the Target Data Extension.
      const usedNames = newFields.map(field => field.Name.toString().toLowerCase());

      /**
       * Loop through the fields to be added.
       * If the name of a field is not unique, create a new one.
       * In any case, add it to the Target Data Extension fields and update the existing names array.
       */
      dataExtension.fields.forEach((field) => {
        // Create a copy of the original field to add in the Target Data Extension.
        const newField = { ...field };

        // Remove underscore if the field belongs to a Data View
        newField.Name = Util.removeUnderscoreFirstCharacter(newField.Name.toString());

        newField.deAlias = dataExtension.deAlias;

        // Make sure field name is unique.
        if (usedNames.includes(newField.Name.toString().toLowerCase())) {
          // Create a unique name for the field.
          newField.Name = getUniqueFieldName(newField.Name.toString(), usedNames);
        }

        // Add field's name in used names.
        usedNames.push(newField.Name.toLowerCase());

        // if FieldType is Text and MaxLength is not defined add max length to 4000
        if(newField.FieldType === 'Text' && !newField.MaxLength) {
          newField.MaxLength = Constants.FIELD_TYPE__TEXT__MAX_LENGTH__DEFAULT_VALUE;
        }

        // Add field to Target Data Extension area.
        addNewMatchedField(field, newField);

        // Add field to newFields
        newFields.push(newField);
      });

      // Update Target Data Extension's fields in state.
      if (editTargetDataExtension) {
        handleSetSelectionState({ targetDataExtensionFields: newFields });
      } else {
        handleSetSelectionState({ newTargetDataExtensionFields: newFields });
      }

      // Manage Sender Relationship
      for (let f = 0; f < newFields.length; f += 1) {
        manageSubscriberRelationship(newFields, newFields[f]);
      }
    }
  };

  /**
   * Function which helps to open custom values modal
   * @returns {void}
   */
  const openAddValuesModal = () => {
    handleSetSelectionState({ showAddValuesModal: true });
  };

  /**
   * delete custom value field from custom values and also from matched fields
   * @param {string} fieldName - name of the field we want to delete
   * @returns {void}
   */
  const deleteCustomValueField = async (fieldName) => {
    let fieldIsMapped = false;

    // check if Custom field is mapped
    matchedFields.forEach((field) => {
      if (field.availableFieldName === fieldName && field.availableFieldObjectID &&
        field.availableFieldObjectID.includes('customValues')) {
        fieldIsMapped = true;
      }
    });

    const result = await SwalUtil.fire({
      title: 'Are you sure you want to delete this Custom Value?',
      message: fieldIsMapped ?
        'This Custom Value will be removed from Custom Fields and matched Target Data Extension Fields.' :
        'This Custom Value will be removed.',
      options: {
        showCancelButton: true,
      },
    });

    // if user confirms remove field
    if (result.value) {
      const removeNewTargetDEField = [];

      let removedFields = [...matchedFields];

      if (fieldName) {
        customValues.forEach((field) => {
          if (fieldName !== field.name) {
            removeNewTargetDEField.push(field);
          }
        });

        if (fieldIsMapped) {
          // also remove that field from matched fields
          removedFields = matchedFields.filter(field => (field.availableFieldName !== fieldName || (
            field.availableFieldObjectID && !field.availableFieldObjectID.includes('customValues'))));
        }
      }

      handleSetSelectionState({
        matchedFields: removedFields,
        customValues: removeNewTargetDEField,
      });
    }
  };

  /**
   * open edit custom value modal
   * @param {string} fieldName - Name of the field we want to edit
   * @returns {void}
   */
  const editCustomValue = (fieldName) => {
    const customValueIndex = tabName === 'Standard' ?
      customValues.findIndex(field => field.name === fieldName) :
      globalCustomValues.findIndex(field => field.name === fieldName) +
      (Array.isArray(customValues) ? customValues.length : 0);

    handleSetSelectionState({ editCustomValueIndex: customValueIndex, showAddValuesModal: true });
  };

  /**
   * Copies a (Global) Custom Value
   * @param {object} customValue - Custom Value to be copied
   * @returns {void}
   */
  const copyCustomValue = async (customValue) => {
    // Ask from the user to provide a name for the new Custom Value
    const result = await SwalUtil.fire({
      title: 'Copy Custom Value',
      options: {
        customClass: {
          content: 'copy-custom-value-modal',
        },
        text: 'Choose a name for your new Custom Value.',
        input: 'text',
        inputValue: customValue.name,
        inputValidator: (value) => {
          if (!value) return 'The name should contain at least one character.';
          if (customValues.map(customValue => customValue.name).find(name => name === value)?.length) {
            return 'A Custom Value with this name already exists.';
          }
        },
        showCancelButton: true,
      },
    });

    // Check if user confirmed
    if (result.isConfirmed) {
      // Keep only needed properties
      const {
        isGlobal,
        description,
        businessUnits,
        orgId,
        selectedDataExtensions,
        ...newCustomValue
      } = customValue;

      // Update state with new Custom Value
      handleSetSelectionState({
        customValues: [...customValues, { ...newCustomValue, name: result.value }],
      });

      if (tabName === 'Shared') setTabName('Standard');
    }
  };

  /**
   * HTML for add button
   * @returns {object} The add button
   */
  const addButton = () => {
    if (filterSearchField === '' && (editNewAutoTargetDE || editTargetDataExtension) && !customValues) {
      return (
        <div
          onClick={() => addAllFields()}
          className="des-lib-add-button-text"
        >
          Add all fields
        </div>
      );
    }
    if (customValues !== undefined) {
      return (
        <div className="add-new-value-button-container">
          <Button
            id="open-custom-value-modal"
            onClick={() => openAddValuesModal()}
            disabled={!featureCustomValuesIsEnabled}
            iconCategory="utility"
            iconName="add"
            iconPosition="left"
            label="Add new value"
          />
        </div>
      );
    }

    return null;
  };

  /**
   * Returns true if the field card belongs to an encrypted field that is in selection criteria
   * @param {object} field - available field
   * @returns {boolean} true/false depending on the condition being met
   */
  const encryptedFieldCardForFilterContainer = field => field.StorageType ===
    Constants.FIELD_STORAGE_TYPE__ENCRYPTED && !inTargetDefinition;

  /**
   * Returns class name for available field
   * @param {object} field - available field
   * @returns {string} class name
   */
  const classNameAvailableField = field => classNames(
    'drag-field available-field',
    // eslint-disable-next-line quote-props
    { 'encrypted': encryptedFieldCardForFilterContainer(field) },
  );

  const classNameAvailableCustomValue = () => classNames(
    'available-field custom-values-wrapper drag-field',
  );

  return (
    <div>
      {/* {customValues !== undefined && <Tabs
        tabs={[
          {
            name: 'Standard',
          },
          {
            name: 'Shared',
            disabled: !featureGlobalCustomValuesIsEnabled,
          },
        ]}
        changeTab={setTabName}
        active={tabName}
        showEssentialsUpgradeModal={showEssentialsUpgradeModal}
      />} */}
      {customValues !== undefined && (
        <div>
          <div className="des-lib-custom-values-container">
            <div className="des-lib-custom-values-text">Standard</div>
            <img
              onClick={() => setTabName('Standard')}
              className="des-available-fields-icon-v2-expand"
              alt="Shape"
              src={arrowRight}
            />
          </div>
        </div>
      )}
      {(filteredFields.length > 0 || customValues !== undefined) && (tabName !== 'Shared') &&
        (
          <div>
            {
            (filterSearchField === '' && (editNewAutoTargetDE || editTargetDataExtension) && !customValues) &&
            (
            <div
            className={classNames(
              'collection-label-container-v2',
              { 'disabled-cv': !featureCustomValuesIsEnabled && customValues !== undefined },
            )}
            style={isBasicMode ? {} : { cursor: 'pointer' }}
            >
              <div
              className="filter-card-v2">
              <span
                className="collection-name slds-truncate"
                onClick={() => toggleFieldsVisibility(dataExtension?.deAlias, positionIndex)}
                title={dataExtension ?
                  (`${dataExtension.deAlias}${hideCollectionAlias || isBasicMode ?
                    '' :
                    `(${dataExtension.Name})`}`) :
                  null}
              >
                {dataExtension && dataExtension.deAlias !== undefined ?
                  (
                    <>
                      <p className="slds-truncate">{dataExtension.deAlias}</p>
                      {hideCollectionAlias || isBasicMode ?
                        '' :
                        (
                          <div className="data-extension-name">
                            <span>
                            <p className="slds-truncate collection-name-alias">{dataExtension.Name.toString()}</p>
                            </span>
                          </div>
                        )}
                    </>
                  ) :
                  null}
              </span>
              {!isBasicMode && dataExtension && dataExtension.deAlias !== undefined && (
                <div
                className="slds-icon-size"
                aria-hidden="true"
                onClick={() => toggleFieldsVisibility(dataExtension?.deAlias, positionIndex)}
                id="right-icon"
                >
                  {showFields && (
                    <img className="des-available-fields-icon" alt="Shape" src={arrowUp} />
                  )}
                  {!showFields && (
                    <img className="des-available-fields-icon" alt="Shape" src={arrowRight} />
                  )}
                </div>
              )}
              </div>
            </div>
            )
            }
             {
            !(filterSearchField === '' && (editNewAutoTargetDE || editTargetDataExtension) && !customValues) &&
            (
              <div
              className={classNames(
                'collection-label-container-v2',
                { 'disabled-cv': !featureCustomValuesIsEnabled && customValues !== undefined },
              )}
              onClick={() => toggleFieldsVisibility(dataExtension?.deAlias, positionIndex)}
              style={isBasicMode ? {} : { cursor: 'pointer' }}
              >
              <span
                className="collection-name slds-truncate"
                title={dataExtension ?
                  (`${dataExtension.deAlias}${hideCollectionAlias || isBasicMode ?
                    '' :
                    `(${dataExtension.Name})`}`) :
                  null}
              >
                {dataExtension && dataExtension.deAlias !== undefined ?
                  (
                    <>
                    <p className="slds-truncate">{dataExtension.deAlias}</p>
                      {hideCollectionAlias || isBasicMode ?
                        '' :
                        (
                          <div className="data-extension-name">
                            <p className="slds-truncate collection-name-alias">{dataExtension.Name.toString()}</p>
                          </div>
                        )}
                    </>
                  ) :
                  null}
              </span>
              {!isBasicMode && dataExtension && dataExtension.deAlias !== undefined && (
                <div
                className="chevron-icons-container"
                >
                {showFields && (
                    <img className="des-available-fields-icon" alt="Shape" src={arrowUp} />
                )}
                {!showFields && (
                  <img className="des-available-fields-icon" alt="Shape" src={arrowRight} />
                )}
                </div>
              )}
              </div>
            )
            }
          </div>
        )}
        {(showFields && !isBasicMode) && (
        <div className="add-new-value-button-container-right">
              {addButton()}
        </div>
        )}
        {customValues !== undefined && (
          <div className="add-new-value-button-container-right-v2">
                {addButton()}
          </div>
        )}
      {dataExtension?.fields ?
        ((showFields && !isBasicMode) && filteredFields.map(f => (
          <div
            title={f?.description || `${f.Name.toString()} (${dataExtension.deAlias})`}
            id={dataExtension.deAlias + f.ObjectID}
            data-collection-alias={dataExtension.deAlias}
            className={classNameAvailableField(f)}
            data-field={dataExtension.Name.toString()}
            onDoubleClick={editNewAutoTargetDE || editTargetDataExtension ?
              e => handleAddFieldToTargetDE(
                e,
                null,
                null,
                null,
                null,
                {
                  availableFieldName: f.Name.toString(),
                  availableFieldObjectID: f.ObjectID,
                  availableFieldDataExtensionCustomerKey: f.DataExtension.CustomerKey,
                  availableFieldFieldType: f.FieldType,
                  availableFieldIsRequired: f.IsRequired,
                  availableFieldMaxLength: f.MaxLength,
                },
              ) :
              null}
            onDragStart={(e) => {
              if (encryptedFieldCardForFilterContainer(f)) return false;
              handleSetSelectionState({ filterBorderMouseOver: true, DEBorderMouseOver: false });

              return dragField(
                e,
                dataExtension.deAlias,
                dataExtension?.ObjectID || dataExtension?.CustomerKey,
                f.FieldType,
                f.Name.toString(),
                f.DataExtension?.CustomerKey,
                f.ObjectID,
                f.IsRequired,
                f.MaxLength,
              );
            }}
            onDragEnd={() => {
              handleSetSelectionState({ filterBorderMouseOver: false, DEBorderMouseOver: false });
            }}
            onDragOver={e => e.preventDefault()}
            onDrop={() => {
              handleSetSelectionState({ filterBorderMouseOver: false, DEBorderMouseOver: false });
            }}
            draggable={!encryptedFieldCardForFilterContainer(f)}
            key={dataExtension.deAlias + f.ObjectID}
            style={{ pointerEvents: DEBorderMouseOver ? 'none' : '' }}
          >
            <span className="des-available-fields-name">
              +
              <span className="des-available-fields-name-space">{f.Name.toString()}</span>
            </span>
            {f.StorageType === Constants.FIELD_STORAGE_TYPE__ENCRYPTED &&
              featureEncryptedFieldsIsEnabled ?
              (
                <span
                  className="fas fa-lock encrypted-lock-icon"
                  title={Constants.FIELD_INFO_LABEL__ENCRYPTED_FIELD}
                />
              ) :
              null}
          </div>
        ))) :
        // Custom values
        filteredFields.map((f, index) => (
          <div
            className={classNameAvailableCustomValue()}
            onDragStart={(e) => {
              handleSetSelectionState({ filterBorderMouseOver: true, DEBorderMouseOver: false });

              return dragField(
                e,
                null,
                null,
                f.fieldType,
                f.name.toString(),
                null,
                null,
                true,
                f.MaxLength || null,
                f?.isGlobal ? f._id : null,
              );
            }}
            title={f.description || 'No description available.'}
            onDragEnd={() => {
              handleSetSelectionState({ filterBorderMouseOver: false, DEBorderMouseOver: false });
            }}
            onDragOver={e => e.preventDefault()}
            onDrop={() => {
              handleSetSelectionState({ filterBorderMouseOver: false, DEBorderMouseOver: false });
            }}
            draggable
            key={index}
            style={{ pointerEvents: DEBorderMouseOver ? 'none' : '' }}
          >
            <span className="slds-truncate">{f.name.toString()}</span>
            <Button
              className="remove-field-target-de"
              noButtonClass
            >
              <span
                title="Copy"
                className="slds-icon_container"
              >
                <svg
                  onClick={() => copyCustomValue(f)}
                  className="slds-button__icon slds-icon_x-small copy-custom-value"
                  aria-hidden="true"
                >
                  <use xlinkHref="/assets/icons/utility-sprite/svg/symbols.svg#copy" />
                </svg>
              </span>
              {f.isGlobal ?
                <div
                    onClick={() => editCustomValue(f.name)}
                  >
                    <span
                      id="edit-info-icon"
                      // eslint-disable-next-line max-len
                      className="slds-icon_container slds-icon-utility-announcement slds-current-color remove-filter last-item"
                      title="View"
                    >
                      <svg
                        className="slds-icon slds-icon_x-small dropEdit"
                        aria-hidden="true"
                      >
                        <use xlinkHref="/assets/icons/utility-sprite/svg/symbols.svg#preview" />
                      </svg>
                    </span>
                </div> :
                <>
                  <div
                    className="custom-field-info-button"
                    onClick={() => editCustomValue(f.name)}
                  >
                    <span
                      id="edit-info-icon"
                      // eslint-disable-next-line max-len
                      className="slds-icon_container slds-icon-utility-announcement slds-current-color remove-filter edit-info-icon"
                      title="Edit"
                    >
                      <svg
                        className="slds-icon slds-icon_x-small dropEdit"
                        aria-hidden="true"
                      >
                        <use xlinkHref="/assets/icons/utility-sprite/svg/symbols.svg#edit" />
                      </svg>
                    </span>
                  </div>
                  <i
                    className="far fa-times-circle remove-filter"
                    id="delete-custom-value"
                    onClick={() => deleteCustomValueField(f.name)}
                  />
                </>}
            </Button>
          </div>
        ))}

      {customValues !== undefined && (
        <div>

          <div className="des-lib-custom-values-container">
            <div className="des-lib-custom-values-text">Shared</div>
            <img
            onClick={() => setTabName('Standard')}
            className="des-available-fields-icon-v2-expand"
            alt="Shape"
            src={arrowRight}
            />
          </div>
        </div>
      )}
    </div>
  );
};

FilterCard.propTypes = {
  /**
   * the data extension which is one of the selected data extensions
   */
  dataExtension: PropTypes.instanceOf(Object),
  /**
   * it keeps the given value of the search input on available fields
   */
  filterSearchField: PropTypes.string,
  /**
   * It helps to a new field to a DE while creating one
   */
  handleAddFieldToTargetDE: PropTypes.func,
  /**
   * It keeps the value of AC/DE creation status
   * It will be passed from Selection.js
   */
  editNewAutoTargetDE: PropTypes.bool,
  /**
   * It keeps the fields of a new target data extension while creating one
   */
  newTargetDataExtensionFields: PropTypes.instanceOf(Array),
  /**
   * It helps to set the Selection`s state
   * It will be passed from Selection.js
   */
  handleSetSelectionState: PropTypes.func.isRequired,
  /**
   * It helps to match a field between available fields and target DE fields
   */
  dropToTargetDataExtensionField: PropTypes.func,
  /**
   * It helps to manage subscriber relationship while creating a data extension
   * It will be passed from Selection.js
   */
  manageSubscriberRelationship: PropTypes.func,
  /**
   * boolean state from Selection for going in or out the edit target de mode
   */
  editTargetDataExtension: PropTypes.bool,
  /**
   * It keeps the fields of an existing target data extension
   * It will be passed from Selection.js
   */
  targetDataExtensionFields: PropTypes.instanceOf(Array),
  /*
   * Keeps track whether Available DE are dragged
   */
  DEBorderMouseOver: PropTypes.bool.isRequired,
  /**
   * It keeps custom values data
   * It will be passed from Selection.js
   */
  customValues: PropTypes.instanceOf(Array),
  /**
   * It keeps the matchedFields for a target data extension of the Selection
   * It will be passed from Selection.js
   */
  matchedFields: PropTypes.instanceOf(Array),
  /**
   * It determines if the collection alias should be hidden after the name in the filter line
   */
  hideCollectionAlias: PropTypes.bool.isRequired,
  /**
   * Defines whether the component is used in Target Definition view
   */
  inTargetDefinition: PropTypes.bool,
  /**
   * Indicates whether a selection is in basic mode
   */
  isBasicMode: PropTypes.bool,
  /**
   * onClick event handler for collection-label-container
   */
  toggleFieldsVisibility: PropTypes.func,
  /**
   * Indicates whether DE fields are shown
   */
  showFields: PropTypes.bool,
  /**
   * Shared Custom Values defined for this BU
   */
  globalCustomValues: PropTypes.instanceOf(Array),
  /**
   * index of an item in DE
   */
  positionIndex: PropTypes.string,
  /**
   * Features info from cookie
   */
  featuresInfo: PropTypes.object,
};

export default connect(mapStateToProps(['featuresInfo']), null, null, { pure: false })(FilterCard);
