import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import './styles.scss';
// eslint-disable-next-line import/no-cycle
import FilterLine from '../FilterLine/FilterLine';
import Constants from '../../../../../constants/constants';

const FilterContainer = ({
  handleSetDataExtensionToFilter,
  dataExtensions,
  filters,
  selectedDataExtensions,
  compareSelectedDataExtensions,
  dropFieldOnFilters,
  operator,
  handleHoverFilterMerging,
  handleHideAddFiltersTogether,
  handleAddNewFilterline,
  filterContainerId,
  handleUpdateFilterLineCriteria,
  handleUpdateFormulaFilterLineCriteria,
  handleUpdateFilterLineValue,
  handleMergeFilterLines,
  handleRemoveFilterLine,
  subQueryModalFilterLineId,
  handleLogicChange,
  handleSetSelectionState,
  isSubQuery,
  handleOpenSubQueryModal,
  handleRemoveDataExtensionFromFilter,
  handleChangeFilterDataExtensionField,
  handleOnChangeRelativeDateFilterType,
  handleOnChangeRelativeDateValue,
  handleOnChangeRelativeDateFilterInterval,
  handleOnChangeRelativeDateValueStartFrom,
  handleOnChangeRelativeDate,
  handleOnChangeIsCompareFieldsFilter,
  handleOnChangeCompareFieldsFilter,
  handleOnChangeFilterComparedDataExtension,
  handleFeatureMissing,
  handleOnChangeFormulaFilterFormulaValue,
  handleOnChangeFormulaValue,
  handleAddFormulaFilter,
  DEBorderMouseOver,
  filterBorderMouseOver,
  showInResultsOption,
  allFilters,
  filterSets,
  addFilterSetFilters,
  pickLists,
  handleOnChangeIsPicklistOption,
  loadingSubQueryFields,
  handleFiltersSave,
  filterType,
  subQueryFilters,
  isSubQueryParentRelation,
  returnPredefinedRelationById,
  tags,
  handleTagsChanged,
  handleSetTags,
  updateTags,
  handleOpenTimezoneModal,
  isInResultsFormula,
  subQuery,
  parentId,
  collapseFormulas,
  handleCollapseAllFormulas,
  showEssentialsUpgradeModal,
  parentBackgroundColor = null,
  selectDataExtensions,
  areFilterSetsAvailable,
  updateSimpleFilterline = () => {},
  updateFiltersState = () => {},
  isSelectedFiltersSection,
}) => {
  const handleAddFilterManually = (filterContainerId) => {
    const e = {
      preventDefault: () => { },
      dataTransfer: { getData: key => (key === 'manuallyAddedFilter' ? true : null) },
    };

    dropFieldOnFilters(e, filterContainerId);
  };

  return (
    <div className={classNames('new-filter-box-main-container', { 'height-100': !filters.length })}>
      <div
        className="filters-groups-container"
      >
        {filters && filters.length === 1 ?
          filters.map((filter, index) => (
            <FilterLine
              isFirstElement={index === 0}
              handleSetDataExtensionToFilter={handleSetDataExtensionToFilter}
              dataExtensions={dataExtensions}
              hideCollectionAlias={false}
              updateSimpleFilterline={updateSimpleFilterline}
              updateFiltersState={updateFiltersState}
              addFilterSetFilters={addFilterSetFilters}
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...filter}
              selectDataExtensions={selectDataExtensions}
              showInResultsOption={showInResultsOption}
              handleRemoveFilterLine={handleRemoveFilterLine}
              filterType={filterType}
              collapseFormulas={collapseFormulas}
              handleCollapseAllFormulas={handleCollapseAllFormulas}
              subQueryModalFilterLineId={subQueryModalFilterLineId}
              selectedDataExtensions={selectedDataExtensions}
              compareSelectedDataExtensions={compareSelectedDataExtensions}
              key={filter.id}
              length={filters.length}
              filters={filters}
              allFilters={allFilters}
              filterSets={filterSets}
              handleUpdateFilterLineCriteria={handleUpdateFilterLineCriteria}
              handleUpdateFormulaFilterLineCriteria={handleUpdateFormulaFilterLineCriteria}
              handleUpdateFilterLineValue={handleUpdateFilterLineValue}
              handleMergeFilterLines={handleMergeFilterLines}
              formulaForRelationFilter={filter.formula}
              isSubQuery={isSubQuery}
              isCompareFieldsFilter={filter.isCompareFieldsFilter}
              handleOpenSubQueryModal={handleOpenSubQueryModal}
              dateFilterType={filter.dateFilterType}
              handleOnChangeRelativeDateFilterType={handleOnChangeRelativeDateFilterType}
              dateValue={filter.dateValue}
              filterInterval={filter.filterInterval}
              dateValueStart={filter.dateValueStart}
              subQuery={isInResultsFormula ? subQuery : filter.subQuery}
              handleRemoveDataExtensionFromFilter={handleRemoveDataExtensionFromFilter}
              handleChangeFilterDataExtensionField={handleChangeFilterDataExtensionField}
              handleOnChangeRelativeDateValue={handleOnChangeRelativeDateValue}
              handleOnChangeRelativeDateFilterInterval={handleOnChangeRelativeDateFilterInterval}
              handleOnChangeRelativeDateValueStartFrom={handleOnChangeRelativeDateValueStartFrom}
              handleOnChangeRelativeDate={handleOnChangeRelativeDate}
              handleOnChangeIsCompareFieldsFilter={handleOnChangeIsCompareFieldsFilter}
              handleOnChangeCompareFieldsFilter={handleOnChangeCompareFieldsFilter}
              handleOnChangeFilterComparedDataExtension={handleOnChangeFilterComparedDataExtension}
              handleAddFormulaFilter={handleAddFormulaFilter}
              comparableFields={filter.comparableFields}
              comparedDataExtension={filter.comparedDataExtension}
              comparedField={filter.comparedField}
              handleFeatureMissing={handleFeatureMissing}
              handleOnChangeFormulaFilterFormulaValue={handleOnChangeFormulaFilterFormulaValue}
              formula={filter.formula}
              handleOnChangeFormulaValue={handleOnChangeFormulaValue}
              DEBorderMouseOver={DEBorderMouseOver}
              filterBorderMouseOver={filterBorderMouseOver}
              handleSetSelectionState={handleSetSelectionState}
              pickLists={pickLists}
              handleOnChangeIsPicklistOption={handleOnChangeIsPicklistOption}
              isPicklistOptions={filter.isPicklistOptions}
              loadingSubQueryFields={loadingSubQueryFields}
              handleFiltersSave={handleFiltersSave}
              subQueryFilters={subQueryFilters}
              relation={filter.relation}
              isSubQueryParentRelation={isSubQueryParentRelation}
              filterObject={filter}
              returnPredefinedRelationById={returnPredefinedRelationById}
              tags={tags}
              handleTagsChanged={handleTagsChanged}
              handleSetTags={handleSetTags}
              updateTags={updateTags}
              handleOpenTimezoneModal={handleOpenTimezoneModal}
              isInResultsFormula={isInResultsFormula}
              handleLogicChange={handleLogicChange}
              handleHoverFilterMerging={handleHoverFilterMerging}
              handleHideAddFiltersTogether={handleHideAddFiltersTogether}
              dropFieldOnFilters={dropFieldOnFilters}
              selectedActiveDE={filter.selectedActiveDE}
              showEssentialsUpgradeModal={showEssentialsUpgradeModal}
            />
          )) :
          null}
        {' '}
        {filters && filters.length > 1 ?
          (
            <div className="filter-line-group has-no-children">
              <div
              className="new-filters"
              style={
                {
                  backgroundColor: parentBackgroundColor === null ?
                    '#FFFFFF' :
                    parentBackgroundColor === '#FFFFFF' ? '#F4F7FB' : '#FFFFFF',
                }
}>
                {filters.map((filter, i) => filter.filters ?
                  (
                    <FilterContainer
                      parentBackgroundColor={parentBackgroundColor === null ?
                        '#FFFFFF' :
                        parentBackgroundColor === '#FFFFFF' ? '#F4F7FB' : '#FFFFFF'}
                      handleSetDataExtensionToFilter={handleSetDataExtensionToFilter}
                      dataExtensions={dataExtensions}
                      showInResultsOption={showInResultsOption}
                      filters={filter.filters}
                      filterContainerId={filter.id}
                      allFilters={allFilters}
                      isSelectedFiltersSection={isSelectedFiltersSection}
                      areFilterSetsAvailable={areFilterSetsAvailable}
                      handleRemoveFilterLine={handleRemoveFilterLine}
                      filterType={filterType}
                      subQueryModalFilterLineId={subQueryModalFilterLineId}
                      updateFiltersState={updateFiltersState}
                      updateSimpleFilterline={updateSimpleFilterline}
                      filterSets={filterSets}
                      addFilterSetFilters={addFilterSetFilters}
                      selectedDataExtensions={selectedDataExtensions}
                      compareSelectedDataExtensions={compareSelectedDataExtensions}
                      handleLogicChange={handleLogicChange}
                      handleHoverFilterMerging={handleHoverFilterMerging}
                      handleHideAddFiltersTogether={handleHideAddFiltersTogether}
                      dropFieldOnFilters={dropFieldOnFilters}
                      handleUpdateFilterLineCriteria={handleUpdateFilterLineCriteria}
                      handleUpdateFormulaFilterLineCriteria={handleUpdateFormulaFilterLineCriteria}
                      handleUpdateFilterLineValue={handleUpdateFilterLineValue}
                      handleMergeFilterLines={handleMergeFilterLines}
                      operator={filter.operator}
                      key={i}
                      isSubQuery={isSubQuery}
                      handleOpenSubQueryModal={handleOpenSubQueryModal}
                      handleSetSelectionState={handleSetSelectionState}
                      subQuery={subQuery || filter.subQuery}
                      handleAddFormulaFilter={handleAddFormulaFilter}
                      handleRemoveDataExtensionFromFilter={handleRemoveDataExtensionFromFilter}
                      handleChangeFilterDataExtensionField={handleChangeFilterDataExtensionField}
                      handleOnChangeRelativeDateFilterType={handleOnChangeRelativeDateFilterType}
                      handleOnChangeRelativeDateValue={handleOnChangeRelativeDateValue}
                      handleOnChangeRelativeDateFilterInterval={handleOnChangeRelativeDateFilterInterval}
                      handleOnChangeRelativeDateValueStartFrom={handleOnChangeRelativeDateValueStartFrom}
                      handleOnChangeRelativeDate={handleOnChangeRelativeDate}
                      handleOnChangeIsCompareFieldsFilter={handleOnChangeIsCompareFieldsFilter}
                      handleOnChangeCompareFieldsFilter={handleOnChangeCompareFieldsFilter}
                      handleOnChangeFilterComparedDataExtension={handleOnChangeFilterComparedDataExtension}
                      handleFeatureMissing={handleFeatureMissing}
                      handleOnChangeFormulaFilterFormulaValue={handleOnChangeFormulaFilterFormulaValue}
                      handleOnChangeFormulaValue={handleOnChangeFormulaValue}
                      DEBorderMouseOver={DEBorderMouseOver}
                      filterBorderMouseOver={filterBorderMouseOver}
                      pickLists={pickLists}
                      handleOnChangeIsPicklistOption={handleOnChangeIsPicklistOption}
                      loadingSubQueryFields={loadingSubQueryFields}
                      handleFiltersSave={handleFiltersSave}
                      subQueryFilters={subQueryFilters}
                      isSubQueryParentRelation={isSubQueryParentRelation}
                      returnPredefinedRelationById={returnPredefinedRelationById}
                      tags={tags}
                      handleTagsChanged={handleTagsChanged}
                      handleSetTags={handleSetTags}
                      updateTags={updateTags}
                      handleOpenTimezoneModal={handleOpenTimezoneModal}
                      isInResultsFormula={isInResultsFormula}
                      parentId={parentId}
                      formulaForRelationFilter={filter.formula}
                      collapseFormulas={collapseFormulas}
                      handleCollapseAllFormulas={handleCollapseAllFormulas}
                      selectedActiveDE={filter.selectedActiveDE}
                      showEssentialsUpgradeModal={showEssentialsUpgradeModal}
                    />
                  ) :
                  (
                    <div
                      className="filterline-container"
                      onDrop={(e) => {
                        if (DEBorderMouseOver) return;
                        // eslint-disable-next-line no-param-reassign
                        e.currentTarget.style.border = null;
                        dropFieldOnFilters(e, filterContainerId, i, isInResultsFormula, filter.parentId);
                      }}
                      key={`${filter.collectionAlias}-${filter.field}-${i}-container`}
                      onDragOver={(e) => {
                        if (e.currentTarget.className !== 'filterline-container' ||
                          filterBorderMouseOver || DEBorderMouseOver) return;
                        // eslint-disable-next-line no-param-reassign
                        e.currentTarget.style.borderTop = 'var(--redBorderTop)';
                      }}
                      onDragEnter={(e) => {
                        if (e.currentTarget.className !== 'filterline-container' ||
                          filterBorderMouseOver || DEBorderMouseOver) return;
                        // eslint-disable-next-line no-param-reassign
                        e.currentTarget.style.borderTop = 'var(--redBorderTop)';
                      }}
                      onDragLeave={(e) => {
                        // eslint-disable-next-line no-param-reassign
                        e.currentTarget.style.border = null;
                      }}
                      onDragEnd={(e) => {
                        // eslint-disable-next-line no-param-reassign
                        e.currentTarget.style.border = null;
                      }}
                    >
                      <FilterLine
                        isFirstElement={i === 0}
                        parentOperator={operator}
                        handleSetDataExtensionToFilter={handleSetDataExtensionToFilter}
                        dataExtensions={dataExtensions}
                        hideCollectionAlias={false}
                        updateSimpleFilterline={updateSimpleFilterline}
                        updateFiltersState={updateFiltersState}
                        allFilters={allFilters}
                        filterSets={filterSets}
                        areFilterSetsAvailable={areFilterSetsAvailable}
                        addFilterSetFilters={addFilterSetFilters}
                        selectDataExtensions={selectDataExtensions}
                        showInResultsOption={showInResultsOption}
                        handleRemoveFilterLine={handleRemoveFilterLine}
                        filterType={filterType}
                        subQueryModalFilterLineId={subQueryModalFilterLineId}
                        // eslint-disable-next-line react/jsx-props-no-spreading
                        {...filter}
                        key={`${filter.collectionAlias}-${filter.field}-${i}`}
                        selectedDataExtensions={selectedDataExtensions}
                        compareSelectedDataExtensions={compareSelectedDataExtensions}
                        dropFieldOnFilters={dropFieldOnFilters}
                        length={filters.length}
                        filterIndex={i}
                        handleHoverFilterMerging={handleHoverFilterMerging}
                        handleHideAddFiltersTogether={handleHideAddFiltersTogether}
                        formulaForRelationFilter={filter.formula}
                        filters={filters}
                        handleUpdateFilterLineCriteria={handleUpdateFilterLineCriteria}
                        handleUpdateFormulaFilterLineCriteria={handleUpdateFormulaFilterLineCriteria}
                        handleUpdateFilterLineValue={handleUpdateFilterLineValue}
                        handleMergeFilterLines={handleMergeFilterLines}
                        isSubQuery={isSubQuery}
                        isCompareFieldsFilter={filter.isCompareFieldsFilter}
                        handleOpenSubQueryModal={handleOpenSubQueryModal}
                        dateFilterType={filter.dateFilterType}
                        handleOnChangeRelativeDateFilterType={handleOnChangeRelativeDateFilterType}
                        dateValue={filter.dateValue}
                        filterInterval={filter.filterInterval}
                        dateValueStart={filter.dateValueStart}
                        subQuery={subQuery || filter.subQuery}
                        handleAddFormulaFilter={handleAddFormulaFilter}
                        handleRemoveDataExtensionFromFilter={handleRemoveDataExtensionFromFilter}
                        handleChangeFilterDataExtensionField={handleChangeFilterDataExtensionField}
                        handleOnChangeRelativeDateValue={handleOnChangeRelativeDateValue}
                        handleOnChangeRelativeDateFilterInterval={handleOnChangeRelativeDateFilterInterval}
                        handleOnChangeRelativeDateValueStartFrom={handleOnChangeRelativeDateValueStartFrom}
                        handleOnChangeRelativeDate={handleOnChangeRelativeDate}
                        handleOnChangeIsCompareFieldsFilter={handleOnChangeIsCompareFieldsFilter}
                        handleOnChangeCompareFieldsFilter={handleOnChangeCompareFieldsFilter}
                        handleOnChangeFilterComparedDataExtension={handleOnChangeFilterComparedDataExtension}
                        comparableFields={filter.comparableFields}
                        comparedDataExtension={filter.comparedDataExtension}
                        comparedField={filter.comparedField}
                        handleFeatureMissing={handleFeatureMissing}
                        handleOnChangeFormulaFilterFormulaValue={handleOnChangeFormulaFilterFormulaValue}
                        formula={filter.formula}
                        handleOnChangeFormulaValue={handleOnChangeFormulaValue}
                        DEBorderMouseOver={DEBorderMouseOver}
                        filterBorderMouseOver={filterBorderMouseOver}
                        handleSetSelectionState={handleSetSelectionState}
                        pickLists={pickLists}
                        handleOnChangeIsPicklistOption={handleOnChangeIsPicklistOption}
                        isPicklistOptions={filter.isPicklistOptions}
                        loadingSubQueryFields={loadingSubQueryFields}
                        handleFiltersSave={handleFiltersSave}
                        subQueryFilters={subQueryFilters}
                        relation={filter.relation}
                        isSubQueryParentRelation={isSubQueryParentRelation}
                        filterObject={filter}
                        returnPredefinedRelationById={returnPredefinedRelationById}
                        tags={tags}
                        handleTagsChanged={handleTagsChanged}
                        handleSetTags={handleSetTags}
                        updateTags={updateTags}
                        handleOpenTimezoneModal={handleOpenTimezoneModal}
                        isInResultsFormula={isInResultsFormula}
                        handleLogicChange={handleLogicChange}
                        collapseFormulas={collapseFormulas}
                        handleCollapseAllFormulas={handleCollapseAllFormulas}
                        selectedActiveDE={filter.selectedActiveDE}
                        showEssentialsUpgradeModal={showEssentialsUpgradeModal}
                      />
                    </div>
                  ))}
                <div
                  className="filter-container-dropzone"
                  onDragOver={e => e.preventDefault()}
                  onDrop={(e) => {
                    e.preventDefault();
                    if (!isInResultsFormula) {
                      handleCollapseAllFormulas(true);
                    }
                    handleSetSelectionState({ DEBorderMouseOver: false, filterBorderMouseOver: false });
                    // eslint-disable-next-line no-param-reassign
                    e.target.style.border = 'var(--transparentBorder)';

                    return dropFieldOnFilters(e, filterContainerId, null, isInResultsFormula, parentId);
                  }}
                  style={{ pointerEvents: DEBorderMouseOver ? 'none' : '' }}
                  onDragEnter={(e) => {
                    if (e.target.className === 'filter-container-dropzone') return;
                    if (e.target.tagName !== 'DIV') return;
                    // eslint-disable-next-line no-param-reassign
                    e.target.style.border = 'var(--solidBorder)';
                  }}
                  onDragLeave={(e) => {
                    if (e.target.className === 'filter-container-dropzone') return;
                    if (e.target.tagName !== 'DIV') return;
                    // eslint-disable-next-line no-param-reassign
                    e.target.style.border = `${filterBorderMouseOver ?
                      'var(--dashedBorder)' :
                      'var(--transparentBorder)'}`;
                  }}
                >
                  <p
                    style={{ border: filterBorderMouseOver ? 'var(--dashedBorder)' : 'var(--transparentBorder)' }}
                    className="filter-drop"
                  >
                    Drag - and - Drop
                    {isInResultsFormula ?
                      ' formula filter here' :
                      <span>
                        {' or '}
                        <button
                          type="button"
                          className="add-filter-manually-button"
                          onClick={() => handleAddFilterManually(filterContainerId)}
                        >
                          Add Filter Manually
                        </button>
                      </span>}
                    {isSelectedFiltersSection &&
                    (<p>
                      Or click the Next button to go to the Output step
                     </p>)}
                  </p>
                </div>
              </div>
              <div
                className="filter-logic"
                style={
                  {
                    backgroundColor: parentBackgroundColor === null ?
                      '#F4F7FB' :
                      parentBackgroundColor,
                  }
                }
              >
                  <div className="slds-select_container">
                    <select
                      className="slds-select filter-operator"
                      onChange={e => handleLogicChange(
                        filterContainerId,
                        e.target.value,
                        null,
                        isInResultsFormula,
                        parentId,
                      )}
                      // eslint-disable-next-line react/no-unknown-property
                      onLoad={
                        operator ?
                          null :
                          handleLogicChange(filterContainerId, Constants.FILTERLINE__OPERATOR__AND)
                      }
                      value={operator}
                    >
                      <option value={Constants.FILTERLINE__OPERATOR__AND}> And </option>
                      <option value={Constants.FILTERLINE__OPERATOR__OR}> Or </option>
                    </select>
                  </div>
              </div>
            </div>
          ) :
          null}
        {!isInResultsFormula && filters && filters.length <= 1 ?
          (
            <div
              className="filterline-dropzone"
              onDragOver={e => e.preventDefault()}
              onDrop={(e) => {
                e.preventDefault();
                handleSetSelectionState({ DEBorderMouseOver: false, filterBorderMouseOver: false });
                // eslint-disable-next-line no-param-reassign
                e.target.style.border = 'var(--transparentBorder)';

                return dropFieldOnFilters(e, filterContainerId, handleAddNewFilterline, parentId);
              }}
              style={{ pointerEvents: DEBorderMouseOver ? 'none' : '' }}
              onDragEnter={(e) => {
                if (e.target.className === 'filter-container-dropzone') return;
                if (e.target.className === 'filterline-dropzone') return;
                if (e.target.tagName !== 'DIV') return;
                // eslint-disable-next-line no-param-reassign
                e.target.style.border = 'var(--solidBorder)';
              }}
              onDragLeave={(e) => {
                if (e.target.className === 'filter-container-dropzone') return;
                if (e.target.className === 'filterline-dropzone') return;
                if (e.target.tagName !== 'DIV') return;
                // eslint-disable-next-line no-param-reassign
                e.target.style.border = `${filterBorderMouseOver ? 'var(--dashedBorder)' : 'var(--transparentBorder)'}`;
              }}
            >
              <p
                style={{ border: filterBorderMouseOver ? 'var(--dashedBorder)' : 'var(--transparentBorder)' }}
                className="filter-drop"
              >
                Drag - and - drop available fields here to filter
                <span>
                  {' or '}
                  <button
                    type="button"
                    className="add-filter-manually-button"
                    onClick={() => handleAddFilterManually(filterContainerId)}
                  >
                    Add Filter Manually
                  </button>
                </span>
                {isSelectedFiltersSection &&
                (<p>
                  Or click the Next button to go to the Output step
                 </p>)}
              </p>
            </div>
          ) :
          null}
      </div>
    </div>
  );
};

export default FilterContainer;

FilterContainer.propTypes = {
  /**
   * Indicated if 'In Results' and 'Not In Results' filters should be available
   */
  showInResultsOption: PropTypes.bool.isRequired,
  /**
   * It keeps the filters to be rendered
   * It will be passed from SelectedFilters.js
   */
  filters: PropTypes.instanceOf(Array),
  /**
   * It keeps the selected data extensions for Selection.js
   * selected data extensions are stored as collections in database
   * It will be passed from Selection.js
   */
  selectedDataExtensions: PropTypes.instanceOf(Array),
  /**
   * It keeps the all selected data extensions if the subquery modal is opened
   */
  compareSelectedDataExtensions: PropTypes.instanceOf(Array),
  /**
   * It helps to remove the selected filter line
   * It will be passed from SelectedFilters.js
   */
  handleRemoveFilterLine: PropTypes.func.isRequired,
  /**
   * It helps to add a field as filter to multi level filter lines
   * It will be passed from SelectedFilters.js
   */
  dropFieldOnFilters: PropTypes.func.isRequired,
  /**
   * It keeps the operator which will be used in the query
   * It will be passed from SelectedFilters.js
   */
  operator: PropTypes.string,
  /**
   * It helps to indicate which filters will be merged as another level
   * when clicked on add filters together button
   * It will be passed from SelectedFilters.js
   */
  handleHoverFilterMerging: PropTypes.func.isRequired,
  /**
   * It actually updates the color of the 'add filters together' button
   * It will be passed from SelectedFilters.js
   */
  handleHideAddFiltersTogether: PropTypes.func,
  /**
   * It helps to add a new field as a filter to filter line
   * It will be passed from SelectedFilters.js
   */
  handleAddNewFilterline: PropTypes.func,
  /**
   * It keeps the filter container id which means,
   * if there is/are multilevel of filterline(s), there should be
   * a unique value for each level of filterline(s)
   */
  filterContainerId: PropTypes.string,
  /**
   * It helps to change the selected criteria for a filter
   * It will be passed from SelectedFilters.js
   */
  handleUpdateFilterLineCriteria: PropTypes.func.isRequired,
  /**
   * It helps to change the selected criteria of a formula in filter
   * It will be passed from SelectedFilters.js
   */
  handleUpdateFormulaFilterLineCriteria: PropTypes.func.isRequired,
  /**
   * It helps to merge one filterline with another to a filter container
   * It will be passed from SelectedFilters.js
   */
  handleMergeFilterLines: PropTypes.func.isRequired,
  /**
   * It helps to update a value of a filter
   * It will be passed from SelectedFilters.js
   */
  handleUpdateFilterLineValue: PropTypes.func.isRequired,
  /**
   * It helps to change the operator of a filter group
   * AND - OR
   * It will be passed from SelectedFilters.js
   */
  handleLogicChange: PropTypes.func.isRequired,
  /**
   * It helps to set the Selection`s state
   * It will be passed from Selection.js
   */
  handleSetSelectionState: PropTypes.func.isRequired,
  /**
   * It determines if the selected field for a filter is a subquery or not
   */
  isSubQuery: PropTypes.bool,
  /**
   * It helps to open subquery modal if a filter`s criteria is IN RESULTS
   * It will be passed from SelectedFilters.js
   */
  handleOpenSubQueryModal: PropTypes.func.isRequired,
  /**
   * When IN RESULTS is selected as criteria for a filter, there is another
   * drag-drop zone for another dataextension which will be used for subquery
   * This function helps to remove that dataextension from that zone if there is
   * one selected
   * It will be passed from SelectedFilters.js
   */
  handleRemoveDataExtensionFromFilter: PropTypes.func.isRequired,
  /**
   * When IN RESULTS is selected as criteria for a filter, there is another
   * drag-drop zone for another dataextension which will be used for subquery.
   * And also there is one field has to be selected to compare.
   * This function helps to change the selected field.
   * It will be passed from SelectedFilters.js
   */
  handleChangeFilterDataExtensionField: PropTypes.func.isRequired,
  /**
   * When a date type of field is selected, there are two options.
   * Relative Date or Calendar Date.
   * This function helps to change between those values for the filter.
   * It will be passed from SelectedFilters.js
   */
  handleOnChangeRelativeDateFilterType: PropTypes.func,
  /**
   * If a relative option is selected for the date type of filter,
   * this function will help to change its starting value
   * It will be passed from SelectedFilters.js
   */
  handleOnChangeRelativeDateValue: PropTypes.func,
  /**
   * If a relative option is selected for the date type of filter,
   * this function will help to change its interval period.
   * e.g. days, weeks, weekdays etc.
   * It will be passed from SelectedFilters.js
   */
  handleOnChangeRelativeDateFilterInterval: PropTypes.func,
  /**
   * If a relative option is selected for the date type of filter,
   * this function will help to change its starting point.
   * Before now, after now.
   * It will be passed from SelectedFilters.js
   */
  handleOnChangeRelativeDateValueStartFrom: PropTypes.func,
  /**
   * If a relative option is selected for the date type of filter,
   * this function will help to change its value, depending on fieldProperty.
   * It will be passed from SelectedFilters.js
   */
  handleOnChangeRelativeDate: PropTypes.func,
  /**
   * It helps to change that filter`s comparable state to make it available
   * to compare with another filter.
   * It will be passed from SelectedFilters.js
   */
  handleOnChangeIsCompareFieldsFilter: PropTypes.func,
  /**
   * When comparing a field with another field for the filter,
   * a data extension will be selected.
   * This function helps to select it.
   * It will be passed from SelectedFilters.js
   */
  handleOnChangeFilterComparedDataExtension: PropTypes.func,
  /**
   * When comparing the selected filter's field with another field,
   * a field from the selected data extension for comparison will be selected for that action.
   * This function helps to select it.
   * It will be passed from SelectedFilters.js
   */
  handleOnChangeCompareFieldsFilter: PropTypes.func,
  /**
   * It helps to throw a warning message if a feature is disabled and used in the Selection
   * It will be passed from Selection.js
   */
  handleFeatureMissing: PropTypes.func,
  /**
   * It helps to determine if an aggregation formula will be used for a filter
   * It will be passed from SelectedFilters.js
   */
  handleOnChangeFormulaFilterFormulaValue: PropTypes.func,
  /**
   * It helps to change the value if an aggregation formula is selected for the filter
   * It will be passed from SelectedFilters.js
   */
  handleAddFormulaFilter: PropTypes.func,
  /**
   * It helps to add formula for a filter if in results/not in results is selected
   * It will be passed from SelectedFilters.js
   */
  handleOnChangeFormulaValue: PropTypes.func,
  /**
   * Keeps track whether Available DE are dragged
   */
  DEBorderMouseOver: PropTypes.bool.isRequired,
  /**
   * Keeps track whether Available Fields are dragged
   */
  filterBorderMouseOver: PropTypes.bool.isRequired,
  /**
   * Keeps searched picklist
   * It will be passed from Selection.js
   */
  pickLists: PropTypes.instanceOf(Array).isRequired,
  /**
   * It toggles input to picklist or vice versa
   */
  handleOnChangeIsPicklistOption: PropTypes.func,
  /**
   * Used to save data extension in a subquery
   */
  handleSetDataExtensionToFilter: PropTypes.func.isRequired,
  /**
   * Stores the data extensions mainly used for the
   * in / not in results filter searchable dropdown
   */
  dataExtensions: PropTypes.instanceOf(Array),
  /**
   * Stores the loading state of filterlines with subQueries
   */
  loadingSubQueryFields: PropTypes.instanceOf(Object),
  /**
   * It keeps the filterline IDs of a subquery`s filters
   */
  subQueryModalFilterLineId: PropTypes.string,
  /**
   * It save the selected filters for the selection
   * it is passed from Selection.js/SubqueryModal.js
   */
  handleFiltersSave: PropTypes.func.isRequired,
  /**
   * Indicates the type/location of a filter
   */
  filterType: PropTypes.string,
  /**
   * Contains subQuery filters for a given filterline
   */
  subQueryFilters: PropTypes.instanceOf(Array),
  /**
   * Indicates whether the parent of the subQuery is a relation
   */
  isSubQueryParentRelation: PropTypes.bool,
  /**
   * It returns predefined relation object for relation filter
   * it is passed from Selection.js
   */
  returnPredefinedRelationById: PropTypes.func,
  /**
   * An object with filterlineId as keys and array of tags as values
   */
  tags: PropTypes.instanceOf(Object).isRequired,
  /**
   * Handles the addition of tags
   */
  handleTagsChanged: PropTypes.func.isRequired,
  /**
   * Handles the setting of tags on filterline load
   */
  handleSetTags: PropTypes.func.isRequired,
  /**
   * Gets the current value of filters and updates the object with tags
   */
  updateTags: PropTypes.func.isRequired,
  /**
   * Handles the opening of the timezone modal
   */
  handleOpenTimezoneModal: PropTypes.func.isRequired,

  /**
   * Handles checking if passed filter is a subquery formula
   */
  isInResultsFormula: PropTypes.bool,

  /**
   * Passes the current subQuery object
   */
  subQuery: PropTypes.instanceOf(Object),

  /**
   * Gets the current id of the parent
   */

  parentId: PropTypes.string,
  /**
   * It helps to set whether all formula filters should be collapsed
   * It will be passed from SelectedFilters.js
   */

  collapseFormulas: PropTypes.bool,

  /**
   * function to toggle state of collapseFormulas
   * It will be passed from SelectedFilters.js
   */

  handleCollapseAllFormulas: PropTypes.func,
  /**
   * It toggles a feature advert modal on with specific feature
   */
  showEssentialsUpgradeModal: PropTypes.func,
};
